<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-card outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn 
              text
              color="primary"
              @click="addNew('Category')"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add Category
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card flat outlined>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr class="mb-5">
                        <!--<th class="text-center" width="100">Display Order</th>-->
                       <!-- <th class="text-left" width="100">Icon</th> -->
                        <th class="text-left">Category</th>
                        <!--<th class="text-left">Description</th>-->
                        <th class="text-center">Display Order</th>
                        <th class="text-left">Active</th>
                        <th class="text-center">Edit</th>
                        <th class="text-center">Delete</th>
                      </tr>
                    </thead>
                   
                      <tr 
                        v-for="(item, index) in categoryData"
                        :key="index"
                      >
                        <!-- if tabbing list
                        <td :class="item.padClass">{{ item.path }}</td>
                        -->
                        <td>{{ item.path }}</td>
                        <td class="text-center">
                          <input 
                            :id="'do-input-' + item.category_id" 
                            class="do-input" 
                            type="text" 
                            :value="item.display_order"
                            @change="updateDisplayOrder(item,$event)"
                          >
                        </td>
                        <td class="text-center">
                          <div>
                            <v-switch
                              class="mt-n2"
                            v-model="categorySwitches"
                            color="success"
                            :value="item.category_id"
                            inset
                            hide-details
                            @change="updateActive(categorySwitches,item.category_id)"
                          ></v-switch>
                          </div>
                        </td>
                        <td class="text-center">
                          <v-btn
                            icon
                            color="primary"
                            @click="updateModal(item,categorySwitches)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td class="text-center">
                          <v-btn
                            icon
                            @click="showDeletDialog(item.category_id,item.category_name)"
                          >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                      </tr>
                     
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>   
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   
    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
    <v-card>
        <v-card-title class="">
        </v-card-title>
        <v-card-text>
          <v-alert
            text
            prominent
            type="warning"
            icon="mdi-alert"
          >
            Are you sure you want to delete <strong>{{dialogDeleteName}}</strong> from Categories?
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            elevation="0"
            @click="deleteCategory()"
          >
            yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <edit-category-modal></edit-category-modal>
    
  </div>
</template>
<script>
import EditCategoryModal from '../components/EditCategoryModal.vue'
//import draggable from 'vuedraggable'
export default {
  name: 'Categories',
  data: () => ({
    categoryData: [],
    categorySwitches: [],
    dialogDelete: false,
    dialogDeleteId: null,
    dialogDeleteName: null,
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Categories")
    this.$root.$on('_resetCategories',() => {
      this.getCategories()
    })
  },
  mounted(){
    this.getCategories()
  },
  methods: {
    addNew(modal) {
      this.$root.$emit("openAddModal",modal)
    },
    getCategories(){
      
      this.categoryData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/portal-categories'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        /*
        var styleArr = []
        var tempObj = {}
        var pathStr = ''
        var padClass = ''
        */
        var switchArr = []
        for (const key in jsonResponse) {
          if(jsonResponse[key].active){
            switchArr.push(jsonResponse[key].category_id)
          }

          // USE IF TABBING LIST
          /*
          pathStr = jsonResponse[key].path.split(">")
          //console.log(pathStr.length)

          if(pathStr.length === 2){
            padClass = 'child-1'
          }else if(pathStr.length === 3){
            padClass = 'child-2'
          }else if(pathStr.length === 4){
            padClass = 'child-3'
          }else{
            padClass = 'parent'
          }

          tempObj = {
            padClass: padClass,
            category_id: jsonResponse[key].category_id,
            parent_id: jsonResponse[key].parent_id,
            category_name: jsonResponse[key].category_name,
            category_icon: jsonResponse[key].category_icon,
            category_description: jsonResponse[key].category_description,
            active: jsonResponse[key].active,
            display_order: jsonResponse[key].display_order,
            category_slug: jsonResponse[key].category_slug,
            path: jsonResponse[key].path,
          }

          styleArr.push(tempObj)
          */
        }

        //this.categoryData = styleArr//jsonResponse
        this.categoryData = jsonResponse
        this.categorySwitches = switchArr
      
      })
      
    },
    
    updateDisplayOrder(item){

      const inputId = "do-input-" + item.category_id
      const newValue = document.getElementById(inputId).value
      const oldValue = item.display_order

      if( !Number(newValue) ){
        document.getElementById(inputId).value = oldValue
        this.$root.$emit("notify","error","Display Order must be a valid number")
        return
      }

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-categories/${item.category_id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        action: "displayOrder",
        displayOrder: newValue
      }

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.$root.$emit("notify","success","Display order updated")

          //now update object
          let catArr = this.categoryData;          
          for (var i = 0; i < catArr.length; i++) { 
            if(catArr[i].category_id == item.category_id){
              //update object
              this.categoryData[i].display_order = newValue
            }
          }
          

        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
      })
      

    },

    updateDisplayOrder_v1(){
      var arrOrder =[]
      const obj = this.categoryData
      for (const [key] of Object.entries(obj)) {
        
        arrOrder.push({ id: obj[key].category_id })
      }

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      var endpoint = `/display-order`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        table: "categories",
        order: arrOrder
      }

      const request = new Request(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        this.getCategories()
        

        if(jsonResponse.success){
          this.$root.$emit("notify","success","Display order has been updated")
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }

      })
      
    },
    updateActive($arr,id){

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var active = ($arr.includes(id) ? 1 : 0)
      var endpoint = `/portal-categories/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        action: "active",
        active: active
      }

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.$root.$emit("notify","success","Active status updated")
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
      })
    },
    
    updateModal(item,activeArr){
      this.$root.$emit("openEditCategoryModal",item,activeArr)
    },
    
    showDeletDialog(id,name){
      this.dialogDelete = true
      this.dialogDeleteId = id,
      this.dialogDeleteName = name
    },
    deleteCategory(){
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-categories/${this.dialogDeleteId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.getCategories()
          this.$root.$emit("notify","success","Category has been deleted")
          this.resetDialogDelete()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    resetDialogDelete(){
      this.dialogDelete = false
      this.dialogDeleteId = null,
      this.dialogDeleteName = null
    }
    
  },
  watch : {
    categoryData: {
      handler(){
       // this.updateDisplayOrder()
      }
    }
  },
  components: {
    EditCategoryModal
  }
}
</script>
<style lang="scss" scoped>
input.do-input{
  width: 50px;
  border: solid 1px #eee;
  padding: 3px 5px;
}
td.parent{
  padding-left:0;
}
td.child-1{
  
  padding-left:20px;
}
td.child-2{
  
  padding-left:40px;
}
td.child-3{
  
  padding-left:60px;
}
</style>