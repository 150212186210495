<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="8">
        <v-card outlined>
          <v-card-title>
            <v-icon>mdi-file-document-multiple</v-icon>  Add Portal Content
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline mb-4">Basic Information</div>

                    <h4 class="font-weight-regular">Content Type</h4>
                    <v-radio-group
                      v-model="docType"
                      row
                    >
                      <v-radio
                        label="PDF Document"
                        value="pdf"
                      ></v-radio>
                      <v-radio
                        label="Link"
                        value="link"
                      ></v-radio>
                      <v-radio
                        label="Video"
                        value="video"
                      ></v-radio>
                      <v-radio
                        label="Other"
                        value="other"
                      ></v-radio>
                    </v-radio-group>

                    <!-- PDF FORM-->
                    <div v-if="docType=='pdf'">
                      
                      <v-form
                        ref="formPdf"
                        v-model="validPdf"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="docName.pdf"
                          :rules="[v => !!v || 'Title is required',]"
                          label="Document Title *"
                          required
                        ></v-text-field>

                        <v-text-field
                          v-model="docDescription.pdf"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-file-input
                          v-model="fileUpload.pdf"
                          :rules="[v => !!v || 'Required',]"
                          accept=".pdf"
                          label="Select PDF Document"
                        ></v-file-input>
                      </v-form>
                      
                      <v-switch
                        class=""
                        v-model="docActive.pdf"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details

                      ></v-switch> 
                      
                    </div>

                    <!-- LINK FORM-->
                    <div v-if="docType=='link'">
                      
                      <v-form
                        ref="formLink"
                        v-model="validLink"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="docName.link"
                          :rules="[v => !!v || 'Title is required',]"
                          label="Link Title *"
                          required
                        ></v-text-field>

                        <v-text-field
                          v-model="docDescription.link"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-text-field
                          v-model="docLink"
                          label="Link URL *"
                          :rules="[
                            v => !!v || 'Link URL is required',
                          ]"
                          required
                          hint="e.g. http://link-address.com/"
                          persistent-hint
                        ></v-text-field>
                      </v-form>
                      <v-switch
                        class=""
                        v-model="docActive.link"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details
                      ></v-switch> 
                      
                    </div>

                    <!-- VIDEO FORM-->
                    <div v-if="docType=='video'">
                      
                      <v-form
                        ref="formVideo"
                        v-model="validVideo"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="docName.video"
                          :rules="[v => !!v || 'Title is required']"
                          label="Video Title *"
                          required
                        ></v-text-field>

                        <v-text-field
                          v-model="docDescription.video"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-text-field
                          v-model="docVideoLink"
                          label="Video URL *"
                          :rules="[
                            v => !!v || 'Video URL is required'
                          ]"
                          required
                          hint="e.g. https://www.youtube.com/embed/video-id"
                          persistent-hint
                        ></v-text-field>
                      </v-form>
                      <v-switch
                        class=""
                        v-model="docActive.video"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details
                      ></v-switch> 
                    </div>

                    <!-- OTHER FORM-->
                    <div v-if="docType=='other'">
                      
                      <v-form
                        ref="formOther"
                        v-model="validOther"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="docName.other"
                          :rules="[v => !!v || 'Title is required',]"
                          label="Document Title *"
                          required
                        ></v-text-field>

                        <v-text-field
                          v-model="docDescription.other"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-file-input
                          v-model="fileUpload.other"
                          :rules="[v => !!v || 'Required',]"
                          label="Select File"
                        ></v-file-input>
                      </v-form>
                      <v-switch
                        class=""
                        v-model="docActive.other"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details
                      ></v-switch>
                    </div>

                  </v-sheet>
                </div>
                
                <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Document Permissions</div>

                    <div class="caption">
                      <v-icon
                        x-small
                        color="warning"
                      >mdi-alert-circle</v-icon>
                      Which user level(s) can access this document?
                    </div>
                    <v-checkbox
                        v-for="item in customerPermissionData"
                        :key="item.permission_id"
                        v-model="documentPermissions"
                        :label="item.permission"
                        :value="item.permission_id"
                        @change="validateCustomerPermissions"
                        hide-details
                      ></v-checkbox>
                      <div v-if="!validPermissions" class="caption red--text mt-5">Please assign permissions</div>
                    
                  </v-sheet>
                </div>
                <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Assign to Portal Categories</div>

                    <div class="mt-5">
                      <v-treeview
                        v-model="selectedCats"
                        selectable
                        item-disabled="locked"
                        :items="faqHierarchy"
                      ></v-treeview>
                    </div>
                    <div v-if="!validCategories" class="caption red--text mt-5">Please assign category</div>
                    <!-- {{ selectedCats }} -->
                  </v-sheet>
                </div>
                <!-- <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Assign to Portal Categories</div>
                    <v-combobox
                      v-model="documentCategories"
                      :items="categoryData"
                      :item-value="categoryData.category_id"
                      :item-text="categoryData.path"
                      clearable
                      multiple
                      small-chips
                      @change="validateCategories"
                    ></v-combobox>

                    <div v-if="!validCategories" class="caption red--text mt-5">Please assign category</div>
                  
                  </v-sheet>
                </div> -->
                <!--
                <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Related Content</div>
                    <v-combobox
                      v-model="relatedContent"
                      :items="documentData"
                      :item-value="documentData.document_id"
                      :item-text="documentData.document_name"
                      clearable
                      multiple
                      small-chips
                    ></v-combobox>
                  </v-sheet>
                </div> 
                -->
                <div>
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Tags / Search</div>
                    <v-text-field
                      id="newTag"
                      v-model="newTag"
                      append-icon="mdi-plus"
                      label="Enter Tag / Search Phrase"
                      placeholder="Enter Tag / Search Phrase"
                      @keydown.enter="addTag"
                      @click:append="addTag"
                    ></v-text-field>
                    <v-combobox
                      v-model="tags"
                      :items="tagData"
                      :item-value="tagData"
                      :item-text="tagData"
                      clearable
                      multiple
                      small-chips
                    ></v-combobox>
                  </v-sheet>
                </div> 
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn
              text
              color="info"
              large
              @click="reset"
            >
              Clear Form
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              large
              elevation="0"
              class="px-10"
              @click="validate"
            >
              Add Portal Content
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>     
  </div>
</template>
<script>
export default {
  name: 'AddPortalContent',
  data: () => ({
    validPdf: true,
    validLink: true,
    validVideo: true,
    validOther: true,
    validPermissions: true,
    validCategories: true,
    docName: {pdf: '', link: '', video: '', other: ''},
    docDescription: {pdf: '', link: '', video: '', other: ''},
    fileUpload: {pdf: null, other: null},
    docLink: '',
    docVideoLink: '',
    docActive: {pdf: '1', link: '1', video: '1', other: '1'},
    docDetails: '',
    docType: 'pdf',
    active: true,
    documentPermissions: [],
    documentPermissionsCheckAll: null,
    documentCategories: [],
    show1: false,
    loading: false,
    customerPermissionData: [],
    portalCategoriesData: [],
    categoryData: [],
    documentData: [],
    relatedContent: null,
    tags: null,
    tagData: [],
    newTag: '',
    faqHierarchy: null,
    selectedCats: [],
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Add Portal Content")
    this.getCustomerPermissions()
    this.getCategories()
    this.getPortalDocuments()
    this.getCategorySchema()
  },
  mounted() {
  },
  methods:{
    getCategorySchema() {
      this.faqHierarchy = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      const target = 'documents'
      var endpoint = `/portal-category-schemas/${target}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse[0].category_schema_json){
            this.formatTree(JSON.parse(jsonResponse[0].category_schema_json))
        }

      })  
    },
    formatTree(level1){
      
      let tempTreeObj = {}
      let tempTreeArr = []
      
      
      let level1SubCatObj = {}

      for (let i = 0; i < level1.length; i++) {

        
        let level1SubCatsArr = []
        level1SubCatsArr = level1[i].subcats

        let level1SubCats  = []

      
        if(level1SubCatsArr.length > 0){
          
          let level2SubCatObj = {}

          for (let level1Subs_i = 0; level1Subs_i < level1SubCatsArr.length; level1Subs_i++) {
            
            let level2SubCatsArr = []
            level2SubCatsArr = level1SubCatsArr[level1Subs_i].subcats
            
            let level2SubCats  = []
            
            if(level2SubCatsArr.length > 0){

              for (let level2Subs_i = 0; level2Subs_i < level2SubCatsArr.length; level2Subs_i++) {
                

                let level3SubCatsArr = []
                level3SubCatsArr = level2SubCatsArr[level2Subs_i].subcats
                
                let level3SubCats  = []

                if(level3SubCatsArr.length > 0){

                  let level3SubCatObj = {}
                  
                  for (let level3Subs_i = 0; level3Subs_i < level3SubCatsArr.length; level3Subs_i++) {
                    
                    level3SubCatObj = {
                      id: level3SubCatsArr[level3Subs_i].category_id,
                      name: level3SubCatsArr[level3Subs_i].category_name_display,
                      locked: false,
                      parent: level3SubCatsArr[level3Subs_i].category_name_display,
                      children: []
                    }

                    level3SubCats.push(level3SubCatObj)

                  }
                }

                level2SubCatObj = {
                  id: level2SubCatsArr[level2Subs_i].category_id,
                  name: level2SubCatsArr[level2Subs_i].category_name_display,
                  locked: false,
                  parent: level1SubCatsArr[level1Subs_i].category_name_display,
                  children: level3SubCats
                }

                level2SubCats.push(level2SubCatObj)
              }

            }
            


            level1SubCatObj = {
              id: level1SubCatsArr[level1Subs_i].category_id,
              name: level1SubCatsArr[level1Subs_i].category_name_display,
              locked: false,
              parent: level1[i].category_name_display,
              children: level2SubCats
            }

            level1SubCats.push(level1SubCatObj)

          }

        }

        tempTreeObj = {
          id: level1[i].category_id,
          name: level1[i].category_name_display,
          locked: false,
          children: level1SubCats
        }

        tempTreeArr.push(tempTreeObj)
      }

      this.faqHierarchy = tempTreeArr
      

    },
    getCustomerPermissions () {

    //  this.customerPermissionData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/customer-permissions'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint

      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })

      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        this.customerPermissionData = jsonResponse
      })
    },
    getCategories(){
      
      this.categoryData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/portal-categories'
      //var endpoint = '/portal-content-categories'

      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        let arr = []
        let obj = {}
        
        for (const key in jsonResponse) {

          obj = {
            text: jsonResponse[key].path,
            value: jsonResponse[key].category_id,
            parents: jsonResponse[key].parent_sid,
          }
          arr.push(obj)
        }
        
        this.categoryData = arr
        
        /*
text: string | number | object,
  value: string | number | object,
        */

      })
      
    },
    getPortalDocuments () {

      //this.categoryData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/portal-documents'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint

      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })

      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
      
        var arr = []
        var obj = {}

        for (const key in jsonResponse) {
          obj = {
            text: jsonResponse[key].document_name,
            value: jsonResponse[key].document_id,
          }
          arr.push(obj)
        }

        this.documentData = arr

      })      
    },
    addTag() {
      let newTag = this.newTag
      if (newTag.trim().length === 0) {
        return
      }

      var arr = this.tagData;
      arr.push(newTag)
      this.tagData = arr
      this.tags = arr

      this.newTag = ''
    },
    validateCustomerPermissions () {
      if( this.documentPermissions.length == 0 ){
        this.validPermissions = false
      }else{
        this.validPermissions = true
      }
    },
    validateCategories () {
      // if( this.documentCategories.length == 0 ){
      if( this.selectedCats.length == 0 ){
        
        this.validCategories = false
      }else{
        this.validCategories = true
      }
    },
    validate(){

      let permsAndCatsValid = true
      //check permissions
      if( this.documentPermissions.length == 0 ){
        this.validPermissions = false
        setTimeout(()=>{
          this.$root.$emit("notify","error","Please assign document permissions")
          this.loading = false
        },500)
        permsAndCatsValid = false
      }

      //check cats
      // if( this.documentCategories.length == 0 ){
      if( this.selectedCats.length == 0 ){  
          
        this.validCategories = false
        setTimeout(()=>{
          this.$root.$emit("notify","error","Please assign a category")
          this.loading = false
        },500)
        permsAndCatsValid = false
      }

      if(!permsAndCatsValid) return;

      if( this.docType == 'pdf') { 
        this.createPdf()
      } else if ( this.docType == 'link' ) {
        this.createLink()
      } else if ( this.docType == 'video' ) {
        this.createVideo()
      } else if ( this.docType == 'other' ) {
        this.createOther()
      } 
    },    
    createPdf () {
      if( this.$refs.formPdf.validate() ) {
      
        this.loading = true

        var active = (this.docActive.pdf == '1') ? 1 : 0

        var docDescription = (!this.docDescription.pdf)? null : this.docDescription.pdf 

        let tagsArr = this.tagData
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
              /*
              let tempObj = {
                tag: tagsArr[i]
              }
              tags.push(tempObj)
              */
             let str = tagsArr[i]
             tags.push(str)
          }
          tags = tags.toString().replaceAll(',', ' | ')
        }

        let postData = {
          name: this.docName.pdf,
          description: docDescription,
          type: this.docType,
          link: '',
          active: active,
          tags: tags
        }  

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-documents'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error creating this record")
              this.loading = false
            },500)
            return
          }

          var insertId = jsonResponse.insertId //new id
          
          this.updateDocPermissions(insertId) 
          this.updateDocCategories(insertId)
          
          //upload file
          this.uploadFile(insertId, this.docType)

          setTimeout(()=>{
            this.$root.$emit("notify","success","Document has been added")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
            this.documentPermissionsCheckAll = null
            this.$refs.formPdf.reset()
            this.docActive.pdf = '1' 
            this.documentPermissions = []
            this.documentCategories = []
            this.selectedCats = []
            this.tagData = []
            this.tags = []
          },500)
          
        })
      }else{
        setTimeout(()=>{
          this.$root.$emit("notify","error","There are errors on your form. Please correct.")
          this.loading = false
        },500)
      }
    },
    createLink () {
      if( this.$refs.formLink.validate() ) {
      
        this.loading = true

        var active = (this.docActive.link == '1') ? 1 : 0

        var docDescription = (!this.docDescription.link)? null : this.docDescription.link 

        let tagsArr = this.tagData
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
              /*
              let tempObj = {
                tag: tagsArr[i]
              }
              tags.push(tempObj)
              */
             let str = tagsArr[i]
             tags.push(str)
          }
          tags = tags.toString().replace(',', ' | ')
        }

        let postData = {
          name: this.docName.link,
          description: docDescription,
          type: this.docType,
          link: this.docLink,
          active: active,
          tags: tags
        }  

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-documents'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error creating this record")
              this.loading = false
            },500)
            return
          }

          var insertId = jsonResponse.insertId //new id
          
          this.updateDocPermissions(insertId)  
          this.updateDocCategories(insertId) 
          
          setTimeout(()=>{
            this.$root.$emit("notify","success","Link has been added")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
            this.documentPermissionsCheckAll = null
            this.$refs.formLink.reset()
            this.docActive.link = '1' 
            this.documentPermissions = []
            this.documentCategories = []
            this.selectedCats = []
            this.tagData = []
            this.tags = []
          },500)
          
        })
      }
    },
    createVideo (){
      
      if( this.$refs.formVideo.validate() ) {
      
        this.loading = true

        var active = (this.docActive.video == '1') ? 1 : 0

        var docDescription = (!this.docDescription.video)? null : this.docDescription.video 
        
        let tagsArr = this.tagData
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
              /*
              let tempObj = {
                tag: tagsArr[i]
              }
              tags.push(tempObj)
              */
             let str = tagsArr[i]
             tags.push(str)
          }
          tags = tags.toString().replace(',', ' | ')
        }

        let postData = {
          name: this.docName.video,
          description: docDescription,
          type: this.docType,
          link: this.docVideoLink,
          active: active,
          tags: tags
        }  

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-documents'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error creating this record")
              this.loading = false
            },500)
            return
          }

          var insertId = jsonResponse.insertId //new id
          
            this.updateDocPermissions(insertId)  
            this.updateDocCategories(insertId) 
          
          setTimeout(()=>{
            this.$root.$emit("notify","success","Video has been added")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
            this.documentPermissionsCheckAll = null
            this.$refs.formVideo.reset()
            this.docActive.video = '1' 
            this.documentPermissions = []
            this.documentCategories = []
            this.selectedCats = []
            this.tagData = []
            this.tags = []
          },500)
          
        })
      }
    },
    createOther () {
      if( this.$refs.formOther.validate() ) {
      
        this.loading = true

        var active = (this.docActive.other == '1') ? 1 : 0
        
        var docDescription = (!this.docDescription.other)? null : this.docDescription.other 

        let tagsArr = this.tagData
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
              /*
              let tempObj = {
                tag: tagsArr[i]
              }
              tags.push(tempObj)
              */
             let str = tagsArr[i]
             tags.push(str)
          }
          tags = tags.toString().replace(',', ' | ')
        }

        let postData = {
          name: this.docName.other,
          description: docDescription,
          type: this.docType,
          link: '',
          active: active,
          tags: tags
        }  

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-documents'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error creating this record")
              this.loading = false
            },500)
            return
          }

          var insertId = jsonResponse.insertId //new id
          
          this.updateDocPermissions(insertId)  
          this.updateDocCategories(insertId) 
          
          //upload file
          this.uploadFile(insertId, this.docType)

          setTimeout(()=>{
            this.$root.$emit("notify","success","File has been added")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
            this.documentPermissionsCheckAll = null
            this.$refs.formOther.reset()
            this.docActive.other = '1' 
            this.documentPermissions = []
            this.documentCategories = []
            this.selectedCats = []
            this.tagData = []
            this.tags = []
          },500)
          
        })
      }
    },  
    uploadFile(insertId, docType){
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      var uploadInput

      if(docType == 'pdf'){
        uploadInput = this.fileUpload.pdf
      }else if(docType == 'other'){
        uploadInput = this.fileUpload.other
      }

      let formData = new FormData();
      formData.append( 'portal-file', uploadInput, "upload" );

      //var endpoint2 = '/document-upload'
      var endpoint2 = '/doc-uploads'

      const url2 = process.env.VUE_APP_API_BASE_URL + endpoint2
      const request2 = new Request(url2, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
        },
        body: formData,
        mode: 'cors',
      })
      fetch(request2)
      .then(response2 => {
        return response2.json();
      })
      .then((jsonResponse2) => {
        
        if(!jsonResponse2.success){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error uploading this document")
            this.loading = false
          },500)
          return
        }

        let fileUploadResponse = {
          action: "updateOnInsert",
          fileName: jsonResponse2.fileData.filename,
          filePath: jsonResponse2.fileData.path,
          fileMimeType: jsonResponse2.fileData.mimetype
        }

        this.updateFileInfo(fileUploadResponse, insertId)

      })
    },
    updateFileInfo (fileUploadResponse, insertId) {
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      var endpoint3 = `/portal-documents/${insertId}`
      const url3 = process.env.VUE_APP_API_BASE_URL + endpoint3

      const request3 = new Request(url3, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fileUploadResponse),
        mode: 'cors',
      })

      fetch(request3)
      .then(response3 => {
        return response3.json();
      })
      .then((jsonResponse3) => {
        if( jsonResponse3.affectedRows != 1 ){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error updating this record")
            this.loading = false
          },500)
          return
        }
      })
    },
    updateDocPermissions(insertId) {
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      let bodyPermissions = {
        id: insertId,
        pIds: this.documentPermissions
      }

      var endpoint4 = `/document-permissions`
      const url4 = process.env.VUE_APP_API_BASE_URL + endpoint4

      const request4 = new Request(url4, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyPermissions),
        mode: 'cors',
      })
      fetch(request4)
      .then(response4 => {
        return response4.json();
      })
      .then((jsonResponse4) => {
        if( jsonResponse4.affectedRows == 0 ){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error adding document permissions")
            this.loading = false
          },500)
          return
        }
      })

    },
    updateDocCategories(insertId) {

      // let selectedCats = this.documentCategories
      // let arr = []
      // for (let i = 0; i < selectedCats.length; i++) {
      //   let cId = selectedCats[i].value;
      //   arr.push(cId)
      // }
      let arr = this.selectedCats

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      let bodyDocumentCategories = {
        cIds: arr,
        id: insertId
      }

      var endpoint5 = `/document-category-assignments`
      const url5 = process.env.VUE_APP_API_BASE_URL + endpoint5

      const request5 = new Request(url5, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyDocumentCategories),
        mode: 'cors',
      })
      fetch(request5)
      .then(request5 => {
        return request5.json();
      })
      .then((jsonResponse5) => {
        
        if( jsonResponse5.affectedRows == 0 ){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error adding document categories")
            this.loading = false
          },500)
          return
        }
      })
    },
    reset () {
      
      switch (this.docType) {
        case 'pdf':
          this.$refs.formPdf.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break
        case 'link':
          this.$refs.formLink.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break
        case 'video':
          this.$refs.formVideo.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break  
        case 'other':
          this.$refs.formOther.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break  
      }
      
    },
    resetValidation () {
      
      switch (this.docType) {
        case 'pdf':
          this.$refs.formPdf.resetValidation()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break
        case 'link':
          this.$refs.formLink.resetValidation()
          break
        case 'other':
          this.$refs.formOther.resetValidation()
          break  
      }
    },
    documentCategoriesChange () {
      
      let selectedCats = this.documentCategories
      let arr = []
      for (let i = 0; i < selectedCats.length; i++) {
        let cId = selectedCats[i].value;
        arr.push(cId)
      }
      


    }
  }
}
</script>