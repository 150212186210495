<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">
        <v-toolbar
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <v-container>
            <v-row justify="center" class="mt-5">
              <v-col cols="12">
                
                  <v-card outlined>
                    <v-card-title>
                      <v-icon large left>
                        {{dialogIcon}}
                      </v-icon>
                      <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div class="modal-form-container">
                      <v-row>
                        <v-col>
                          <div class="overline">Select Document Type</div>
                          <v-radio-group
                            v-model="docType"
                          >
                            <v-radio
                              label="PDF Document"
                              value="pdf"
                              disabled
                            ></v-radio>
                            <v-radio
                              disabled
                              label="Link"
                              value="link"
                            ></v-radio>
                            <v-radio
                              disabled
                              label="Video"
                              value="video"
                            ></v-radio>
                            <v-radio
                              disabled
                              label="Other"
                              value="other"
                            ></v-radio>
                          </v-radio-group>

                          <div class="overline mt-4">Edit Document Details</div>
                          
                          <!-- PDF FORM-->
                          <div v-if="docType=='pdf'">
                            
                            <v-form
                              ref="formPdf"
                              v-model="validPdf"
                              lazy-validation
                            >
                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="docName.pdf"
                                    :rules="[v => !!v || 'Title is required',]"
                                    label="Document Title *"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mt-5"
                                    text
                                    color="primary"
                                    small
                                    block
                                    outlined
                                    @click="viewDocument"
                                  >
                                    view
                                  </v-btn>

                                </v-col>
                              </v-row>
                              

                              <v-text-field
                                v-model="docDescription.pdf"
                                label="Description"
                                counter="255"
                              ></v-text-field>

                              <v-file-input
                                v-model="fileUpload.pdf"
                                accept=".pdf"
                                label="Select New PDF Document"
                              ></v-file-input>
                            </v-form>

                            <v-switch
                              class=""
                              v-model="docActive.pdf"
                              color="success"
                              label="Active"
                              inset
                              hide-details
                              value="1"
                            ></v-switch> 
                           
                          </div>

                          <!-- LINK FORM-->
                          <div v-if="docType=='link'">
                            
                            <v-form
                              ref="formLink"
                              v-model="validLink"
                              lazy-validation
                            >

                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="docName.link"
                                    :rules="[v => !!v || 'Title is required',]"
                                    label="Link Title *"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mt-5"
                                    text
                                    color="primary"
                                    small
                                    block
                                    outlined
                                    @click="viewDocument"
                                  >
                                    view
                                  </v-btn>
                                </v-col>
                              </v-row>

                              <v-text-field
                                v-model="docDescription.link"
                                label="Description"
                                counter="255"
                              ></v-text-field>

                              <v-text-field
                                v-model="docLink"
                                label="Link URL *"
                                :rules="[
                                  v => !!v || 'Title is required',
                                ]"
                                required
                                hint="e.g. http://link-address.com/"
                                persistent-hint
                              ></v-text-field>

                              <v-switch
                                class=""
                                v-model="docActive.link"
                                color="success"
                                label="Active"
                                value="1"
                                inset
                                hide-details
                              ></v-switch> 

                            </v-form>
                            
                          </div>

                          <!-- VIDEO FORM-->
                          <div v-if="docType=='video'">
                            
                            <v-form
                              ref="formVideo"
                              v-model="validVideo"
                              lazy-validation
                            >

                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="docName.video"
                                    :rules="[v => !!v || 'Title is required']"
                                    label="Video Title *"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mt-5"
                                    text
                                    color="primary"
                                    small
                                    block
                                    outlined
                                    @click="viewDocument"
                                  >
                                    view
                                  </v-btn>
                                </v-col>
                              </v-row>
                              

                              <v-text-field
                                v-model="docDescription.video"
                                label="Description"
                                counter="255"
                              ></v-text-field>

                              <v-text-field
                                v-model="docVideoLink"
                                label="Video URL *"
                                :rules="[
                                  v => !!v || 'Video URL is required'
                                ]"
                                required
                                hint="e.g. https://www.youtube.com/embed/video-id"
                                persistent-hint
                              ></v-text-field>

                              <v-switch
                                class=""
                                v-model="docActive.video"
                                color="success"
                                label="Active"
                                inset
                                hide-details
                              ></v-switch> 
                            </v-form>
                          </div>

                          <!-- OTHER FORM-->
                          <div v-if="docType=='other'">
                            <v-form
                              ref="formOther"
                              v-model="validOther"
                              lazy-validation
                            >

                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="docName.other"
                                    :rules="[v => !!v || 'Title is required',]"
                                    label="Document Title *"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mt-5"
                                    text
                                    color="primary"
                                    small
                                    block
                                    outlined
                                    @click="viewDocument"
                                  >
                                    view
                                  </v-btn>
                                </v-col>
                              </v-row>

                              

                              <v-text-field
                                v-model="docDescription.other"
                                label="Description"
                                counter="255"
                              ></v-text-field>

                              <v-file-input
                                v-model="fileUpload.other"
                                :rules="[v => !!v || 'Required',]"
                                label="Select New File"
                              ></v-file-input>

                              <v-switch
                                class=""
                                v-model="docActive.other"
                                color="success"
                                label="Active"
                                inset
                                hide-details
                              ></v-switch> 
                            </v-form>
                           
                          </div>
                        
                        </v-col>
                        <v-col class="col-auto"><v-divider vertical></v-divider></v-col>
                        <v-col>
                          <div class="overline">Edit Document Permissions</div>
                          
                          <div class="caption">
                            <v-icon
                              x-small
                              color="warning"
                            >mdi-alert-circle</v-icon>
                            Which user level(s) can access this document?
                          </div>
                          
                          <!--
                          <v-checkbox
                            v-model="documentPermissionsCheckAll"
                            label="All Users"
                            hide-details
                            @change="checkAllPermissions"
                          ></v-checkbox>
                          -->
                          <v-checkbox
                            v-for="item in customerPermissionData"
                            :key="item.permission_id"
                            v-model="documentPermissions"
                            :label="item.permission"
                            :value="item.permission_id"
                            hide-details
                          ></v-checkbox>
                          
                          <div v-if="!validPermissions" class="caption red--text mt-5">Please assign permissions</div>
                        </v-col>
                        <v-col class="col-auto"><v-divider vertical></v-divider></v-col>
                        <v-col>
                          <div class="overline">Edit Portal Categories</div>
                          
                          <v-checkbox
                            v-for="item in portalCategoriesData"
                            :key="item.category_id"
                            v-model="documentCategories"
                            :label="item.category_name_display"
                            :value="item.category_id"
                            hide-details
                          ></v-checkbox>
                          
                          <div v-if="!validCategories" class="caption red--text mt-5">Please assign category</div>
                        
                        </v-col>
                      </v-row>
                      
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-btn
                        text
                        color="info"
                        large
                        @click="reset"
                      >
                        Clear Form
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="secondary"
                        large
                        elevation="0"
                        class="px-10"
                        @click="validate"
                      >
                        Update Portal Content
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              
              </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  
    <v-dialog
      v-model="videoDialog"
      width="800"
    >
      <v-card class="pa-5">
        <iframe width="100%" height="450" :src="docVideoLink" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'EditPortalContentModal',
    data: () => ({
      document_id: null,
      videoDialog:false,
      dialog: false,
      dialogTitle: "Edit Portal Content",
      dialogIcon: "mdi-file-document-multiple",
      validPdf: true,
      validLink: true,
      validVideo: true,
      validOther: true,
      validPermissions: true,
      validCategories: true,
      docName: {pdf: '', link: '', video: '', other: ''},
      docDescription: {pdf: '', link: '', video: '', other: ''},
      fileUpload: {pdf: null,other: null},
      docLink: '',
      docVideoLink: '',
      docActive: {pdf: '', link: '', video: '', other: ''},
      docDetails: '',
      docType: null,
      active: true,
      documentPermissions: [],
      documentPermissionsCheckAll: null,
      documentCategories: [],
      show1: false,
      loading: false,
      customerPermissionData: [],
      portalCategoriesData: [],
      mimetype: null,
      videoUrl: null,
      documentFileName: null,
    }),
    beforeMount() {
      
      this.$root.$on('openEditPortalContentModal',(id) => {
        this.loadModal(id)
      })
    },
    mounted() {
      this.getCustomerPermissions()
      this.getPortalCategories()
    },
    methods: {
      viewDocument (){

        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        const jwt = userObj.jwt
        var docId = this.document_id
        var url, endpoint
        
        if( this.docType == 'pdf' || this.docType == 'other' ) {

          endpoint = `/view-file/${docId}/${jwt}`
          url = process.env.VUE_APP_API_BASE_URL + endpoint
          window.open(url)

        }else if ( this.docType == 'link' ) {

          url = this.docLink
          window.open(url)
          
        } else if ( this.docType == 'video' ) {

          this.videoDialog = true
        
        }
        
      },

      loadModal(id){
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-documents/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          this.document_id = jsonResponse[0].document_id 
          this.docType = jsonResponse[0].document_type
          this.mimetype = jsonResponse[0].document_mimetype 
          var active = (jsonResponse[0].active == 1) ? '1' : '0'
          this.documentFileName = jsonResponse[0].document_file_name 
          
          var assignedPermissions = []
          if( jsonResponse[0].permissions_arr ){
            assignedPermissions = jsonResponse[0].permissions_arr.split(',').map(Number)
          }
          this.documentPermissions = assignedPermissions
          
          var assignedCats = []
          if( jsonResponse[0].categories_arr ){
            assignedCats = jsonResponse[0].categories_arr.split(',').map(Number)
          }
          this.documentCategories = assignedCats
          
          if( this.docType == 'pdf'){
            this.docName.pdf = jsonResponse[0].document_name
            this.docDescription.pdf = jsonResponse[0].document_description
            this.docActive.pdf = active
          }else if( this.docType == 'link'){
            this.docName.link = jsonResponse[0].document_name
            this.docDescription.link = jsonResponse[0].document_description
            this.docLink = jsonResponse[0].document_url
            this.docActive.link = active
          }else if( this.docType == 'video'){
            this.docName.video = jsonResponse[0].document_name
            this.docDescription.video = jsonResponse[0].document_description
            this.docVideoLink = jsonResponse[0].document_url
            this.docActive.video = active
          }else if( this.docType == 'other'){
            this.docName.other = jsonResponse[0].document_name
            this.docDescription.other = jsonResponse[0].document_description
            this.docActive.other = active
          }
          
        })

        this.dialog = true
      },
      getCustomerPermissions () {
        let userData = localStorage.getItem('rccpa');
        this.customerPermissionData = []
        const userObj = JSON.parse(userData)

        var endpoint = '/customer-permissions'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          this.customerPermissionData = jsonResponse
        })
      },
      getPortalCategories () {
        
        let userData = localStorage.getItem('rccpa')

        this.portalCategoriesData = []
        const userObj = JSON.parse(userData)

        var endpoint = '/portal-categories'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          
          var arr = []

          for (const [key] of Object.entries(jsonResponse)) {
            arr.push(jsonResponse[key])
          }

          this.portalCategoriesData = arr
        })
      },
      checkAllPermissions (e){
        
        if (e) {
          var newArr = []
          var obj = this.customerPermissionData
          for (const [key] of Object.entries(obj)) {
            newArr.push(obj[key].permission_id)
          }
          this.documentPermissions = newArr
          this.validPermissions = true
          
        } else {
          this.documentPermissions = []
          this.validPermissions = false
        }
      },
      validateCustomerPermissions () {
        if( this.documentPermissions.length == 0 ){
          this.validPermissions = false
        }else{
          this.validPermissions = true
        }
      },
      validateCategories () {
        if( this.documentCategories.length == 0 ){
          this.validCategories = false
        }else{
          this.validCategories = true
        }
      },
      
      validate () {
        if( this.docType == 'pdf') { 
          this.updatePdf()
        } else if ( this.docType == 'link' ) {
          this.updateLink()
        }else if ( this.docType == 'video' ) {
          this.updateVideo()
        }else if ( this.docType == 'other' ) {
          this.updateOther()
        }
      },

      updatePdf () {
        if( this.$refs.formPdf.validate() ) {
        
          this.loading = true

          var id = this.document_id

          var active = (this.docActive.pdf == '1') ? 1 : 0

          let postData = {
            action: 'updateFileInfo',
            name: this.docName.pdf,
            description: this.docDescription.pdf,
            type: this.docType,
            link: '',
            active: active
          }  

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/portal-documents/${id}`

          const url = process.env.VUE_APP_API_BASE_URL + endpoint

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {


            if( jsonResponse.affectedRows != 1 ){
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error updating this record")
                this.loading = false
              },500)
              return
            }

            this.updateDocPermissions(id)
            this.updateDocCategories(id)

            if(this.fileUpload.pdf){ //update file if uploaded


              this.updateUploadFile(id, this.docType)
            }
            
            setTimeout(()=>{
              this.$root.$emit("notify","success","Document has been updated")
              this.loading = false  
              this.$root.$emit("_resetPortalDocuments")
              
              setTimeout(()=>{
                this.$refs.formPdf.reset()
                this.documentFileName = null
                this.dialog = false
              },500)  

            },500)
            
          })
        }   
      },

      updateLink () {
        if( this.$refs.formLink.validate() ) {
        
          this.loading = true
          var id = this.document_id
          var active = (this.docActive.link == '1') ? 1 : 0

          let postData = {
            action: 'updateFileInfo',
            name: this.docName.link,
            description: this.docDescription.link,
            type: this.docType,
            link: this.docLink,
            active: active
          }  

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/portal-documents/${id}`

          const url = process.env.VUE_APP_API_BASE_URL + endpoint

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if( jsonResponse.affectedRows != 1 ){
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error updating this record")
                this.loading = false
              },500)
              return
            }

            this.updateDocPermissions(id)
            this.updateDocCategories(id)

            setTimeout(()=>{
              this.$root.$emit("notify","success","Link has been updated")
              this.loading = false  
              this.$root.$emit("_resetPortalDocuments")
              
              setTimeout(()=>{
                this.$refs.formLink.reset()
                this.documentFileName = null
                this.dialog = false
              },500)  
            },500)
 
          })
        }
      },
      
      updateVideo (){
        
        if( this.$refs.formVideo.validate() ) {
        
          this.loading = true
          var id = this.document_id
          var active = (this.docActive.video == '1') ? 1 : 0

          let postData = {
            action: 'updateFileInfo',
            name: this.docName.video,
            description: this.docDescription.video,
            type: this.docType,
            link: this.docVideoLink,
            active: active
          }  

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/portal-documents/${id}`

          const url = process.env.VUE_APP_API_BASE_URL + endpoint

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if( jsonResponse.affectedRows != 1 ){
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error updating this record")
                this.loading = false
              },500)
              return
            }

            this.updateDocPermissions(id)
            this.updateDocCategories(id)

            setTimeout(()=>{
              this.$root.$emit("notify","success","Video has been updated")
              this.loading = false  
              this.$root.$emit("_resetPortalDocuments")
              
              setTimeout(()=>{
                this.$refs.formVideo.reset()
                this.dialog = false
              },500) 
            },500)
            
          })
        }
      },

      updateOther () {
        if( this.$refs.formOther.validate() ) {
        
          this.loading = true
          var id = this.document_id
          var active = (this.docActive.other == '1') ? 1 : 0

          let postData = {
            action: 'updateFileInfo',
            name: this.docName.other,
            description: this.docDescription.other,
            type: this.docType,
            link: '',
            active: active
          }  

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/portal-documents/${id}`

          const url = process.env.VUE_APP_API_BASE_URL + endpoint

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if( jsonResponse.affectedRows != 1 ){
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error updating this record")
                this.loading = false
              },500)
              return
            }

            this.updateDocPermissions(id)
            this.updateDocCategories(id)

            if(this.fileUpload.other){ //update file if uploaded
              this.updateUploadFile(id, this.docType)
            }

            setTimeout(()=>{
              this.$root.$emit("notify","success","Document has been updated")
              this.loading = false  
              this.$root.$emit("_resetPortalDocuments")
              setTimeout(()=>{
                this.$refs.formOther.reset()
                this.documentFileName = null
                this.dialog = false
              },500) 
            },500)
            
          })
        }
      },

      updateDocPermissions(insertId) {

        var addNew = (this.documentPermissions.length > 0) ? true : false
       
        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        
        let bodyPermissions = {
          id: insertId,
          pIds: this.documentPermissions,
          isUpdate: true,
          addNew: addNew
        }

        var endpoint4 = `/document-permissions`
        const url4 = process.env.VUE_APP_API_BASE_URL + endpoint4

        const request4 = new Request(url4, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyPermissions),
          mode: 'cors',
        })
        fetch(request4)
        .then(response4 => {
          return response4.json();
        })
        .then((jsonResponse4) => {
          if( jsonResponse4.affectedRows == 0 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error adding document permissions")
              this.loading = false
            },500)
            return
          }
        })

      },
      updateDocCategories(insertId) {

        var addNew = (this.documentCategories.length > 0) ? true : false
       
        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        
        let bodyDocumentCategories = {
          cIds: this.documentCategories,
          id: insertId,
          isUpdate: true,
          addNew: addNew
        }

        var endpoint5 = `/document-category-assignments`
        const url5 = process.env.VUE_APP_API_BASE_URL + endpoint5

        const request5 = new Request(url5, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyDocumentCategories),
          mode: 'cors',
        })
        fetch(request5)
        .then(request5 => {
          return request5.json();
        })
        .then((jsonResponse5) => {
          
          if( jsonResponse5.affectedRows == 0 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error adding document categories")
              this.loading = false
            },500)
            return
          }
        })
      },
      updateUploadFile(insertId, docType){
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        
        var uploadInput

        if(docType == 'pdf'){
          uploadInput = this.fileUpload.pdf
        }else if(docType == 'other'){
          uploadInput = this.fileUpload.other
        }

        let formData = new FormData();
        formData.append( 'portal-file', uploadInput, "upload" );

        //var endpoint2 = '/document-upload'
        var endpoint2 = '/doc-uploads'

        

        const url2 = process.env.VUE_APP_API_BASE_URL + endpoint2
        const request2 = new Request(url2, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
          },
          body: formData,
          mode: 'cors',
        })
        fetch(request2)
        .then(response2 => {
          return response2.json();
        })
        .then((jsonResponse2) => {
          

          if(!jsonResponse2.success){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error uploading this document")
              this.loading = false
            },500)
            return
          }

          let fileUploadResponse = {
            action: "updateOnUpdate",
            fileName: jsonResponse2.fileData.filename,
            filePath: jsonResponse2.fileData.path,
            fileMimeType: jsonResponse2.fileData.mimetype,
            oldFileName: this.documentFileName
          }

          this.updateFileInfo(fileUploadResponse, insertId)

        })
      },

      updateFileInfo (fileUploadResponse, insertId) {
        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        
        var endpoint3 = `/portal-documents/${insertId}`
        const url3 = process.env.VUE_APP_API_BASE_URL + endpoint3

        const request3 = new Request(url3, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(fileUploadResponse),
          mode: 'cors',
        })

        fetch(request3)
        .then(response3 => {
          return response3.json();
        })
        .then((jsonResponse3) => {
          if( jsonResponse3.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error updating this record")
              this.loading = false
            },500)
            return
          }
        })
      },
     
      reset () {
        
        switch (this.docType) {
          case 'pdf':
            this.$refs.formPdf.reset()
            this.validCategories = true
            this.validPermissions = true
            this.documentPermissions = []
            this.documentCategories = []
            break
          case 'link':
            this.$refs.formLink.reset()
            this.validCategories = true
            this.validPermissions = true
            this.documentPermissions = []
            this.documentCategories = []
            break
          case 'other':
            this.$refs.formOther.reset()
            break  
        }
      },

      resetValidation () {
        
        switch (this.docType) {
          case 'pdf':
            this.$refs.formPdf.resetValidation()
            this.validCategories = true
            this.validPermissions = true
            this.documentPermissions = []
            this.documentCategories = []
            break
          case 'link':
            this.$refs.formLink.resetValidation()
            break
          case 'other':
            this.$refs.formOther.resetValidation()
            break  
        }
        
      },
      
    },
  }
</script>
