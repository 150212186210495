<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">
        <v-toolbar
          
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

  
          <v-container>
            <v-row justify="center" class="mt-5">
              <v-col cols="12" md="3" sm="6">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-card outlined>
                    <v-card-title>
                      <v-icon large left>
                        {{dialogIcon}}
                      </v-icon>
                      <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div class="modal-form-container">
                      <v-text-field
                        v-model="name"
                        :rules="[v => !!v || 'Name is required',]"
                        label="Full Name"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="email"
                        :rules="[
                          v => !!v || 'E-mail is required',
                          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                        ]"
                        label="Email"
                        required
                      ></v-text-field>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        large
                        elevation="0"
                        @click="validate"
                      >
                        Update Administrator
                      </v-btn>
                      
                    </v-card-actions>
                  </v-card>
                </v-form> 
              </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: 'EditAdministratorModal',
    data: () => ({
      dialog: false,
      dialogTitle: "Update RareCyte Administrator",
      dialogIcon: "mdi-account-lock",
      valid: true,
      name: '',
      email: '',
      password: '',
      id: '',
      show1: false,
      loading: false,
    }),
    beforeMount() {
    },
    mounted() {
      this.$root.$on('openEditAdministratorModal',(id,name,email) => {
        this.dialog = true
        this.name = name
        this.email = email
        this.id = id
      })
    },
    methods: {
      validate () {
        if(this.$refs.form.validate()){
          
          this.loading = true

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/users/${this.id}`
          const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
          let postData = {
            action: "user",
            name: this.name,
            email: this.email
          }

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
      
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              
              setTimeout(()=>{
                this.$root.$emit("_resetAdministrators")
                this.$root.$emit("notify","success","Administrator has been updated")
                this.loading = false

                setTimeout(()=>{
                  this.dialog = false
                },3500)

              },500) 

            }else{
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
              },500) 
            }
          })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
