<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="12" lg="12">
        <v-card outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn 
              text
              color="primary"
              @click="addNew('Portal Content')"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add Content
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card flat outlined>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="documentData"
                :search="search"
                :footer-props="{
                  'items-per-page-options': [50, 100, 200]
                }"
                :items-per-page="200"
              >
                <template v-slot:item.display_order>
                  <v-icon>mdi-arrow-up-down</v-icon>
                </template>
                <template v-slot:item.document_type="{ item }">
                  <v-btn
                    v-if="item.document_type == 'pdf'"
                    elevation="0"
                    color="transparent"
                    @click="viewDocument(item)"
                  >
                    <v-icon large  color="red darken-2" dark>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <v-btn 
                    v-else-if="item.document_type == 'link'"
                    elevation="0"
                    color="transparent"
                    @click="viewDocument(item)"
                  >
                    <v-icon large color="blue darken-2" dark>mdi-link-box-variant</v-icon>
                  </v-btn>
                  <v-btn 
                    v-else-if="item.document_type == 'other'"
                    elevation="0"
                    color="transparent"
                    @click="viewDocument(item)"
                  >
                    <v-icon large color="deep-purple darken-2" dark>mdi-text-box</v-icon>
                  </v-btn>
                  <v-btn 
                    v-else-if="item.document_type == 'video'"
                    elevation="0"
                    color="transparent"
                    @click="viewDocument(item)"
                  >
                    <v-icon large color="blue-grey darken-3" dark>mdi-video-box</v-icon>
                  </v-btn>
                </template>
                <!-- document_file_name -->

                <template v-slot:item.active="{ item }">
                  <v-switch
                      class="mt-n2"
                    v-model="documentSwitches"
                    color="success"
                    :value="item.document_id"
                    inset
                    hide-details
                    @change="updateActive($event,item.document_id)"
                  ></v-switch>
                </template>

                <template v-slot:item.document_id="{ item }">
                  <v-btn
                    icon
                    color="primary"
                    @click="updateItem(item.document_id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.del_id="{ item }">
                  <v-btn
                    icon
                    @click="showDeletDialog(item.document_id, item.document_name, item.document_file_name)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
    <v-card>
      <v-card-title class="">
        </v-card-title>
        <v-card-text>
          <v-alert
            text
            prominent
            type="warning"
            icon="mdi-alert"
          >
            Are you sure you want to delete <strong>{{dialogDeleteName}}</strong> from portal content?
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="dialogDelete = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            elevation="0"
            @click="deleteContent()"
          >
            yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="videoDialogList"
      width="800"
    >
      <v-card class="pa-5">
        <iframe width="100%" height="450" :src="docVideoLinkList" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-card>
    </v-dialog>
    <edit-portal-content-modal></edit-portal-content-modal>
  </div>
</template>
<script>
import EditPortalContentModal from '../components/EditPortalContentModal.vue'
import Sortable from 'sortablejs'
import Vue from "vue";


function orderNotification(status) {
  var type, msg
  if( status == 'success'){
    type = "success";
    msg = "Display order has been updated";
  }else{
    type = "error";
    msg = "There was an error processing this request";
  }

  var options = {
    timeout: 3000,
    hideProgressBar: true,
    type: type
  }

  Vue.$toast(msg, options);
  
}

export default {
  name: 'PortalContent',
  data: () => ({
    search: '',
    headers: [
      { 
        text: 'Display Order', 
        value: 'display_order',
        filterable: false,
        sortable: false
      },
      {
        text: 'Title',
        align: 'start',
        value: 'document_name'
      },
      { text: 'Content Type', value: 'document_type' },
      { text: 'Permissions', value: 'permissions', filterable: false, },
      { text: 'Categories', value: 'categories', filterable: false, },
      { text: 'Active', value: 'active', sortable: false, filterable: false, },
      { 
        text: '', 
        value: 'document_id',
        filterable: false,
        sortable: false
      },
      { 
        text: '', 
        value: 'del_id',
        filterable: false,
        sortable: false
      },
    ],
    documentData: [],
    documentSwitches: [1],
    dialogDelete: false,
    dialogDeleteId: null,
    dialogDeleteName: null,
    dialogDeleteDocument: null,
    docVideoLinkList: null,
    videoDialogList: false,
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Portal Content")
    this.$root.$on('_resetPortalDocuments',() => {
      this.getPortalDocument()
    })
    this.getPortalDocument()
  },
  mounted() {
    setTimeout(() => {
      this.initSortable();
    }, 250);
    
  },
  methods: {
    addNew () {

      this.$router.push({ path: '/add-portal-content' })
     // modal = ''
     // return
    //  this.$root.$emit("openAddModal",modal)
    },
    viewDocument (item) {

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      const jwt = userObj.jwt
      var docId = item.document_id
      var url, endpoint
      
      if( item.document_type == 'pdf' || item.document_type == 'other' ) {
        endpoint = `/view-file/${docId}/${jwt}`
        url = process.env.VUE_APP_API_BASE_URL + endpoint
        window.open(url)
      }else if ( item.document_type == 'link' ) {
        url = item.document_url.replace(/\/$/, "")
        window.open(url)
      } else if ( item.document_type == 'video' ) {
        this.docVideoLinkList = item.document_url
        this.videoDialogList = true
      }
    },
    getPortalDocument () {

      //this.categoryData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/portal-documents'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
       
        var switchArr = []
        for (const key in jsonResponse) {
          if(jsonResponse[key].active){
            switchArr.push(jsonResponse[key].document_id)
          }
        }

        this.documentData = jsonResponse
        this.documentSwitches = switchArr
      
      })      
    },
    
    updateActive ($arr,id) {
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var active = ($arr.includes(id) ? 1 : 0)
      var endpoint = `/portal-documents/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        action: "active",
        active: active
      }

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.$root.$emit("notify","success","Active status updated")
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    updateItem(id) {
      this.$router.push(`/edit-portal-content/${id}`)
    },
    updateModal (id) {
      this.$root.$emit("openEditPortalContentModal",id)
    },
    showDeletDialog (id,name, doc) {
      this.dialogDelete = true
      this.dialogDeleteId = id
      this.dialogDeleteName = name
      this.dialogDeleteDocument = doc
    },
    deleteContent () {
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-documents/${this.dialogDeleteId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        documentName: this.dialogDeleteDocument
      }
      
      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse.affectedRows){

          this.getPortalDocument()
          this.$root.$emit("notify","success","Document has been deleted")
          this.resetDialogDelete()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    resetDialogDelete(){
      this.dialogDelete = false
      this.dialogDeleteId = null,
      this.dialogDeleteName = null
      this.dialogDeleteDocument = null
    },
    initSortable() {
      //let table = document.querySelector(".v-datatable tbody");
      let table = document.querySelector("tbody");
 
      const _self = this;
     
      _self.dragNdrop = JSON.parse(JSON.stringify(_self.documentData));

      Sortable.create(table, {
        onEnd({
          newIndex,
          oldIndex
        }) {
          _self.dragNdrop.splice(
            newIndex,
            0,
            ..._self.dragNdrop.splice(oldIndex, 1)
          );
          // console.log(_self.dragNdrop);
          
          //PUSH TO API TO UPDATE
          var arrOrder = _self.dragNdrop
      
          const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
          var endpoint = `/display-order`
          const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
          let postData = {
            table: "documents",
            order: arrOrder
          }

          const request = new Request(url, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
      
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.success){
              orderNotification('success')
              // this.$root.$emit("notify","success","Display order has been updated")
            }else{
              orderNotification('error')
              // this.$root.$emit("notify","error","There was an error processing this request")
            }

          })          

        }
      });
          
    },
    
  },
  components: {
    EditPortalContentModal
  }
}
</script>
