<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">
        <v-toolbar
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <v-row justify="center" class="mt-5">
            <v-col cols="12" md="5" sm="6">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-card outlined>
                  <v-card-title>
                    <v-icon large left>
                      {{dialogIcon}}
                    </v-icon>
                    <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <div class="modal-form-container">
                   
                    <v-row>
                      <v-col>
                        
                        <v-text-field
                          v-model="password"
                          label="New password *"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          :rules= "[
                            v => !!v || 'New password is required',
                            v => /.{8,}$/.test(v) || 'Password must be at lease 8 characters',
                            v => /(?=.*[a-z])(?=.*[A-Z])/.test(v) || 'Password must contain at least one uppercase and one lowercase letter',
                          ]"
                          required
                          @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-text-field
                          v-model="passwordMatch"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          label="Confirm password *"
                          :type="show2 ? 'text' : 'password'"
                          :rules="[
                            v => !!v || 'Password must match',
                            v => v === this.password || 'Password must match',
                          ]"
                          required
                          @click:append="show2 = !show2"
                        ></v-text-field>

                      </v-col>
                      <v-col>
                        <v-alert
                          dense
                          type="info"
                          outlined
                        >
                          Password must:
                          <ul>
                            <li>Have a min of eight characters</li>
                            <li>Contain at least one uppercase letter</li>
                            <li>Have at least one number</li>
                          </ul>
                        </v-alert>
                      </v-col>
                    </v-row>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        large
                        elevation="0"
                        @click="validate"
                      >
                        Update Password
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form> 
              </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
import jwt_decode from "jwt-decode"

  export default {
    name: 'EditPasswordModal',
    data: () => ({
      dialog: false,
      dialogTitle: "Update Password",
      dialogIcon: "mdi-lock",
      valid: true,
      active: true,
      loading: false,
      password: null,
      passwordMatch: null,
      show1: false,
      show2: false,
    }),
    beforeMount() {
    },
    mounted() {
      this.$root.$on('openEditPasswordModal',() => {
        this.dialog = true
      })
    },
    methods: {
      validate () {
        if(this.$refs.form.validate()){

          this.loading = true
          
          const userObj = JSON.parse(localStorage.getItem('rccpa'))
          var decoded = jwt_decode(userObj.jwt);
          
          var endpoint = `/users/${decoded.id}`
          
          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          
          let postData = {
            action: "pwd",
            pwd: this.password
          }
          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
      
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              
              setTimeout(()=>{
                
                this.$root.$emit("notify","success","Your password has been updated")
                this.loading = false

                setTimeout(()=>{
                  this.$refs.form.reset()
                  this.dialog = false
                },3500)

              },500) 

            }else{
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
              },500) 
            }
          })
          
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
