import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Administrators from '../views/Administrators.vue'
import Categories from '../views/Categories.vue'
import Logout from '../views/Logout.vue'
import PortalContent from '../views/PortalContent.vue'

import PortalContentV1 from '../views/PortalContentV1.vue'

import Customers from '../views/Customers.vue'
import AddPortalContent from '../views/AddPortalContent.vue'
import EditPortalContent from '../views/EditPortalContent.vue'
import PortalFaqs from '../views/PortalFaqs.vue'
import FaqCategories from '../views/FaqCategories.vue'
import AddFaq from '../views/AddFaq.vue'
import EditFaq from '../views/EditFaq.vue'
import ContentCategories from '../views/ContentCategories.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/administrators',
    name: 'Administrators',
    component: Administrators
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/portal-content-v1',
    name: 'PortalContentV1',
    component: PortalContentV1
  },
  {
    path: '/portal-content',
    name: 'PortalContent',
    component: PortalContent
  },
  {
    path: '/add-portal-content',
    name: 'AddPortalContent',
    component: AddPortalContent
  },
  {
    path: '/edit-portal-content/:id',
    name: 'EditPortalContent',
    component: EditPortalContent
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/portal-faqs',
    name: 'PortalFaqs',
    component: PortalFaqs
  },
  {
    path: '/faq-categories',
    name: 'FaqCategories',
    component: FaqCategories
  },
  {
    path: '/add-faq',
    name: 'AddFaq',
    component: AddFaq
  },
  {
    path: '/edit-faq/:id',
    name: 'EditFaq',
    component: EditFaq
  },
  {
    path: '/content-categories',
    name: 'ContentCategories',
    component: ContentCategories
  },
]

const router = new VueRouter({
  routes
})

export default router

router.beforeEach((to, from, next) => {

  const publicPages = ['/','/logout'];
  const authRequired = !publicPages.includes(to.path);

  //verify jwt is set
  const loggedIn = localStorage.getItem('rccpa');
  
  if(authRequired && loggedIn){
    
    const userObj = JSON.parse(loggedIn)

    var endpoint = '/auth'

    const url = process.env.VUE_APP_API_BASE_URL + endpoint

    const request = new Request(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userObj.jwt
      },
    })

    fetch(request)
    .then(response => {
      return response.json()
    })
    .then((jsonResponse) => {
      
      if( !jsonResponse.authorized ){
        
        localStorage.clear()
        localStorage.removeItem('rccpa')
        
        next('/logout')
      }
    })

  }
  
  if (authRequired && !loggedIn) {
    next('/logout')
  } else {
    next()
  }
})