<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn 
              text
              color="primary"
              @click="addNew('Customer')"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add Customer
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card flat outlined>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="customerData"
                  :search="search"
                >
                  <template v-slot:item.customer_permissions="{ item }">
                    <div style="width:150px">
                      <v-select
                        :ref="`selectedPermission-` + item.customer_id" 
                        :items="accountTypes"
                        item-text="permission"
                        item-value="permission"
                        dense
                        :value="item.customer_permissions"
                        @change="changePermission($event, item.customer_id)"
                      ></v-select>
                    </div>
                  </template>

                  <template v-slot:item.active="{ item }">
                    <v-switch
                        class="mt-n2"
                      v-model="customerSwitches"
                      color="success"
                      :value="item.customer_id"
                      inset
                      hide-details
                      @change="updateActive($event,item.customer_id)"
                    ></v-switch>
                  </template>

                  <template v-slot:item.customer_id="{ item }">
                    <v-btn
                      icon
                      color="primary"
                      @click="updateModal(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.del_id="{ item }">
                    <v-btn
                      icon
                      @click="showDeletDialog(item.customer_id, item.customer_first, item.customer_last)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>
              </v-card-text>
            </v-card>    
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
    <v-card>
      <v-card-title class="">
        </v-card-title>
        <v-card-text>
          <v-alert
            text
            prominent
            type="warning"
            icon="mdi-alert"
          >
            Are you sure you want to delete <strong>{{dialogDeleteName}}</strong> from Customers?
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
          text
          @click="dialogDelete=false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            elevation="0"
            @click="deleteCustomer()"
          >
            yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-customer-modal></edit-customer-modal>
  </div>
</template>
<script>

import EditCustomerModal from '../components/EditCustomerModal.vue'



export default {
  name: 'Customers',
  data: () => ({
    accountTypes: [
      {permission: "Guest", id: 0},
      {permission: "Customer", id: 1},
      {permission: "Distributor", id: 2},
      {permission: "Admin", id: 3},
    ],
    dialogDelete: false,
    dialogDeleteId: null,
    dialogDeleteName: null,
    search: '',
    headers: [
      {
        text: 'First',
        align: 'start',
        value: 'customer_first',
      },
      { text: 'Last', value: 'customer_last' },
      { text: 'Company', value: 'customer_company' },
      { text: 'Email', value: 'customer_email' },
      { text: 'Phone', value: 'customer_phone' },
      { text: 'Permissions', value: 'customer_permissions' },
      { text: 'Active', value: 'active' },
      { text: 'Sign Up Date', value: 'sign_up_date' },
      { 
        text: '', 
        value: 'customer_id',
        filterable: false,
        sortable: false
      },
      { 
        text: '', 
        value: 'del_id',
        filterable: false,
        sortable: false
      },
    ],
    customerData: [],
    customerSwitches: [],
  }),
  beforeMount() {
    this.$root.$on('_resetCustomers',() => {
      this.getCustomers()
    })
    this.getCustomers()
  },
  mounted() {
  },
  methods: {
    addNew(modal) {
      this.$root.$emit("openAddModal",modal)
    },
    getCustomers () {
      this.customerData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/customers'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        var switchArr = []
        for (const key in jsonResponse) {
          if(jsonResponse[key].active){
            switchArr.push(jsonResponse[key].customer_id)
          }
        }
        this.customerData = jsonResponse
        this.customerSwitches = switchArr
      })
    },

    updateActive ($arr,id) {
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var active = ($arr.includes(id) ? 1 : 0)
      var endpoint = `/customers/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        action: "active",
        active: active
      }

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.$root.$emit("notify","success","Active status updated")
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }

      })
    },
    changePermission ($event, id) {
            
      var permission = $event

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      let postData = {
        action: "status",
        permission: permission
      }

      var endpoint = `/customers/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.$root.$emit("notify","success","Customer permission has been updated")
          this.getDashboard()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
      })
      
    },
    updateModal (item) {
      this.$root.$emit("openEditCustomerModal",item.customer_id)
    },
    showDeletDialog (id, first, last) {
      this.dialogDelete = true
      this.dialogDeleteId = id,
      this.dialogDeleteName = first + ' ' + last
    },
    deleteCustomer () {

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/customers/${this.dialogDeleteId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.getCustomers()
          this.$root.$emit("notify","success","Customer has been deleted")
          this.resetDialogDelete()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })

    },
    resetDialogDelete () {
      this.dialogDelete = false
      this.dialogDeleteId = null,
      this.dialogDeleteName = null
    }
  },
  components: {
    EditCustomerModal
  }
}
</script>
