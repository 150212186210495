var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"modal-bg"},[_c('v-toolbar',{attrs:{"elevation":"0","outlined":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-container',[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.dialogIcon)+" ")]),_c('span',{staticClass:"text-h6 font-weight-light overline"},[_vm._v(_vm._s(_vm.dialogTitle))])],1),_c('v-divider'),_c('div',{staticClass:"modal-form-container"},[_c('v-text-field',{attrs:{"rules":[v => !!v || 'Name is required',],"label":"Full Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"rules":[
                      v => !!v || 'E-mail is required',
                      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ],"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                      v => !!v || 'Password is required',
                      v => v.length >= 8 || 'Min 8 characters',
                    ],"type":_vm.show1 ? 'text' : 'password',"label":"Password","hint":"At least 8 characters","persistent-hint":"","counter":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"text":"","color":"info","large":""},on:{"click":_vm.reset}},[_vm._v(" Clear Form ")]),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"secondary","large":"","elevation":"0"},on:{"click":_vm.validate}},[_vm._v(" Add Administrator ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }