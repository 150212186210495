<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="12" lg="12">
        <v-card outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn 
              text
              color="primary"
              @click="addNew('Portal Content')"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add Content
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            
            <v-tabs
              v-model="tab"
              fixed-tabs
              icons-and-text
              @change="tabChange($event)"
            >
              <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  All Content
                  <v-icon>mdi-list-box-outline</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Content BY Category
                  <v-icon>mdi-file-tree-outline</v-icon>
                </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab" class="mt-5">
              <v-tab-item value="tab-1" class="mt-6" style="min-height:700px">
                <v-card flat outlined>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Filter"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <div>
                      <v-data-table
                        v-model="contents"
                        class="mt-5"
                        :headers="headers"
                        :items="list"
                        :search="search"
                        hide-default-footer
                        :items-per-page=-1
                        item-key="document_id"
                      >

                      <template v-slot:item.documentType="{ item }">
                        <v-btn
                          v-if="item.document_type == 'pdf'"
                          elevation="0"
                          color="transparent"
                          @click="viewDocument(item)"
                        >
                          <v-icon large  color="red darken-2" dark>mdi-file-pdf-box</v-icon>
                        </v-btn>
                        <v-btn 
                          v-else-if="item.document_type == 'link'"
                          elevation="0"
                          color="transparent"
                          @click="viewDocument(item)"
                        >
                          <v-icon large color="blue darken-2" dark>mdi-link-box-variant</v-icon>
                        </v-btn>
                        <v-btn 
                          v-else-if="item.document_type == 'other'"
                          elevation="0"
                          color="transparent"
                          @click="viewDocument(item)"
                        >
                          <v-icon large color="deep-purple darken-2" dark>mdi-text-box</v-icon>
                        </v-btn>
                        <v-btn 
                          v-else-if="item.document_type == 'video'"
                          elevation="0"
                          color="transparent"
                          @click="viewDocument(item)"
                        >
                          <v-icon large color="blue-grey darken-3" dark>mdi-video-box</v-icon>
                        </v-btn>
                      </template>

                      <template v-slot:item.status="props">
                        <v-switch
                          false-value="0"
                          true-value="1"
                          :input-value="props.item.active.toString()"
                          color="success"
                          @change="updateActive($event, props.item.document_id)"
                          
                        ></v-switch>
                      </template>
                        <!-- <template v-slot:item.faq_content="{ item }">
                          <v-btn
                            text
                            color="primary"
                            @click="viewAnswerDialog(item)"
                          >
                            View
                          </v-btn>
                        </template>
                        -->
                        <template v-slot:item.edit="{ item }">
                          
                          <v-icon
                            small
                            @click="updateItem(item.document_id)"
                          >
                            mdi-pencil
                          </v-icon>
                        </template> 
                        
                        <template v-slot:item.delete="{ item }">
                          <v-icon
                            small
                            @click="showDialogDelete(item.document_id, item.document_name, item.document_file_name)"
                            
                          >
                            mdi-delete
                          </v-icon>
                        </template> 
                        
                      </v-data-table>
                    </div>
                    
                    <v-dialog
                      v-model="dialogDelete"
                      width="500"
                    >
                    <v-card>
                      <v-card-title class="">
                        </v-card-title>
                        <v-card-text>
                          <v-alert
                            text
                            prominent
                            type="warning"
                            icon="mdi-alert"
                          >
                            Are you sure you want to <strong>permanently delete</strong> this content?
                          </v-alert>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            elevation="0"
                            @click="deleteContent()"
                          >
                            yes, delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item value="tab-2" class="mt-6" style="min-height:700px">
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-subheader>SELECT CATEGORY</v-subheader>
                    <v-treeview
                      v-model="selectedContentCats"
                      :active.sync="treeSelected"
                      activatable
                      open-on-click
                      return-object
                      :items="contentHierarchy"
                    ></v-treeview>  
                  </v-col>
                  <v-col cols="12" lg="9">
                    <v-card flat outlined>
                      <v-card-text>
                        <div class="caption">{{ selectedCategory.parent }}</div>
                        <div class="text-h5">{{ selectedCategory.category }}</div>
                      </v-card-text>
                      <v-card-text>
                        <div v-if="list.length === 0" style="min-height: 600px;">
                          <!-- No Data -->
                        </div>
                        <nested-draggable :subcats="list" />
                      </v-card-text>
                    </v-card> 
                    
                  </v-col>
                </v-row>

              </v-tab-item>
            </v-tabs-items>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import nestedDraggable from '../components/ContentNested.vue'

export default {
  name: 'PortalContent',
  data: () => ({
    tab: null,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      
    cbViewAll: true,
    contents: [],
    search: '',
    list: [],
    dialogAnswer:{
      show: false,
      question: null,
      answer: false,
      id: null,
    },
    dialogDelete: false,
    selectedContentCats: [],
    contentHierarchy: null,
    test: '',
    treeSelected: [],
    headers: [
    
      {
        text: 'Type',
        align: 'start',
        value: 'documentType',
        sortable: false,
        filterable: true, 
      },
      {
        text: 'Content',
        align: 'start',
        value: 'document_name',
        sortable: true,
        filterable: true, 
      },
      {
        text: 'Categories',
        align: 'start',
        value: 'category_list',
        sortable: false,
        filterable: false, 
      },
      {
        text: 'Permissions',
        align: 'start',
        value: 'permission_list',
        sortable: false,
        filterable: false, 
      },
      { 
        text: 'Active', 
        value: 'status', 
        sortable: false, 
        filterable: false, 
      },
      { 
        text: '', 
        value: 'edit',
        filterable: false,
        sortable: false,
        align: 'end'
      },
      { 
        text: '', 
        value: 'delete',
        filterable: false,
        sortable: false,
        align: 'end'
      },
    ],
    selectedCategory: {
      id: null,
      category: null,
      parent: null,
    },
    
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Portal Content")
    this.getCategorySchema()
    this.$root.$on('_resetPortalDocuments',() => {
      // this.getPortalDocument()
      this.getContent()
    })
    // this.getPortalDocument()
  },
  mounted() {
    // setTimeout(() => {
    //   this.initSortable();
    // }, 250);
    
  },
  watch: {
    treeSelected: function(e){
      this.getContentByCat(e[0])
    }
  },
  methods: {
    tabChange(tab){
      this.list = []
      if(tab === 'tab-1'){
        this.getContent()
      }
    },
    
    getContent(){
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      var endpoint = `/portal-content`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        // console.log(jsonResponse)
        if(jsonResponse){
          this.list = jsonResponse
        }
      })

     
    },
    
    getContentByCat(catData){

      this.selectedCategory = {
        id: catData.id,
        category: catData.name,
        parent: catData.parent,
      }
      
       
      this.list = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      var endpoint = `/portal-content-category-assignments/${catData.id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        // console.log(jsonResponse)
        if(jsonResponse){
          this.list = jsonResponse
        }
       
      })  


    },
    addNew () {
      this.$router.push({ path: '/add-portal-content' })
    },
    getCategorySchema() {
      this.contentHierarchy = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      const target = 'documents'
      var endpoint = `/portal-category-schemas/${target}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse[0].category_schema_json){
            this.formatTree(JSON.parse(jsonResponse[0].category_schema_json))
        }
       
      })  
    },
    formatTree(level1){
      
      let tempTreeObj = {}
      let tempTreeArr = []
      
      
      let level1SubCatObj = {}

      for (let i = 0; i < level1.length; i++) {

        
        let level1SubCatsArr = []
        level1SubCatsArr = level1[i].subcats

        let level1SubCats  = []

      
        if(level1SubCatsArr.length > 0){
          
          let level2SubCatObj = {}

          for (let level1Subs_i = 0; level1Subs_i < level1SubCatsArr.length; level1Subs_i++) {
            
            let level2SubCatsArr = []
            level2SubCatsArr = level1SubCatsArr[level1Subs_i].subcats
            
            let level2SubCats  = []
            
            if(level2SubCatsArr.length > 0){

              for (let level2Subs_i = 0; level2Subs_i < level2SubCatsArr.length; level2Subs_i++) {
                

                let level3SubCatsArr = []
                level3SubCatsArr = level2SubCatsArr[level2Subs_i].subcats
                
                let level3SubCats  = []

                if(level3SubCatsArr.length > 0){

                  let level3SubCatObj = {}
                  
                  for (let level3Subs_i = 0; level3Subs_i < level3SubCatsArr.length; level3Subs_i++) {
                    
                    level3SubCatObj = {
                      id: level3SubCatsArr[level3Subs_i].category_id,
                      name: level3SubCatsArr[level3Subs_i].category_name_display,
                      locked: false,
                      parent: level3SubCatsArr[level3Subs_i].category_name_display,
                      children: []
                    }

                    level3SubCats.push(level3SubCatObj)

                  }
                }

                level2SubCatObj = {
                  id: level2SubCatsArr[level2Subs_i].category_id,
                  name: level2SubCatsArr[level2Subs_i].category_name_display,
                  locked: false,
                  parent: level1SubCatsArr[level1Subs_i].category_name_display,
                  children: level3SubCats
                }

                level2SubCats.push(level2SubCatObj)
              }

            }
            


            level1SubCatObj = {
              id: level1SubCatsArr[level1Subs_i].category_id,
              name: level1SubCatsArr[level1Subs_i].category_name_display,
              locked: false,
              parent: level1[i].category_name_display,
              children: level2SubCats
            }

            level1SubCats.push(level1SubCatObj)

          }

        }

        tempTreeObj = {
          id: level1[i].category_id,
          name: level1[i].category_name_display,
          locked: false,
          children: level1SubCats
        }

        tempTreeArr.push(tempTreeObj)
      }

      this.contentHierarchy = tempTreeArr

    },
    updateActive ($arr,id) {
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var active = ($arr.includes(id) ? 1 : 0)
      var endpoint = `/portal-documents/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        action: "active",
        active: active
      }

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.$root.$emit("notify","success","Active status updated")
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    showDialogDelete (id,name, doc) {
      this.dialogDelete = true
      this.dialogDeleteId = id
      this.dialogDeleteName = name
      this.dialogDeleteDocument = doc
    },
    deleteContent () {
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-documents/${this.dialogDeleteId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        documentName: this.dialogDeleteDocument
      }
      
      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse.affectedRows){

          this.getContent()
          this.$root.$emit("notify","success","Document has been deleted")
          this.resetDialogDelete()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    // deleteContent () {
    //   const userObj = JSON.parse(localStorage.getItem('rccpa'))

    //   var endpoint = `/portal-documents/${this.dialogDeleteId}`
    //   const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
    //   let postData = {
    //     documentName: this.dialogDeleteDocument
    //   }
      
    //   const request = new Request(url, {
    //     method: 'DELETE',
    //     headers: {
    //       'Authorization': 'Bearer ' + userObj.jwt,
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(postData),
    //     mode: 'cors',
    //   })
      
    //   fetch(request)
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then((jsonResponse) => {
        
    //     if(jsonResponse.affectedRows){

    //       this.getPortalDocument()
    //       this.$root.$emit("notify","success","Document has been deleted")
    //       this.resetDialogDelete()
    //     }else{
    //       this.$root.$emit("notify","error","There was an error processing this request")
    //     }
        
    //   })
    // },
    
    resetDialogDelete(){
      this.dialogDelete = false
      this.dialogDeleteId = null,
      this.dialogDeleteName = null
      this.dialogDeleteDocument = null
    },
    updateItem(id) {
      this.$router.push(`/edit-portal-content/${id}`)
    },
  },
  components: {
    nestedDraggable
  }
}
</script>

