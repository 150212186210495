<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="12" lg="12">
        <v-card outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn 
              text
              color="primary"
              @click="addNew('Portal FAQ')"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add FAQ
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            
            <v-tabs
              v-model="tab"
              fixed-tabs
              icons-and-text
              @change="tabChange($event)"
            >
              <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  All FAQs
                  <v-icon>mdi-list-box-outline</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  FAQs BY CATEGORY
                  <v-icon>mdi-file-tree-outline</v-icon>
                </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab" class="mt-5">
              <v-tab-item value="tab-1" class="mt-6" style="min-height:700px">
                <v-card flat outlined>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Filter"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-card-text>
                    
                    <div>
                      <v-data-table
                        v-model="faqs"
                        dense
                        class="mt-5"
                        :headers="headers"
                        :items="list"
                        :search="search"
                        hide-default-footer
                        :items-per-page=-1
                        item-key="faq_ID"
                      >
                        <template v-slot:item.faq_content="{ item }">
                          <v-btn
                            text
                            color="primary"
                            @click="viewAnswerDialog(item)"
                          >
                            View
                          </v-btn>
                        </template>
                        <template v-slot:item.status="props">
                          <v-switch
                            false-value="0"
                            true-value="1"
                            :input-value="props.item.active.toString()"
                            color="success"
                            @change="updateActive($event, props.item.faq_ID)"
                          ></v-switch>
                        </template>
                        <template v-slot:item.edit="{ item }">
                          
                          <v-icon
                            small
                            @click="updateItem(item.faq_ID)"
                          >
                            mdi-pencil
                          </v-icon>
                        </template> 
                        <template v-slot:item.delete="{ item }">
                          <v-icon
                            small
                            @click="showDialogDelete(item.faq_ID)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        
                    </v-data-table>
                    </div>
                    <v-dialog
                      v-model="dialogAnswer.show"
                      width="500"
                    >
                    <v-card>
                      <v-card-text>
                        <div class="text-h5 pt-4 mb-4">{{ dialogAnswer.question }}</div>
                        <div v-html="dialogAnswer.answer"></div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions class="pa-4">
                        <v-btn
                          text
                          color="info"
                          large
                          @click="closeDialogAnswer"
                        >
                          Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="secondary"
                          elevation="0"
                          class="px-10"
                          @click="editFaq(dialogAnswer.id)"
                        >
                          Edit FAQ
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    </v-dialog>

                    <v-dialog
                      v-model="dialogDelete"
                      width="500"
                    >
                    <v-card>
                      <v-card-title class="">
                        </v-card-title>
                        <v-card-text>
                          <v-alert
                            text
                            prominent
                            type="warning"
                            icon="mdi-alert"
                          >
                            Are you sure you want to <strong>permanently delete</strong> this FAQ?
                          </v-alert>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            elevation="0"
                            @click="deleteItem()"
                          >
                            yes, delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2" class="mt-6" style="min-height:700px">
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-subheader>SELECT CATEGORY</v-subheader>
                    <v-treeview
                      v-model="selectedFaqCats"
                      :active.sync="treeSelected"
                      activatable
                      open-on-click
                      return-object
                      :items="faqHierarchy"
                    ></v-treeview>  
                  </v-col>
                  <v-col cols="12" lg="9">
                    <v-card flat outlined>
                      <v-card-text>
                        <div class="caption">{{ selectedFaq.parent }}</div>
                        <div class="text-h5">{{ selectedFaq.category }}</div>
                      </v-card-text>
                      <v-card-text>
                        <div v-if="list.length === 0" style="min-height: 600px;">
                          <!-- No Data -->
                        </div>
                        <nested-draggable :subcats="list" />
                      </v-card-text>
                    </v-card> 
                    
                  </v-col>
                </v-row>

              </v-tab-item>
            </v-tabs-items>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import nestedDraggable from '../components/FaqsNested.vue'

export default {
  name: 'PortalFaqs',
  data: () => ({
    tab: null,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      
    cbViewAll: true,
    faqs: [],
    search: '',
    list: [],
    dialogAnswer:{
      show: false,
      question: null,
      answer: false,
      id: null,
    },
    dialogDelete: false,
    deleteFaqId: null,

    // list: [
    //   {
    //     "faq_cat_ID":16,
    //     "faq_category":"Instrument",
    //     "answer": "The answer"
    //   },
    //   {
    //     "faq_cat_ID":19,
    //     "faq_category":"Software",
    //     "answer": "The answer boom baps"
    //   },
    //   {
    //     "faq_cat_ID":24,
    //     "faq_category":"Reagents",
    //     "answer": "The answer and whatnots"
    //   }
    // ],
    // list: [
    //   {
    //     "faq_ID": 27,
    //     "faq_title": "my title",
    //     "faq_content": "mycontent",
    //     "active": "1"
    //   }
    // ],
    selectedFaqCats: [],
    faqHierarchy: null,
    test: '',
    treeSelected: [],
    headers: [
      {
        text: 'FAQ',
        align: 'start',
        value: 'faq_title',
        sortable: false,
        filterable: true, 
      },
      {
        text: 'Answer',
        align: 'start',
        value: 'faq_content',
        sortable: false,
        filterable: false, 
      },
      {
        text: 'Assigned Categories',
        align: 'start',
        value: 'faq_cat_list',
        sortable: false, 
      },
      {
        text: 'Assigned Tags',
        align: 'start',
        value: 'faq_tag_list',
        sortable: false, 
      },
      { 
        text: 'Active', 
        value: 'status', 
        sortable: false, 
        filterable: false, 
      },
      { 
        text: '', 
        value: 'edit',
        filterable: false,
        sortable: false,
        align: 'end'
      },
      { 
        text: '', 
        value: 'delete',
        filterable: false,
        sortable: false,
        align: 'end'
      },
    ],
    selectedFaq: {
      id: null,
      category: null,
      parent: null,
    },
    // documentData: [],
    // documentSwitches: [1],
    // dialogDelete: false,
    // dialogDeleteId: null,
    // dialogDeleteName: null,
    // dialogDeleteDocument: null,
    // docVideoLinkList: null,
    // videoDialogList: false,
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Portal FAQs")
    this.getCategorySchema()
    // this.$root.$on('_resetPortalDocuments',() => {
    //   this.getPortalDocument()
    // })
    // this.getPortalDocument()
  },
  mounted() {
    // setTimeout(() => {
    //   this.initSortable();
    // }, 250);
    
  },
  watch: {
    treeSelected: function(e){
      this.getFaqsByCat(e[0])
    }
  },
  methods: {
    tabChange(tab){
      this.list = []
      if(tab === 'tab-1'){
        this.getFaqs()
      }
    },
    getFaqs(){
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      var endpoint = `/portal-faqs`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse){
          this.list = jsonResponse
        }
       
      })
    },
    viewAnswerDialog(faq){
      // console.log(faq)
      this.dialogAnswer = {
        show: true,
        question: faq.faq_title,
        answer: faq.faq_content,
        id: faq.faq_ID,
      }
    },
    closeDialogAnswer(){
      this.dialogAnswer = {
        show: false,
        question: null,
        answer: false,
        id: null,
      }
    },
    getFaqsByCat(catData){
      
      this.selectedFaq = {
        id: catData.id,
        category: catData.name,
        parent: catData.parent,
      }

      // 
      this.list = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      var endpoint = `/portal-faq-category-assignments/${catData.id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse){
          this.list = jsonResponse
        }
       
      })  


    },
    addNew () {
      this.$router.push({ path: '/add-faq' })
    },
    getCategorySchema() {
      this.faqHierarchy = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      const target = 'faq'
      var endpoint = `/portal-category-schemas/${target}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse[0].category_schema_json){
            this.formatTree(JSON.parse(jsonResponse[0].category_schema_json))
        }
       
      })  
    },
    formatTree(json){
      
      let tempTreeObj = {}
      let tempTreeArr = []
      let tempSubsArr  = []
      let loopSubCatArr = []
      let loopSubCatObj = {}

      for (let i = 0; i < json.length; i++) {

        loopSubCatArr = []
        tempSubsArr  = []

        loopSubCatArr = json[i].subcats
        if(loopSubCatArr.length > 0){
          
          for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
            loopSubCatObj = {
              id: loopSubCatArr[iSubs].faq_cat_ID,
              name: loopSubCatArr[iSubs].faq_category,
              locked: false,
              parent: json[i].faq_category,
              children: []
            }

            tempSubsArr.push(loopSubCatObj)

          }

          

        }

        tempTreeObj = {
          id: json[i].faq_cat_ID,
          name: json[i].faq_category,
          locked: false,
          children: tempSubsArr
        }

        tempTreeArr.push(tempTreeObj)
      }

      this.faqHierarchy = tempTreeArr
      

    },
    updateActive(event, id){
        
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-faqs/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
          
      let postData = {
        action: "active",
        active: event,
      }

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
                      
          setTimeout(()=>{
            this.$root.$emit("notify","success","FAQ status has been updated")
          },500) 

        }else{
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error processing this request")
            this.loading = false
          },500) 
        }
      })

    },
    showDialogDelete(id){
      // console.log(id)
      this.dialogDelete = true
      this.deleteFaqId = id
    },
    deleteItem(){
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-faqs/${this.deleteFaqId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          
          this.$root.$emit("notify","success","FAQ has been deleted")
          
          this.resetDialogDelete()

          // this.list = newNavArr

          // setTimeout(()=>{
          //   this.updateCategoryHierarchy()
          // }, 300)

          // setTimeout(()=>{
          //   this.getCategories()
          //   this.getCategorySchema() 
          // }, 750)

        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
          // this.resetDialogDelete()
          // this.getCategories()
        }
        
      })
    },
    resetDialogDelete(){
      this.dialogDelete = false
      this.deleteFaqId = null
    },
    updateItem(id) {
      this.$router.push(`/edit-faq/${id}`)
    },
    // viewDocument (item) {

    //   const userObj = JSON.parse(localStorage.getItem('rccpa'))
    //   const jwt = userObj.jwt
    //   var docId = item.document_id
    //   var url, endpoint
      
    //   if( item.document_type == 'pdf' || item.document_type == 'other' ) {
    //     endpoint = `/view-file/${docId}/${jwt}`
    //     url = process.env.VUE_APP_API_BASE_URL + endpoint
    //     window.open(url)
    //   }else if ( item.document_type == 'link' ) {
    //     url = item.document_url.replace(/\/$/, "")
    //     window.open(url)
    //   } else if ( item.document_type == 'video' ) {
    //     this.docVideoLinkList = item.document_url
    //     this.videoDialogList = true
    //   }
    // },
    // getPortalDocument () {

    //   //this.categoryData = []
    //   const userObj = JSON.parse(localStorage.getItem('rccpa'))

    //   var endpoint = '/portal-documents'
    //   const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
    //   const request = new Request(url, {
    //     method: 'GET',
    //     headers: {
    //       'Authorization': 'Bearer ' + userObj.jwt,
    //       'Content-Type': 'application/json'
    //     },
    //     mode: 'cors',
    //   })
      
    //   fetch(request)
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then((jsonResponse) => {
       
    //     var switchArr = []
    //     for (const key in jsonResponse) {
    //       if(jsonResponse[key].active){
    //         switchArr.push(jsonResponse[key].document_id)
    //       }
    //     }

    //     this.documentData = jsonResponse
    //     this.documentSwitches = switchArr
      
    //   })      
    // },
    
    // updateActive ($arr,id) {
      
    //   const userObj = JSON.parse(localStorage.getItem('rccpa'))

    //   var active = ($arr.includes(id) ? 1 : 0)
    //   var endpoint = `/portal-documents/${id}`
    //   const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
    //   let postData = {
    //     action: "active",
    //     active: active
    //   }

    //   const request = new Request(url, {
    //     method: 'PUT',
    //     headers: {
    //       'Authorization': 'Bearer ' + userObj.jwt,
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(postData),
    //     mode: 'cors',
    //   })
      
    //   fetch(request)
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then((jsonResponse) => {

    //     if(jsonResponse.affectedRows){
    //       this.$root.$emit("notify","success","Active status updated")
    //     }else{
    //       this.$root.$emit("notify","error","There was an error processing this request")
    //     }
        
    //   })
    // },
    // updateItem(id) {
    //   this.$router.push(`/edit-portal-content/${id}`)
    // },
    // updateModal (id) {
    //   this.$root.$emit("openEditPortalContentModal",id)
    // },
    // showDeletDialog (id,name, doc) {
    //   this.dialogDelete = true
    //   this.dialogDeleteId = id
    //   this.dialogDeleteName = name
    //   this.dialogDeleteDocument = doc
    // },
    // deleteContent () {
    //   const userObj = JSON.parse(localStorage.getItem('rccpa'))

    //   var endpoint = `/portal-documents/${this.dialogDeleteId}`
    //   const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
    //   let postData = {
    //     documentName: this.dialogDeleteDocument
    //   }
      
    //   const request = new Request(url, {
    //     method: 'DELETE',
    //     headers: {
    //       'Authorization': 'Bearer ' + userObj.jwt,
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(postData),
    //     mode: 'cors',
    //   })
      
    //   fetch(request)
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then((jsonResponse) => {
        
    //     if(jsonResponse.affectedRows){

    //       this.getPortalDocument()
    //       this.$root.$emit("notify","success","Document has been deleted")
    //       this.resetDialogDelete()
    //     }else{
    //       this.$root.$emit("notify","error","There was an error processing this request")
    //     }
        
    //   })
    // },
    // resetDialogDelete(){
    //   this.dialogDelete = false
    //   this.dialogDeleteId = null,
    //   this.dialogDeleteName = null
    //   this.dialogDeleteDocument = null
    // },
    // initSortable() {
    //   //let table = document.querySelector(".v-datatable tbody");
    //   let table = document.querySelector("tbody");
 
    //   const _self = this;
     
    //   _self.dragNdrop = JSON.parse(JSON.stringify(_self.documentData));

    //   Sortable.create(table, {
    //     onEnd({
    //       newIndex,
    //       oldIndex
    //     }) {
    //       _self.dragNdrop.splice(
    //         newIndex,
    //         0,
    //         ..._self.dragNdrop.splice(oldIndex, 1)
    //       );
    //       // console.log(_self.dragNdrop);
          
    //       //PUSH TO API TO UPDATE
    //       var arrOrder = _self.dragNdrop
      
    //       const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
    //       var endpoint = `/display-order`
    //       const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
    //       let postData = {
    //         table: "documents",
    //         order: arrOrder
    //       }

    //       const request = new Request(url, {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': 'Bearer ' + userObj.jwt,
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(postData),
    //         mode: 'cors',
    //       })
      
    //       fetch(request)
    //       .then(response => {
    //         return response.json();
    //       })
    //       .then((jsonResponse) => {

    //         if(jsonResponse.success){
    //           orderNotification('success')
    //           // this.$root.$emit("notify","success","Display order has been updated")
    //         }else{
    //           orderNotification('error')
    //           // this.$root.$emit("notify","error","There was an error processing this request")
    //         }

    //       })          

    //     }
    //   });
          
    // },
    
  },
  components: {
    nestedDraggable
  }
}
</script>

