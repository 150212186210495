<template>
  <div id="login-container">
    <div style="height:100px;">
    <v-row justify="center" class="">
      <v-col cols="12" lg="3">
        <v-alert 
          :value="alert"
          type="error"
          transition="scroll-y-transition"
        >
          {{alertMsg}}
        </v-alert>
      </v-col>
    </v-row>
  </div>
    <v-row justify="center" class="">
      <v-col cols="12" xl="4" lg="5" md="8">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="validate"
        >
          <v-card elevation="2" class="pa-8">
            <v-row class="mt-4 mb-6">
              <v-col>
                <v-img max-width="200" :src="require('../assets/RareCyte_Logo.jpg')"/>
              </v-col>
            </v-row>
            
            <v-text-field
              v-model="email"
              :rules="[
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
              ]"
              outlined
              label="Email"
              prepend-inner-icon="mdi-email"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              :rules="[
                v => !!v || 'Password is required',
              ]"
              outlined
              label="Password"
              prepend-inner-icon="mdi-lock"
              type="password"
              required
            ></v-text-field>
             
            <v-btn
              :loading="loading"
              :disabled="loading"
              class=""
              color="secondary"
              @click="validate"
              large
              min-width="200"
              elevation="0"
              type="submit"
            >
              Login
            </v-btn>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    valid: true,
    email: '',
    password: '',
    loading: false,
    alert: false,
    alertMsg: ''
  }),
  mounted() {
  },
  methods: {
    validate () {
      
      if(this.$refs.form.validate()){
        
        this.loading = true

        let postData = {
          email: this.email,
          pwd: this.password
        }

        var endpoint = '/login'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json'
          },
        });

        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          setTimeout(()=>{
            
            if(!jsonResponse.success){
            
              this.alertMsg = jsonResponse.msg
              this.alert = true
              setTimeout(()=>{
                this.alert = false
                this.alertMsg = ''
              },2000)
            
            }else{

              if(jsonResponse.success && jsonResponse.jwt){

                localStorage.setItem('rccpa', JSON.stringify(jsonResponse))
                  
                setTimeout(()=>{

                  this.$router.push({ path: '/dashboard' })
                  this.$root.$emit("_setDashboard") 
                },250)

              }else{

                this.alertMsg = jsonResponse.msg
                this.alert = true
                setTimeout(()=>{
                  this.alert = false
                  this.alertMsg = ''
                },2000)

              }
            }
           
            this.loading = false

          },500);  

        })      
      }
      
    },
    reset () {
      this.$refs.form.reset()
    },
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
#login-container{
  padding-top:50px;
}
</style>
