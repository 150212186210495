import { render, staticRenderFns } from "./FaqsNested.vue?vue&type=template&id=ab17a246&scoped=true&"
import script from "./FaqsNested.vue?vue&type=script&lang=js&"
export * from "./FaqsNested.vue?vue&type=script&lang=js&"
import style0 from "./FaqsNested.vue?vue&type=style&index=0&id=ab17a246&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab17a246",
  null
  
)

export default component.exports