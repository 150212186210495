<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">
        <v-toolbar
          
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <v-container>
            <v-row justify="center" class="mt-5">
              <v-col cols="12" md="4" sm="6">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-card outlined>
                    <v-card-title>
                      <v-icon large left>
                        {{dialogIcon}}
                      </v-icon>
                      <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div class="modal-form-container">
                      <v-text-field
                        v-model="name"
                        :rules="[v => !!v || 'Name is required',]"
                        label="Full Name"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="email"
                        :rules="[
                          v => !!v || 'E-mail is required',
                          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                        ]"
                        label="Email"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          v => !!v || 'Password is required',
                          v => v.length >= 8 || 'Min 8 characters',
                        ]"
                        :type="show1 ? 'text' : 'password'"
                        label="Password"
                        hint="At least 8 characters"
                        persistent-hint
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-btn
                        text
                        color="info"
                        large
                        @click="reset"
                      >
                        Clear Form
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="secondary"
                        large
                        elevation="0"
                        @click="validate"
                      >
                        Add Administrator
                      </v-btn>
                      
                    </v-card-actions>
                  </v-card>
                </v-form> 
              </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: 'AddAdministratorModal',
    data: () => ({
      dialog: false,
      dialogTitle: "Add RareCyte Administrator",
      dialogIcon: "mdi-account-lock",
      valid: true,
      name: '',
      email: '',
      password: '',
      show1: false,
      loading: false,
    }),
    beforeMount() {
      this.$root.$on('openAddModal',(modal) => {
        if(modal == "Administrator"){
          this.dialog = true
        }
      })
    },
    methods: {
      validate () {
        if(this.$refs.form.validate()){
          
          this.loading = true
          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          let postData = {
            name: this.name,
            email: this.email,
            pwd: this.password
          }

          var endpoint = '/users'

          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          const request = new Request(url, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          });

          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              setTimeout(()=>{
                
                this.$root.$emit("notify","success",`${this.name} has been added as an Administrator`)
                this.$root.$emit("_resetAdministrators")
                
                this.loading = false
                this.reset()

              },500)

            }else{

              setTimeout(()=>{
                
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
                
              },500)

            }
          })      
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
