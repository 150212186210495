<template>
  <div class="content-container">
    <v-row>
      <v-col cols="12" lg="9">
        <v-card elevation="0" outlined min-height="100%">
          <v-card-title>
            <div class="overline">New Customers</div>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Company
                    </th>
                    <th class="text-left">
                      Email
                    </th>
                    <th class="text-left">
                      Phone
                    </th>
                    <th class="text-left">
                      Sign Up Date
                    </th>
                    <th class="text-left" width="120">
                      Accout Type
                    </th>
                    <th class="text-center">
                      Click to Approve Customer
                    </th>
                    <th class="text-center" width="100">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in newCustomers"
                    :key="index"
                  >
                  <td>{{ item.customer_first }} {{ item.customer_last }}</td>
                  <td>{{ item.customer_company }}</td>
                  <td>{{ item.customer_email }}</td>
                  <td>{{ item.customer_phone }}</td>
                  <td>{{ item.sign_up_date }}</td>
                  <td>
                    <v-select
                      :ref="`selectedPermission-` + item.customer_id" 
                      :items="accountTypes"
                      item-text="permission"
                      item-value="permission"
                      dense
                      :value="item.customer_permissions"
                    ></v-select>
                  </td>
                  <td class="text-center">
                    <v-btn
                      color="green darken-3"
                      class="ma-2 white--text"
                      x-small
                      elevation="0"
                      outlined
                      @click="approveCustomer(item.customer_id, item.customer_email, item.customer_first)"
                    >
                      Approve Customer
                    </v-btn>
                  </td>
                  <td class="text-center">
                    <v-btn

                      icon
                      @click="showDeletDialog(item.customer_id,item.customer_first + ' ' + item.customer_last)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-row>
          <v-col>
            <v-card elevation="0" outlined height="150">
              <v-card-title>
                <div class="overline">Customers</div>
                <v-spacer></v-spacer>
                <v-icon
                  large
                  color="blue lighten-2"
                >
                mdi-account-group
                </v-icon>
              </v-card-title>
              <v-card-text>
                <div class="text-center  pt-3">
                  <h1>{{ ttlCustomers }}</h1>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="0" outlined height="150">
              <v-card-title>
                <div class="overline">Content</div>
                <v-spacer></v-spacer>
                <v-icon
                  large
                  color="blue lighten-2"
                >
                mdi-file-document-multiple
                </v-icon>
              </v-card-title>
              <v-card-text>
                <div class="text-center pt-3">
                  <h1>{{ ttlDocuments }}</h1>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="0" outlined height="150">
              <v-card-title>
                <div class="overline">Categories</div>
                <v-spacer></v-spacer>
                <v-icon
                  large
                  color="blue lighten-2"
                >
                mdi-format-list-text
                </v-icon>
              </v-card-title>
              <v-card-text>
                <div class="text-center pt-3">
                  <h1>{{ ttlCategories }}</h1>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="0" outlined height="150">
              <v-card-title>
                <div class="overline">Admins</div>
                <v-spacer></v-spacer>
                <v-icon
                  large
                  color="blue lighten-2"
                >
                mdi-account-lock
                </v-icon>
              </v-card-title>
              <v-card-text>
                <div class="text-center  pt-3">
                  <h1>{{ ttlAdmins }}</h1>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="0" outlined>
              <v-card-title>
                <div class="overline">New Customer Sign Up Notifications</div>
              </v-card-title>
              <v-card-text>
                
                <v-list-item two-line 
                  v-for="(item, index) in adminsNotified"
                  :key="index"
                >
                  <v-list-item-avatar>
                  <v-icon
                    class="orange darken-1"
                    dark    
                  >mdi-email-arrow-right</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.user_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.user_email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text link x-small to="/administrators">
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
      <v-card>
        <v-card-title class=""></v-card-title>
        <v-card-text>
          <v-alert
            text
            prominent
            type="warning"
            icon="mdi-alert"
          >
            Are you sure you want to delete customer <strong>{{dialogDeleteName}}</strong>?
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            elevation="0"
            @click="deleteCustomer()"
          >
            yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Dashboard',
  data: () => ({
    accountTypes: [
      {permission: "Guest", id: 0},
      {permission: "Customer", id: 1},
      {permission: "Distributor", id: 2},
      {permission: "Admin", id: 3},
    ],
    ttlAdmins: null,
    ttlCategories: null,
    ttlCustomers: null,
    ttlDocuments: null,
    adminsNotified: [],
    newCustomers: [],
    dialogDelete: false,
    dialogDeleteId: null,
    dialogDeleteName: null,
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Dashboard")
    this.getDashboard()
  },
  methods: {
    closeDialog() {
      this.dialogDelete = false
    },
    getDashboard () {

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/admin-dashboard'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.success){
          this.ttlAdmins = jsonResponse.ttlAdmins
          this.ttlCategories = jsonResponse.ttlCategories
          this.ttlCustomers = jsonResponse.ttlCustomers
          this.ttlDocuments = jsonResponse.ttlDocuments
          this.adminsNotified = jsonResponse.adminsNotified
          this.newCustomers = jsonResponse.newCustomers
        }
      })

    },

    approveCustomer (id, email, firstName) {
      var ref ="selectedPermission-" + id
      var permission = this.$refs[ref][0].$el.innerText
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      let postData = {
        action: "approveCustomer",
        permission: permission,
        email: email,
        firstName: firstName
      }

      var endpoint = `/customers/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.$root.$emit("notify","success","New customer has been approved")
          this.getDashboard()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }

      })
    },

    showDeletDialog(id,name){
      this.dialogDelete = true
      this.dialogDeleteId = id,
      this.dialogDeleteName = name
    },

    deleteCustomer () {

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/customers/${this.dialogDeleteId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.getDashboard()
          this.$root.$emit("notify","success","Customer has been deleted")
          this.resetDialogDelete()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    resetDialogDelete(){
      this.dialogDelete = false
      this.dialogDeleteId = null,
      this.dialogDeleteName = null
    },
    getDoc() {
      //this.categoryData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/view-document/a3e7b2e566772b8d23f2ee5bc6f452ce'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then((res) => { return res.blob(); })
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = "a3e7b2e566772b8d23f2ee5bc6f452ce";
        a.click();
      })
    }
  },
  components: {
  }
}
</script>

