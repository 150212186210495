<template>
  <div>
    <draggable class="nested-sortable" tag="ul" :list="subcats" :group="{ name: 'g1' }" 
      :move="checkMove" 
      >
      <li v-for="el, index in subcats" :key="el.ID">
        <v-row>
          <v-col cols class="col-auto mt-4 ml-3 move-me">
            <v-icon>mdi-arrow-up-down</v-icon>  
          </v-col>
          <v-col>
            <v-expansion-panels  flat>
              <v-expansion-panel>
                <v-expansion-panel-header>{{ el.faq_title }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="el.faq_content"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols class="col-auto">
            <v-switch
              label="Active"
              false-value="0"
              true-value="1"
              :input-value="el.active.toString()"
              color="success"
              @change="updateActive($event, el.faq_ID)"
            ></v-switch>
          </v-col>
          <v-col cols class="col-auto pt-6">
            <v-btn
              icon
              color="primary"
            >
              <v-icon @click="updateItem(el.faq_ID)">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col cols class="col-auto pt-6 pr-6">
            <v-btn
              icon
            >
              <v-icon @click="showDialogDelete(el.ID,index)">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </li>
    </draggable>

    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
    <v-card>
      <v-card-title class="">
        </v-card-title>
        <v-card-text>
          <v-alert
            text
            prominent
            type="warning"
            icon="mdi-alert"
          >
            Are you sure you want to remove this FAQ?
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            elevation="0"
            @click="deleteItem()"
          >
            yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>    
</template>
  <script>
  import draggable from 'vuedraggable'
  
  export default {
    props: {
      subcats: {
        required: true,
        type: Array
      }
    },
    data: () => ({
      dialogDelete: false,
      deleteFaqId: null, 
      subcatsIndex: null,
      orderId: null,
    }),
    
    methods:{
      doDelete: function(index){
        this.subcats.splice(index, 1);
      },
      checkMove: function (evt) {
       
        //const collection = document.getElementsByClassName("nested-sortable");
        // var toLvl = $(to.el).parents('.nested-sortable').length;

       // console.log(evt.to.childNodes)
       
       const parentLi = evt.to.parentNode
       const parentUl = parentLi.parentNode
    // const parentLi2 = parentUl.parentNode

       if( parentUl.nodeName === 'UL'){
        return false
       }

       setTimeout(()=>{
        this.updateOrder()
       },1000)

       
      // console.log(parentLi2.nodeName)
      //  return false
      //console.log("=> checkMove");
      //console.log("evt.draggedContext.element");
      //var itemElem = evt.draggedContext.element;
      //console.log(itemElem);
      //console.log(this.tasks.length)
       // console.log(evt.to);
      // console.log(evt.from);
      // console.log("evt.to");
      // console.log(evt.to);
      
      // no items outside a category
      // if (itemElem.type === "inventoryItem") {
      //   if (evt.to.childNodes.length > 0) {
      //     var k = 0;
      //     for (var i = 0; i < evt.to.childNodes.length; ++i) {
      //       if (
      //         evt.to.childNodes[i].getAttribute("element-type") ===
      //         "inventoryCategory"
      //       ) {
      //         k++;
      //       }
      //     }
      //     if (k === evt.to.childNodes.length) {
      //       return false;
      //     }
      //   } else {
      //     return true;
      //   }
      // }
      // console.log("child nodes:");
      // for (var i = 0; i < evt.to.childNodes.length; ++i) {
      //   console.log(evt.to.childNodes[i]);
      // }
      // No nested categories
      // if (evt.from !== evt.to && itemElem.type === "inventoryCategory") {
      //   return false;
      // }
      // return true;
      // if (evt.from === evt.to) console.log("=");
      // console.log("evt.draggedContext.element");
      // console.log(evt.draggedContext.element);
      // console.log("evt.from");
      // console.log(evt.from);
      // console.log("evt.to");
      // console.log(evt.to);
      // console.log("=> /checkMove");
      // return true;
      // return !((evt.from.getAttribute('element-type') === 'inventoryCategory')
      // && (evt.from.getAttribute('element-type') === 'inventoryCategory'));
      // var itemEl = evt.draggedContext.element;  // dragged HTMLElement
      // console.log('Item');
      // console.log(itemEl);
      // console.log('draggable attr');
      // // console.log(itemEl.getAttribute('element-type'));
      // console.log('evt.to');
      // console.log(evt.to);    // target list
      // console.log('evt.from');
      // console.log(evt.from);  // previous list
      // console.log('evt.oldIndex');
      // console.log(evt.oldIndex);  // element's old index within old parent
      // console.log('evt.newIndex');
      // console.log(evt.newIndex);  // element's new index within new parent
      // console.log('evt.oldDraggableIndex');
      // console.log(evt.oldDraggableIndex); // element's old index within old parent, only counting draggable elements
      // console.log('evt.newDraggableIndex');
      // console.log(evt.newDraggableIndex); // element's new index within new parent, only counting draggable elements
      // console.log('evt.clone');
      // console.log(evt.clone); // the clone element
      // console.log('evt.pullMode');
      // console.log(evt.pullMode);  // when item is in another sortable: `"clone"` if cloning, `true` if moving
      // return true;
      },
      updateOrder(){

        const orderedArr = this.subcats
        //let tempObj = {}
        let tempArr = []

        for (let i = 0; i < orderedArr.length; i++) {
          // tempObj = {
          //   id: orderedArr[i].ID,
          //   order: i 
          // }
          
          tempArr.push(orderedArr[i].ID)
        }

        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        
        var endpoint = `/portal-faq-category-assignments/0`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "displayOrder",
          newOrder: tempArr
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.success){
            
            setTimeout(()=>{
              
              this.$root.$emit("notify","success","Display order has been updated")
              
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              
            },500) 
          }

        })
        

      },
      updateActive(event, id){
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-faqs/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "active",
          active: event,
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
                        
            setTimeout(()=>{
              this.$root.$emit("notify","success","FAQ status has been updated")
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              this.loading = false
            },500) 
          }
        })

      },
      showDialogDelete(id, index){
        this.dialogDelete = true
        this.orderId = id
        this.subcatsIndex = index
      },
      deleteItem(){
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        // var endpoint = `/portal-faqs/${this.deleteFaqId}`
        var endpoint = `/portal-faq-category-assignments/${this.orderId}`
      
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            this.$root.$emit("notify","success","FAQ has been deleted")
            
            this.subcats.splice(this.subcatsIndex, 1);
            this.resetDialogDelete()

            // this.list = newNavArr

            // setTimeout(()=>{
            //   this.updateCategoryHierarchy()
            // }, 300)

            // setTimeout(()=>{
            //   this.getCategories()
            //   this.getCategorySchema() 
            // }, 750)

          }else{
            this.$root.$emit("notify","error","There was an error processing this request")
            // this.resetDialogDelete()
            // this.getCategories()
          }
          
        })
      },
      resetDialogDelete(){
        this.dialogDelete = false
        this.deleteFaqId = null
        this.subcatsIndex = null
        this.orderId = null
      },
      updateItem(id) {
        this.$router.push(`/edit-faq/${id}`)
      },
    },
    components: {
      draggable
    },
    name: "nested-draggable"
  };
  </script>
  <style scoped>
  ul.nested-sortable {
    min-height: 10px;
    margin: 0 0 0 -25px;
    list-style: none;
    padding-bottom: 5px;

    li{
      
      margin: 0 0 25px 0;
      outline: 1px dashed;
      

      ul li{
        outline: 1px dashed;
        margin: 0 0 10px 0;
      }
    }

    .move-me{
      cursor: move;
    }
  }
  </style>