<template>
  <v-app>
    <!-- begin: LEFT NAV -->
    <v-navigation-drawer app color="#fff" v-if="loggedIn">
      <div class="px-3 pt-1 overline">
        Customer Portal Admin
      </div>
      <div class="px-3 pb-5">
        <v-img 
        :src="require('./assets/RareCyte_Logo.jpg')"
        />
      </div>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title >
                  {{userName}}
                </v-list-item-title>
                <v-list-item-subtitle>Logged In</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item link
            @click="showEditPasswordModal"
          >
            <v-list-item-title>Update Password</v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-lock</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link
            @click="logMeOut"
          >
            <v-list-item-title>Logout</v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-power</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item-group
          v-model="adminAppNavItem"
          color="primary"
        >
          <v-list-item link to="/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/customers">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Customers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            prepend-icon="mdi-file-document-multiple"
            color="primary"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title>Portal Content</v-list-item-title>
            </template>

            <v-list-group
              :value="false"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Docs, Links & Vids</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                link
                v-for="(item, i) in contentItems"
                :key="i"
                :to="item.link"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>FAQs</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                link
                v-for="(item, i) in faqItems"
                :key="i"
                :to="item.link"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-group>

          <v-list-item link to="/administrators">
            <v-list-item-icon>
              <v-icon>mdi-account-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Administrators</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>

        <!-- <v-list-item-group
          v-model="adminAppNavItem"
          color="primary"
        >
        

          <v-list-item
            link
            v-for="(item, i) in items"
            :key="i"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group> -->
      </v-list>
    </v-navigation-drawer>
    <!-- end: LEFT NAV -->
    <v-app-bar flat color="#fff" style="border-bottom:solid 1px #e0e0e0" app v-if="loggedIn">
      <v-icon class="mr-3">{{pageTitleIcon}}</v-icon> 
      <v-toolbar-title>{{pageTitle}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-5"></v-divider>
      <!-- begin: TOP NAV ADD -->
      <template>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                v-bind="attrs"
                v-on="on"
                large
                text
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-subheader>ADD NEW</v-subheader>
              <v-list-item
                v-for="(item, index) in addNavItems"
                :key="index"
                @click="showAddModal(index)"
              >
              <v-list-item-icon x-small>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <!-- end: TOP NAV ADD -->
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer v-if="loggedIn">
      <!-- -->
    </v-footer>

    <add-administrator-modal></add-administrator-modal>
    <add-category-modal></add-category-modal>
    <add-portal-content-modal></add-portal-content-modal>
    <add-customer-modal></add-customer-modal>
    <edit-password-modal-vue></edit-password-modal-vue>
  </v-app>
</template>
<script>
//import jwt_decode from "jwt-decode";
import AddAdministratorModal from './components/AddAdministratorModal.vue'
import AddCategoryModal from './components/AddCategoryModal.vue'
import AddPortalContentModal from './components/AddPortalContentModal.vue'
import AddCustomerModal from './components/AddCustomerModal.vue'
import EditPasswordModalVue from './components/EditPasswordModal.vue'
export default {
  name: 'App',

  data: () => ({
    loggedIn: false,
    adminAppNavItem: null,
    pageTitle: '',
    pageTitleIcon: '',
    addNavItems: [
      { title: 'Customer', icon: 'mdi-account-group'},
      { title: 'Portal Content', icon: 'mdi-file-document-multiple'},
      { title: 'FAQ', icon: 'mdi-file-question'},
      { title: 'Administrator', icon: 'mdi-account-lock'},
    ],
    items: [
      { text: 'Dashboard', icon: 'mdi-monitor-dashboard', link: { path: '/dashboard'} },
      { text: 'Customers', icon: 'mdi-account-group', link: '/customers' },
      { text: 'Portal Content', icon: 'mdi-file-document-multiple', link: '/portal-content' },
      { text: 'FAQs', icon: 'mdi-file-document-multiple', link: '/portal-faqs' },
      { text: 'Categories', icon: 'mdi-format-list-text', link: '/categories' },
      { text: 'Administrators', icon: 'mdi-account-lock', link: '/administrators' },
    ],
    userName: null,
    userEmail: null,
    contentItems: [
      { text: 'Content Categories', link: { path: '/content-categories'} },
      { text: 'Content', link: '/portal-content' },
    ],
    faqItems: [
      { text: 'Categories', link: { path: '/faq-categories'} },
      { text: 'FAQs', link: '/portal-faqs' },
    ],
  }),
  beforeMount() {

    this.verifyUser(this.$route.name)

    this.$root.$on('_setDashboard',() => {
      this.verifyUser('Dashboard')
    })

    this.$root.$on('_loggedIn',(pg) => {
      this.pageTitle = pg
      
      switch(pg) {
        case "Dashboard":
        this.pageTitleIcon = 'mdi-monitor-dashboard'
        break;
        case "Customers":
        this.pageTitleIcon = 'mdi-account-group'
        break;
        case "Portal Content":
        this.pageTitleIcon = 'mdi-file-document-multiple'
        break;
        case "Add Portal Content":
        this.pageTitleIcon = 'mdi-file-document-multiple'
        break;
        case "Categories":
        this.pageTitleIcon = 'mdi-format-list-text'
        break;
        case "Administrators":
        this.pageTitleIcon = 'mdi-account-lock'
        break;
      } 
      
    })
    this.$root.$on('_loggedOut',() => {
      this.logMeOut()  
    })
    this.$root.$on('notify',(type,msg) => {
      this.showToasts(type,msg)  
    })
    if(this.$route.name == 'Login'){
      this.loggedIn = false
    }
    this.$root.$on('_getAdministrators',() => {
      this.getAdministrators()
    })
  },
  mounted() {
  },
  methods: {
    verifyUser(pg) {
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      if(pg !== 'Login'){

        var endpoint = '/auth'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userObj.jwt
          },
        })
        fetch(request)
        .then(response => {
          return response.json()
        })
        .then((jsonResponse) => {
          
          if(jsonResponse.authorized ){
            
            this.userName = userObj.name
            this.userEmail = userObj.email
            this.loggedIn = true
            
          }else{
            this.logMeOut()
          }
        })
      }
    },
    showEditPasswordModal(){
      this.$root.$emit("openEditPasswordModal")
    },
    showAddModal(i) {

      //override
      if(this.addNavItems[i].title === 'Portal Content'){

        this.$router.push({ path: '/add-portal-content' })
        
        return;
      }
      if(this.addNavItems[i].title === 'Portal FAQ'){

        this.$router.push({ path: '/add-faq' })

        return;
      }

      var modal = this.addNavItems[i].title
      this.$root.$emit("openAddModal",modal)
    },
    showToasts(type,msg){
      var options = {
        timeout: 3000,
        hideProgressBar: true,
      }
      switch (type) {
        case 'success':
          this.$toast.success(msg, options)
          break
        case 'info':
          this.$toast.info(msg, options)
          break
        case 'warning':
          this.$toast.warning(msg, options)
          break
        case 'error':
          this.$toast.error(msg, options)
          break    
        default:
          this.$toast(msg, options)
      }
    },
    logMeOut(){
      localStorage.clear()
      localStorage.removeItem('rccpa')
      this.userName = null
      this.userEmail = null
      this.loggedIn = false
      this.$router.push({ path: '/' })
    },
    getAdministrators () {

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/users'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        return jsonResponse
      })

    }
  },
  components: {
    AddAdministratorModal,
    AddCategoryModal,
    AddPortalContentModal,
    AddCustomerModal,
    EditPasswordModalVue
  }
};
</script>
<style lang="scss">
.v-application, .modal-bg {
  background-color: #f6f8fa !important;
}
.modal-form-container{
  padding: 20px 20px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.content-container{
  padding-top: 20px;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>