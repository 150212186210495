<template>
    <div class="content-container">
      <v-row justify="center">
        <v-col cols="12" lg="4" md="5">
          <v-card outlined>
            <v-card-title>
              FAQ Categories
            </v-card-title>
            <v-card-text>
              <v-card flat outlined>
                <v-card-text>

                  <v-row>
                    <v-col>
                      <v-form
                        ref="form"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="newFaqCategory"
                          append-outer-icon="mdi-plus"
                          label="Add FAQ Category"
                          :rules="[v => !!v || 'FAQ Category Name is required',]"
                          type="text"
                          @click:append-outer="addCat"
                        ></v-text-field>
                      </v-form>
                    </v-col>
                  </v-row>

                  <v-divider class="mt-2 mb-5"></v-divider>

                  <v-row>
                    <v-col>

                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Filter Categories"
                        single-line
                        hide-details
                        outlined
                        dense
                        class="mt-2"
                      ></v-text-field>

                      <v-data-table
                        v-model="selected"
                        dense
                        class="mt-5"
                        :headers="headers"
                        :items="faqs"
                        :search="search"
                        hide-default-footer
                        :items-per-page=-1
                        height="450"
                        item-key="faq_cat_ID"
                        show-select
                        :single-select="singleSelect"
                      >
                        <template v-slot:item.edit="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.faq_category"
                            large
                            persistent
                            @save="updateCatName(props.item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            <template v-slot:input>
                              <div class="mt-4 text-h6">
                                Update FAQ Category
                              </div>
                              <v-text-field
                                v-model="props.item.faq_category"
                                label="Edit"
                                single-line
                                autofocus
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          
                          <v-icon
                            small
                            @click="showDeletDialog(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template> 
                      </v-data-table>
            
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-4">
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="loading"
                    color="primary"
                    elevation="0"
                    text
                    @click="addSelectedCats"
                  >Add Selected</v-btn>
                </v-card-actions>
              </v-card>   
            </v-card-text>
           
          </v-card>
        </v-col>
        <v-col cols="12" lg="8" md="7">
          
          <v-card outlined>
            <v-card-title>
              FAQ Category Hierarchy
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    elevation="0"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="resetHierarchy"
                  >
                    <v-icon dark>
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Reset FAQ Hierarchy </span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <div v-if="list.length === 0" style="min-height: 600px;">
                No Data
              </div>
              <nested-draggable :subcats="list" />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn 
                :loading="loadingHierarchy"
                color="secondary"
                elevation="0"
                @click="updateCategoryHierarchy"
              >Update Category Hierarchy</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialogDelete"
        width="500"
      >
      <v-card>
        <v-card-title class="">
          </v-card-title>
          <v-card-text>
            <v-alert
              text
              prominent
              type="warning"
              icon="mdi-alert"
            >
              Are you sure you want to delete <strong>{{dialogDeleteName}}</strong> from FAQ Categories?
            </v-alert>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              elevation="0"
              @click="deleteItem()"
            >
              yes, delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </template>
  <script>
  import nestedDraggable from '../components/FaqCategoriesNested.vue'

  export default {
    name: 'FaqCategories',
    data: () => ({
      loading: false,
      loadingHierarchy: false,
      newFaqCategory: null,
      currentCatStructure: [],
      list: [],
      selected: [],
      singleSelect: false,
      search: '',
      headers: [
        {
          text: 'FAQ Category',
          align: 'start',
          value: 'faq_category',
        },
        { 
          text: 'Edit', 
          value: 'edit', 
          sortable: false,
          align: 'end'
        },
        { 
          text: 'Delete', 
          value: 'actions', 
          sortable: false,
          align: 'end'
        },
      ],
      faqs: [],
      dialogDelete: false,
      dialogDeleteId: null,
      dialogDeleteName: null
    }),
    beforeMount() {
      this.$root.$emit("_loggedIn","Portal Content: FAQ Categories")
      this.getCategories()
      this.getCategorySchema()
    },
    methods: {
      resetHierarchy(){
        this.getCategories()
        this.getCategorySchema()
      },
      addCat() {
        if(this.$refs.form.validate()){
          
          this.loading = true

          const userObj = JSON.parse(localStorage.getItem('rccpa'))
          
          let postData = {
            faqCategory: this.newFaqCategory
          }

          var endpoint = '/portal-faq-categories'

          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          const request = new Request(url, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          });

          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              setTimeout(()=>{
                
                this.$root.$emit("notify","success",`${this.newFaqCategory} has been added`)
                
                this.reset()
                this.getCategories()
                this.loading = false
              },500)

            }else{

              setTimeout(()=>{
                
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
                
              },500)

            }
          })

        }
      },
      reset () {
        this.$refs.form.reset()
        this.showCatIcon = false;
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      getCategories(){
      
        this.faqs = []
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-faq-categories'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
        
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          
          this.faqs = jsonResponse
          
        })
      
      },
      updateCatName(item){
        const id = item.faq_cat_ID
        const newCatName = item.faq_category

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-faq-categories/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "category",
          name: newCatName,
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            //update nav JSON
            this.synchHierarchy(id,newCatName)
            
            setTimeout(()=>{
              this.$root.$emit("notify","success","FAQ Category has been updated")
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              this.loading = false
            },500) 
          }
        })


      },
      synchHierarchy(id,newCatName) {
        
        let navObj = this.currentCatStructure
        let newNavArr = []
        let loopId, loopCat, loopSlug, loopSubCatArr, loopSubCatObj, subsId, subsCat, subsSlug, tempSubsArr, tempObj

        for (let i = 0; i < navObj.length; i++) {
          loopId = navObj[i].faq_cat_ID
          loopCat = navObj[i].faq_category
          loopSlug = navObj[i].faq_category_slug

          if(id === loopId) {
            loopCat = newCatName
          }

          loopSubCatArr = navObj[i].subcats
          tempSubsArr  = []

          if(loopSubCatArr.length > 0){
            for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
              subsId = loopSubCatArr[iSubs].faq_cat_ID
              subsCat = loopSubCatArr[iSubs].faq_category
              subsSlug = loopSubCatArr[iSubs].faq_category_slug

              if(id === subsId) {
                subsCat = newCatName
              }

              loopSubCatObj = {
                faq_cat_ID: subsId,
                faq_category: subsCat,
                faq_category_slug: subsSlug,
                subcats: []
              }

              tempSubsArr.push(loopSubCatObj)

            }
          }

          tempObj = {
            faq_cat_ID: loopId,
            faq_category: loopCat,
            faq_category_slug: loopSlug,
            subcats: tempSubsArr  
          }

          newNavArr.push(tempObj)

        }

        this.list = newNavArr

        setTimeout(()=>{
          this.updateCategoryHierarchy()
        }, 300)

        setTimeout(()=>{
          this.getCategories()
          this.getCategorySchema() 
        }, 750)

      },
      addSelectedCats() {

        this.loading = true
        let tempArr = this.list
        let addedCats = this.selected

        let i = 0;

        let tempObj = {}

        while (i < addedCats.length) {
          tempObj = {
            faq_cat_ID: addedCats[i].faq_cat_ID,
            faq_category: addedCats[i].faq_category,
            faq_category_slug: addedCats[i].faq_category_slug,
            subcats: []
          }
          tempArr.push(tempObj)
          i++;
        }

        this.list = tempArr
        this.selected = []

        setTimeout(()=>{
          this.loading = false
        },500)
         //tempArr.push(addedCats)
      },
      getCategorySchema() {
        this.faqs = []
        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        const target = 'faq'
        var endpoint = `/portal-category-schemas/${target}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
                
          if(jsonResponse[0].category_schema_json){
            this.list = JSON.parse(jsonResponse[0].category_schema_json)
            this.currentCatStructure = this.list
          }

        })  
      },
      updateCategoryHierarchy() {
        
        this.loadingHierarchy = true

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        const target = 'faq'
        var endpoint = `/portal-category-schemas/${target}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          category_schema_json: this.list
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            setTimeout(()=>{
              
              this.$root.$emit("notify","success","FAQ Category Schema has been updated")
              this.loadingHierarchy = false
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              this.loadingHierarchy = false
            },500) 
          }
        })
      },
      showDeletDialog(item){
        this.dialogDelete = true
        this.dialogDeleteId = item.faq_cat_ID,
        this.dialogDeleteName = item.faq_category
      },
      resetDialogDelete(){
        this.dialogDelete = false
        this.dialogDeleteId = null,
        this.dialogDeleteName = null
      },
      deleteItem(){
        
        const id = this.dialogDeleteId
        
        let navObj = this.currentCatStructure
        let newNavArr = []

        let loopId, loopCat, loopSubCatArr, loopSubCatObj, subsId, tempSubsArr, tempObj, loopCatSlug

        for (let i = 0; i < navObj.length; i++) {
          
          loopId = navObj[i].faq_cat_ID
          loopCat = navObj[i].faq_category
          loopCatSlug = navObj[i].faq_category_slug

          if(id === loopId) {
            continue
          }

          loopSubCatArr = navObj[i].subcats
          tempSubsArr  = []

          if(loopSubCatArr.length > 0){
            
            for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
              
              subsId = loopSubCatArr[iSubs].faq_cat_ID
              
              if(id === subsId) {
                continue
              }else{

                loopSubCatObj = {
                  faq_cat_ID: subsId,
                  faq_category: loopSubCatArr[iSubs].faq_category,
                  faq_category_slug: loopSubCatArr[iSubs].faq_category_slug,
                  subcats: []
                }

                tempSubsArr.push(loopSubCatObj)
              }

            }
          }

          tempObj = {
            faq_cat_ID: loopId,
            faq_category: loopCat,
            faq_category_slug: loopCatSlug,
            subcats: tempSubsArr  
          }

          newNavArr.push(tempObj)
        }

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-faq-categories/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            this.$root.$emit("notify","success","FAQ Category has been deleted")
            
            this.resetDialogDelete()

            this.list = newNavArr

            setTimeout(()=>{
              this.updateCategoryHierarchy()
            }, 300)

            setTimeout(()=>{
              this.getCategories()
              this.getCategorySchema() 
            }, 750)

          }else{
            this.$root.$emit("notify","error","There was an error processing this request")
            this.resetDialogDelete()
            this.getCategories()
          }
          
        })
      },
      
    },
    
    components: {
      nestedDraggable
    }
  }
  </script>
  <style lang="scss" scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
 
  </style>