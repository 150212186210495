<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="8">
        <v-card outlined>
          <v-card-title>
            <v-icon>mdi-file-question-outline</v-icon>  Edit FAQ Content
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-form
                  ref="myForm"
                  lazy-validation
                >
                  <div class="mb-7">
                    <v-sheet
                      class="pa-6"
                      outlined
                    >
                      <div class="overline mb-4">FAQ Information</div>

                      <v-text-field
                        v-model="faq.question"
                        :rules="[v => !!v || 'Question is required',]"
                        label="FAQ Question *"
                        required
                      ></v-text-field>
                      
                      <h4 class="font-weight-regular mt-4 mb-3">FAQ Answer</h4>
                      <div class="mb-6">
                        <vue-editor v-model="faq.answer" />
                      </div> 
                      <v-switch
                        class=""
                        v-model="faq.active"
                        color="success"
                        label="Active"
                        inset
                        hide-details
                      ></v-switch> 
                    </v-sheet>
                  </div>
                  <div class="mb-7">
                    <v-sheet
                      class="pa-6"
                      outlined
                    >
                      <div class="overline">Assign to FAQ Categories</div>

                      <div class="mt-5">
                        <v-treeview
                          v-model="selectedFaqCats"
                          selectable
                          item-disabled="locked"
                          :items="faqHierarchy"
                        ></v-treeview>
                      </div>
                    </v-sheet>
                  </div>
                 
                  <div>
                    <v-sheet
                      class="pa-6"
                      outlined
                    >  
                      <v-row>
                        <v-col cols="12" lg="7">
                          <div class="overline mb-5">Assign Tags</div>

                          <div class="caption">
                            
                            <v-row class="mb-5">
                              <v-col class="col-auto">
                                <v-icon color="warning">mdi-alert-circle</v-icon>
                              </v-col>
                              <v-col>Use drop-down to select tags. If your desired tag is not on the list, you can add by clicking the "Managage FAQ Tag Database" button below.</v-col>
                            </v-row>

                          </div>
                          <div>
                            <v-combobox
                              label="Select Tags"
                              v-model="tagSelect"
                              :items="tagSelectItems"
                              :item-value="tagSelectItems.tag_ID"
                              :item-text="tagSelectItems.tag"
                              clearable
                              multiple
                              small-chips
                            ></v-combobox>
                          </div>
                          <div class="float-right">
                            <v-btn
                              text
                              color="primary"
                              x-small
                              @click="manangeTags"
                            >
                              Manage FAQ Tag Database
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </div>
                </v-form>    
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn
              text
              color="info"
              large
              @click="reset"
            >
              Reset Form
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              large
              elevation="0"
              class="px-10"
              @click="validate"
            >
              Update FAQ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>   
    <edit-faq-tag-modal></edit-faq-tag-modal>
  </div>
  
</template>
<script>
import { VueEditor } from "vue2-editor"
import EditFaqTagModal from '../components/EditFaqTagModal.vue'

export default {
  name: 'EditFaq',
  data: () => ({
    faq: {
      question: null,
      answer: null,
      active: true
    },
    faqHierarchy: null,
    selectedFaqCats: [],
    selectedFaqCatsPageLoad: [],
    tags: [],
    tagData: [],
    tagSelect: null,
    selectedFaqTagsPageLoad: [],
    tagSelectItems: [],
    newTag: '',
    newFaqTag: null,
    search: '',
    selectedTags: [],
    singleSelect: false,
    headers: [
      {
        text: 'FAQ Tag',
        align: 'start',
        value: 'tag',
      },
      { 
        text: 'Edit', 
        value: 'edit', 
        sortable: false,
        align: 'end'
      },
      { 
        text: 'Delete', 
        value: 'actions', 
        sortable: false,
        align: 'end'
      },
    ],
    loading: false,
  
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Edit FAQ Content")
    this.getCategorySchema()
    this.getFaqTags()
  },
  mounted() {
    this.$root.$on('resetFaqTags',() => {
      this.getFaqTags()
    })

    setTimeout(()=>{
      this.getFaq(this.$route.params.id)
    },1000)
    

  },
  methods:{
    async getFaq(id){
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-faqs/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
        
      const faqData = await fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        return jsonResponse
        
      })

      if(!faqData.success){
        //error
        return
      }
      
      this.faq.question = faqData.faq[0].faq_title
      this.faq.answer = faqData.faq[0].faq_content
      this.faq.active = (faqData.faq[0].active === 1) ? true : false

      const categoryIds = faqData.cats
      let catsArr = []

      for (let i = 0; i < categoryIds.length; i++) {
        catsArr.push(categoryIds[i].faq_cat_ID)
      }
      
      this.selectedFaqCats = catsArr
      this.selectedFaqCatsPageLoad = faqData.cats
     
      if(faqData.tags){
        const tagIds = faqData.tags
        const tagsArr = []
        let tagObj = {}

        for (let ii = 0; ii < tagIds.length; ii++) {

          tagObj = {
            text: tagIds[ii].tag,
            value: tagIds[ii].tag_ID
          }
          tagsArr.push(tagObj)
        }

        this.tagSelect = tagsArr
        this.selectedFaqTagsPageLoad = faqData.tags
      } 


    },
    async addTag(){

      let newTag = this.newFaqTag
      if (newTag.trim().length === 0) {
        return
      }

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
          
      let postData = {
        tag: this.newFaqTag
      }

      var endpoint = '/portal-faq-tags'

      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      const request = new Request(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      });

      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){

          this.newFaqTag = ''
          
          const newID = jsonResponse.insertId 

       //   setTimeout(()=>{
            this.$root.$emit("notify","success",`${this.newFaqTag} has been added`)
            this.getFaqTags()
       //   },500)

          setTimeout(()=>{
            
            let selectedTags = this.tagSelect
            let tempSelectedTags = []
            for (let i = 0; i < selectedTags.length; i++) {
              tempSelectedTags.push(selectedTags[i])
            }

            let tempObj = {
              text: this.newFaqTag,
              value: newID
            }

            tempSelectedTags.push(tempObj)

            this.tagSelect = tempSelectedTags


          },250)

        }else{

          setTimeout(()=>{
            
            this.$root.$emit("notify","error","There was an error processing this request")
            
            
          },500)

        }
      })

        
    },
    getFaqTags(){
      
      this.tagSelectItems = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/portal-faq-tags'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
        
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        let arr = []
        let tempArr = jsonResponse
        for (let i = 0; i < tempArr.length; i++) {
          
          let tempObj = {
            text: tempArr[i].tag,
            value: tempArr[i].tag_ID
          }

          arr.push(tempObj)
        }
        this.tagSelectItems = arr
        
      })
    },
    manangeTags(){
      this.$root.$emit("openEditFaqTagModal")
    },
    getCategorySchema() {
      this.faqHierarchy = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      const target = 'faq'
      var endpoint = `/portal-category-schemas/${target}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse[0].category_schema_json){
            this.formatTree(JSON.parse(jsonResponse[0].category_schema_json))
        }
        // const success = (JSON.parse(jsonResponse[0].category_schema_json)) ? true : false
        
        // const jsonData = ( success ) ? JSON.parse(jsonResponse[0].category_schema_json) : null

        // const respObj = {
        //   success: success,
        //   data: jsonData
        // }
      
       

      })  
    },
    formatTree(json){
      
      let tempTreeObj = {}
      let tempTreeArr = []
      let tempSubsArr  = []
      let loopSubCatArr = []
      let loopSubCatObj = {}

      for (let i = 0; i < json.length; i++) {

        loopSubCatArr = []
        tempSubsArr  = []

        loopSubCatArr = json[i].subcats
        if(loopSubCatArr.length > 0){
          
          for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
            loopSubCatObj = {
              id: loopSubCatArr[iSubs].faq_cat_ID,
              name: loopSubCatArr[iSubs].faq_category,
              locked: false,
              children: []
            }

            tempSubsArr.push(loopSubCatObj)

          }

          

        }

        tempTreeObj = {
          id: json[i].faq_cat_ID,
          name: json[i].faq_category,
          locked: false,
          children: tempSubsArr
        }

        tempTreeArr.push(tempTreeObj)
      }

      this.faqHierarchy = tempTreeArr
      /*

       {
          id: 1,
          name: 'Applications :',
          locked: true,
          children: [
            { id: 2, name: 'Calendar : app' },
            { id: 3, name: 'Chrome : app' },
            { id: 4, name: 'Webstorm : app' },
          ],
        },

      */

      /*
      {
        "faq_cat_ID": 16,
        "faq_category": "Instrument",
        "subcats": [
          {
            "faq_cat_ID": 17,
            "faq_category": "Specifications",
            "subcats": []
          },
          {
            "faq_cat_ID": 18,
            "faq_category": "Troubleshooting",
            "subcats": []
          }
        ]
      }
      */

    },
    async validate(){
     
      if(this.$refs.myForm.validate()){

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        const faqQuestion = this.faq.question
        const faqAnswer = this.faq.answer
        const active = (this.faq.active) ? "1" : "0"
        
        let postData = {
          faq_title: faqQuestion,
          faq_content: faqAnswer,
          active: active,
        }

        var endpoint = `/portal-faqs/${this.$route.params.id}`

        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        });


        const insertResult = await fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          return jsonResponse
        })

        
        if(insertResult.affectedRows === 1) {

          //HANDLE CATS UPDATE
          const selectedFaqCatsPageLoad = this.selectedFaqCatsPageLoad
          const updatedFaqCats = this.selectedFaqCats

          let catDeleteIds = []
          let catInserIds = []
          let pageLoadArr = []

          //get delete
          for (let i = 0; i < selectedFaqCatsPageLoad.length; i++) {
            
            let loopId = selectedFaqCatsPageLoad[i].faq_cat_ID
            
            pageLoadArr.push(loopId)
            
            if(!updatedFaqCats.includes(loopId)){
              catDeleteIds.push(selectedFaqCatsPageLoad[i].ID)
            }

          }

          // get new
          for (let ii = 0; ii < updatedFaqCats.length; ii++) {
            
            let loopId2 = updatedFaqCats[ii]
            
            if(!pageLoadArr.includes(loopId2)){
              catInserIds.push(loopId2)
            }

          }

          // DELETE CATEGORIES
          if(catDeleteIds && catDeleteIds.length > 0){

            const deleteCats = await this.deleteFaqCats(catDeleteIds)
            
            if(!deleteCats.success){
          
              this.$root.$emit("notify","error","There was an error updating category assignments")
            }

          }

          // INSERT CATEGORIES
          if( catInserIds && catInserIds.length > 0){
            
            const addFaqCats = await this.addFaqCats(this.$route.params.id, catInserIds)
            
            if(addFaqCats.affectedRows === 0) {
              // error alert
              this.$root.$emit("notify","error","There was an error updating category assignments")
            }

          }

          //HANDLE FAQ UPDATE

          //build array of ids
          const tagsOnPageLoad = this.selectedFaqTagsPageLoad
          let tagIdsOnPageLoad = []
          
          const tagsOnUpdate = this.tagSelect
          let tagIdsOnUpdate = []
          for (let tempIi = 0; tempIi < tagsOnUpdate.length; tempIi++) {
            tagIdsOnUpdate.push(tagsOnUpdate[tempIi].value)
          }

          // //get delete
          let tagDeleteIds = []

          for (let di = 0; di < tagsOnPageLoad.length; di++) {
            
            let loopId = tagsOnPageLoad[di].tag_ID
            tagIdsOnPageLoad.push(loopId)
            
            if(!tagIdsOnUpdate.includes(loopId)){
              tagDeleteIds.push(tagsOnPageLoad[di].ID)
            }

          }
         
          // get new
          let tagsInsert = []

          for (let dii = 0; dii < tagsOnUpdate.length; dii++) {
            
            let loopId2 = tagsOnUpdate[dii].value
            
            if(!tagIdsOnPageLoad.includes(loopId2)){
              tagsInsert.push(loopId2)
            }

          }

          // DELETE TAGS
          if(tagDeleteIds && tagDeleteIds.length > 0){

            const deleteTags = await this.deleteFaqTags(tagDeleteIds)
            
            if(!deleteTags.success){
          
              this.$root.$emit("notify","error","There was an error updating category assignments")
            }

          }

          // INSERT TAGS
          if( tagsInsert && tagsInsert.length > 0){
            
            const addTag = await this.addFaqTags(this.$route.params.id, tagsInsert)
            
            if(addTag.affectedRows === 0) {
              // error alert
              this.$root.$emit("notify","error","There was an error updating tag assignments")
            }

          }


          this.$root.$emit("notify","success",`FAQ has been updated`)
            

        }else{
          console.log(insertResult)
        }

      }
       
    },   
    async deleteFaqCats(Ids){
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-faq-category-assignments/0`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      let postData = {
        action: "multiple",
        ids: Ids,
      }

      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
    
      const delResponse = fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        return jsonResponse
        
      })    

      return delResponse

    },
    async deleteFaqTags(Ids){
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-faq-tag-assignments/0`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      let postData = {
        action: "multiple",
        ids: Ids,
      }

      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
    
      const delResponse = fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        return jsonResponse
        
      })    

      return delResponse

    },
  
    async addFaqCats(insertId, catInserIds){
      
      const faqCats = catInserIds
    
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      let postData = {
        faq_cat_IDs: faqCats,
        faq_ID: insertId,
      }

      var endpoint = '/portal-faq-category-assignments'

      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      const request = new Request(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      });

      const catsResponse = fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        return jsonResponse
      })

      return catsResponse
     
    },
    async addFaqTags(insertId, tagsInsert){
      
      // let tagIdArr = []
      
      // for (let i = 0; i < tagsInsert.length; i++) {
      //   tagIdArr.push(tagsInsert[i].value)
      // }

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      let postData = {
        tag_IDs: tagsInsert,
        faq_ID: insertId,
      }

      var endpoint = '/portal-faq-tag-assignments'

      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      const request = new Request(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      });

      const tagsResponse = fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        return jsonResponse
      })

      return tagsResponse
     
    },
    reset () {
      location.reload()
    },
  },
  components:{
    VueEditor,
    EditFaqTagModal
  }
}
</script>