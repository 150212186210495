<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">
        <v-toolbar
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <v-row justify="center" class="mt-5">
            <v-col cols="12" md="5" sm="6">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-card outlined>
                  <v-card-title>
                    <v-icon large left>
                      {{dialogIcon}}
                    </v-icon>
                    <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <div class="modal-form-container">
                    <v-text-field
                      v-model="name"
                      :rules="[v => !!v || 'Name is required',]"
                      label="Category Name *"
                      required
                    ></v-text-field>

                    <v-select
                      v-model="categoryParent"
                      :items="categoryItems"
                      label="Parent Category"
                    ></v-select>
                    <!---
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="icon"
                          :rules="[v => !!v || 'Icon is required',]"
                          label="Category Icon *"
                          required
                          hint="mdi-icon-name"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-alert
                          dense
                          type="info"
                          outlined
                        >
                        <strong><a href="https://pictogrammers.github.io/@mdi/font/6.9.96/" target="_blank">Select your icon here</a></strong>, and paste icon name in text field.
                      </v-alert>
                      </v-col>
                    </v-row>
                  -->
                      <v-textarea
                      v-model="description"
                        label="Brief Description"
                        maxlength="130"
                        rows="2"
                        counter
                      ></v-textarea>

                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            v-model="displayOrder"
                            :rules="[
                              v => !!v || 'Display Order is required',
                              v => /^[0-9]+$/.test(v) || 'Display Order must be a valid number',
                            ]"
                            label="Display Order *"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-switch
                        v-model="active"
                        color="success" 
                        inset
                        label="Active"
                        flat
                      ></v-switch>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        large
                        elevation="0"
                        @click="validate"
                      >
                        Update Category
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form> 
              </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: 'EditCategoryModal',
    data: () => ({
      dialog: false,
      dialogTitle: "Update Category",
      dialogIcon: "mdi-format-list-text",
      valid: true,
      id: '',
      icon: '',
      name: '',
      description: '',
      active: true,
      show1: false,
      loading: false,
      categoryItems: '',
      categoryParent: null,
      categoryData: [],
      displayOrder: ''
    }),
    beforeMount() {
    },
    mounted() {
      this.$root.$on('openEditCategoryModal',(item,activeArr) => {
        this.getCategories()
        this.dialog = true
        
        setTimeout(()=>{
          this.name = item.category_name
          this.id = item.category_id
          //this.icon = item.category_icon
          this.description = item.category_description
          var active = (activeArr.includes(item.category_id) ? true : false)
          this.active = active
          this.categoryParent = item.parent_id
          this.displayOrder = item.display_order
        },500)

        
      })
    },
    methods: {
      validate () {
        if(this.$refs.form.validate()){
          
          this.loading = true

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/portal-categories/${this.id}`
          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          
          var status = (this.active) ? "1" : "0"

          let postData = {
            action: "category",
            name: this.name,
            displayOrder: this.displayOrder,
            description: this.description,
            active: status,
            categoryParent: this.categoryParent
          }

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
      
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              
              setTimeout(()=>{
                this.$root.$emit("_resetCategories")
                this.$root.$emit("notify","success","Category has been updated")
                this.loading = false

                setTimeout(()=>{
                  this.dialog = false
                },3500)

              },500) 

            }else{
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
              },500) 
            }
          })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      getCategories(){
      
        this.categoryData = []
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-categories'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          let i = 0;
          let arr = []
          let loopObj = {
            text: "No Parent",
            value: null
          }
          arr.push(loopObj)

          while (i < jsonResponse.length) {
              
            loopObj = {
              text: jsonResponse[i].path,
              value: jsonResponse[i].category_id
            }
            
            arr.push(loopObj)
            i++;
          }

          this.categoryItems = arr
        
        })
      
      },
    },
    watch: {
      dialog(newValue){
        if(newValue === false){
          this.reset()
        }
      }
    }
  }
</script>
