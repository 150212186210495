<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="12" lg="7">
        <v-card outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn 
              text
              color="primary"
              @click="addNew('Administrator')"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add Administrator
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card flat outlined>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Email</th>
                        <th class="text-left" width="150">New Customer Notifications</th>
                        <th class="text-left">Active</th>
                        <th class="text-left" colspan="2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr 
                        v-for="item in userData"
                        :key="item.user_id"
                      >
                        <td>{{ item.user_name }}</td>
                        <td>{{ item.user_email }}</td>
                        <td>
                          <v-checkbox
                          
                            v-model="notificationCheckboxes"
                            :value="item.user_id"
                            @change="quickUpdate($event,item.user_id,'email_notifications ')"
                          ></v-checkbox>
                        </td>
                        <td class="">
                          <v-switch
                            v-model="userSwitches"
                            color="success"
                            :value="item.user_id"
                            inset
                            hide-details
                            @change="quickUpdate($event,item.user_id,'active')"
                          ></v-switch>
                        </td>
                        <td class="text-right">
                          <v-btn
                            icon
                            color="primary"
                            @click="updateModal(item.user_id,item.user_name,item.user_email)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td class="text-right">
                          <v-btn
                            icon
                            @click="showDeletDialog(item.user_id,item.user_name)"
                          >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>    
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
    <v-card>
        <v-card-title class="">
        </v-card-title>
        <v-card-text>
          <v-alert
            text
            prominent
            type="warning"
            icon="mdi-alert"
          >
            Are you sure you want to delete <strong>{{dialogDeleteName}}</strong> from Administrators?
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            elevation="0"
            @click="deleteAdministrator()"
          >
            yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <edit-administrator-modal></edit-administrator-modal>
  </div>
</template>
<script>
import EditAdministratorModal from '../components/EditAdministratorModal.vue'

export default {
  name: 'Administrators',
  data: () => ({
    userData: [],
    notificationCheckboxes: [],
    userSwitches: [],
    dialogDelete: false,
    dialogDeleteId: null,
    dialogDeleteName: null,
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Administrators")
    
    this.$root.$on('_resetAdministrators',() => {
      this.getAdministrators()
    })
    
  },
  mounted() {
    this.getAdministrators()
  },
  methods: {
    addNew(modal) {
      this.$root.$emit("openAddModal",modal)
    },
    getAdministrators(){
      this.userData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/users'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        var cbArr = []
        var switchArr = []
        for (const key in jsonResponse) {
          if(jsonResponse[key].active){
            switchArr.push(jsonResponse[key].user_id)
          }
          if(jsonResponse[key].email_notifications){
            cbArr.push(jsonResponse[key].user_id)
          }
        }

        this.userData = jsonResponse
        this.userSwitches = switchArr
        this.notificationCheckboxes = cbArr
    
      })
    },
    quickUpdate($arr,id, col){
      
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var active = ($arr.includes(id) ? 1 : 0)
      
      var endpoint = `/users/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      let postData = {
        action: "quick",
        col: col,
        active: active
      }

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          var msg = 'Notification status updated'
          if(col == 'active'){
            msg = 'Active status updated'
          }

          this.$root.$emit("notify","success",msg)
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    updateModal(id,name,email){
      this.$root.$emit("openEditAdministratorModal",id,name,email)
    },
    showDeletDialog(id,name){
      this.dialogDelete = true
      this.dialogDeleteId = id,
      this.dialogDeleteName = name
      
    },
    deleteAdministrator(){
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/users/${this.dialogDeleteId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        if(jsonResponse.affectedRows){
          this.getAdministrators()
          this.$root.$emit("notify","success","Administrator has been deleted")
          this.resetDialogDelete()
        }else{
          this.$root.$emit("notify","error","There was an error processing this request")
        }
        
      })
    },
    resetDialogDelete(){
      this.dialogDelete = false
      this.dialogDeleteId = null,
      this.dialogDeleteName = null
    }
  },
  components: {
    EditAdministratorModal
  }
}
</script>
