<template>
  <draggable class="nested-sortable" tag="ul" :list="subcats" :group="{ name: 'g1' }" 
    :move="checkMove" 
    >
    <li v-for="el, index in subcats" :key="el.faq_cat_ID">
      <div class="d-flex">
        <div class="pa-4 mr-auto">{{ el.faq_category }}</div>
        <div class="pa-4">
          <v-btn
            icon
            x-small
          >
            <v-icon @click="doDelete(index)">mdi-delete</v-icon>
          </v-btn>
          <!-- <span class="del" v-on:click="doDelete(index)">more</span> -->
        </div>
      </div>
      <!-- <div class="pa-5">
        <span class="float-left">{{ el.faq_category }} </span>
        <span class="float-right">Del</span>
      </div> -->
      <nested-draggable :subcats="el.subcats" />
    </li>
  </draggable>
</template>
  <script>
  import draggable from 'vuedraggable'
  
  export default {
    props: {
      subcats: {
        required: true,
        type: Array
      }
    },
    methods:{
      doDelete: function(index){
        this.subcats.splice(index, 1);
      },
      pullFunction(to) {
        console.log(to)
       
      },
      checkMove: function (evt) {
       
        //const collection = document.getElementsByClassName("nested-sortable");
        // var toLvl = $(to.el).parents('.nested-sortable').length;

       // console.log(evt.to.childNodes)
       
       const parentLi = evt.to.parentNode
       const parentUl = parentLi.parentNode
       const parentLi2 = parentUl.parentNode

       if( parentLi2.nodeName === 'LI'){
        return false
       }

      // console.log(parentLi2.nodeName)
      //  return false
      //console.log("=> checkMove");
      //console.log("evt.draggedContext.element");
      //var itemElem = evt.draggedContext.element;
      //console.log(itemElem);
      //console.log(this.tasks.length)
       // console.log(evt.to);
      // console.log(evt.from);
      // console.log("evt.to");
      // console.log(evt.to);
      
      // no items outside a category
      // if (itemElem.type === "inventoryItem") {
      //   if (evt.to.childNodes.length > 0) {
      //     var k = 0;
      //     for (var i = 0; i < evt.to.childNodes.length; ++i) {
      //       if (
      //         evt.to.childNodes[i].getAttribute("element-type") ===
      //         "inventoryCategory"
      //       ) {
      //         k++;
      //       }
      //     }
      //     if (k === evt.to.childNodes.length) {
      //       return false;
      //     }
      //   } else {
      //     return true;
      //   }
      // }
      // console.log("child nodes:");
      // for (var i = 0; i < evt.to.childNodes.length; ++i) {
      //   console.log(evt.to.childNodes[i]);
      // }
      // No nested categories
      // if (evt.from !== evt.to && itemElem.type === "inventoryCategory") {
      //   return false;
      // }
      // return true;
      // if (evt.from === evt.to) console.log("=");
      // console.log("evt.draggedContext.element");
      // console.log(evt.draggedContext.element);
      // console.log("evt.from");
      // console.log(evt.from);
      // console.log("evt.to");
      // console.log(evt.to);
      // console.log("=> /checkMove");
      // return true;
      // return !((evt.from.getAttribute('element-type') === 'inventoryCategory')
      // && (evt.from.getAttribute('element-type') === 'inventoryCategory'));
      // var itemEl = evt.draggedContext.element;  // dragged HTMLElement
      // console.log('Item');
      // console.log(itemEl);
      // console.log('draggable attr');
      // // console.log(itemEl.getAttribute('element-type'));
      // console.log('evt.to');
      // console.log(evt.to);    // target list
      // console.log('evt.from');
      // console.log(evt.from);  // previous list
      // console.log('evt.oldIndex');
      // console.log(evt.oldIndex);  // element's old index within old parent
      // console.log('evt.newIndex');
      // console.log(evt.newIndex);  // element's new index within new parent
      // console.log('evt.oldDraggableIndex');
      // console.log(evt.oldDraggableIndex); // element's old index within old parent, only counting draggable elements
      // console.log('evt.newDraggableIndex');
      // console.log(evt.newDraggableIndex); // element's new index within new parent, only counting draggable elements
      // console.log('evt.clone');
      // console.log(evt.clone); // the clone element
      // console.log('evt.pullMode');
      // console.log(evt.pullMode);  // when item is in another sortable: `"clone"` if cloning, `true` if moving
      // return true;
    },
    },
    components: {
      draggable
    },
    name: "nested-draggable"
  };
  </script>
  <style scoped>
  ul.nested-sortable {
    min-height: 10px;
    margin: 0 10px;
    list-style: none;
    padding-bottom: 5px;

    li{
      
      margin: 0 0 15px 0;
      font-weight: 900;
      outline: 1px dashed;
      cursor: move;

      ul li{
        outline: 1px dashed;
        margin: 0 0 10px 0;
      }
    }
  }
  </style>