<template>
  <div class="content-container">
    <v-row justify="center">
      <v-col cols="8">
        <v-card outlined>
          <v-card-title>
            <v-icon>mdi-file-document-multiple</v-icon>  Edit Portal Content
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline mb-4">Basic Information</div>

                    <h4 class="font-weight-regular">Content Type</h4>
                    <v-radio-group
                      v-model="docType"
                      row
                      readonly
                    >
                      <v-radio
                        label="PDF Document"
                        value="pdf"
                      ></v-radio>
                      <v-radio
                        label="Link"
                        value="link"
                      ></v-radio>
                      <v-radio
                        label="Video"
                        value="video"
                      ></v-radio>
                      <v-radio
                        label="Other"
                        value="other"
                      ></v-radio>
                    </v-radio-group>

                    <!-- PDF FORM-->
                    <div v-if="docType=='pdf'">
                      
                      <v-form
                        ref="formPdf"
                        v-model="validPdf"
                        lazy-validation
                      >
                        <v-row>
                          <v-col cols="12" md="9">
                            <v-text-field
                              v-model="docName.pdf"
                              :rules="[v => !!v || 'Title is required',]"
                              label="Document Title *"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-btn
                              class="mt-5"
                              text
                              color="primary"
                              small
                              block
                              outlined
                              @click="viewDocument"
                            >
                              View Pdf
                            </v-btn>
                          </v-col>
                        </v-row>
                        
                        <v-text-field
                          v-model="docDescription.pdf"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-file-input
                          v-model="fileUpload.pdf"
                          accept=".pdf"
                          label="Select New PDF Document"
                        ></v-file-input>
                      </v-form>
                      
                      <v-switch
                        class=""
                        v-model="docActive.pdf"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details

                      ></v-switch> 
                      
                    </div>

                    <!-- LINK FORM-->
                    <div v-if="docType=='link'">
                      
                      <v-form
                        ref="formLink"
                        v-model="validLink"
                        lazy-validation
                      >
                        <v-row>
                          <v-col cols="9">
                            <v-text-field
                              v-model="docName.link"
                              :rules="[v => !!v || 'Title is required',]"
                              label="Link Title *"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-btn
                              class="mt-5"
                              text
                              color="primary"
                              small
                              block
                              outlined
                              @click="viewDocument"
                            >
                              View Link
                            </v-btn>
                          </v-col>
                        </v-row>


                        <v-text-field
                          v-model="docDescription.link"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-text-field
                          v-model="docLink"
                          label="Link URL *"
                          :rules="[
                            v => !!v || 'Link URL is required',
                          ]"
                          required
                          hint="e.g. http://link-address.com/"
                          persistent-hint
                        ></v-text-field>
                      </v-form>
                      <v-switch
                        class=""
                        v-model="docActive.link"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details
                      ></v-switch> 
                      
                    </div>

                    <!-- VIDEO FORM-->
                    <div v-if="docType=='video'">
                      
                      <v-form
                        ref="formVideo"
                        v-model="validVideo"
                        lazy-validation
                      >
                        
                        <v-row>
                          <v-col cols="9">
                            <v-text-field
                              v-model="docName.video"
                              :rules="[v => !!v || 'Title is required']"
                              label="Video Title *"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-btn
                              class="mt-5"
                              text
                              color="primary"
                              small
                              block
                              outlined
                              @click="viewDocument"
                            >
                              View Video
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-text-field
                          v-model="docDescription.video"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-text-field
                          v-model="docVideoLink"
                          label="Video URL *"
                          :rules="[
                            v => !!v || 'Video URL is required'
                          ]"
                          required
                          hint="e.g. https://www.youtube.com/embed/video-id"
                          persistent-hint
                        ></v-text-field>
                      </v-form>
                      <v-switch
                        class=""
                        v-model="docActive.video"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details
                      ></v-switch> 
                    </div>

                    <!-- OTHER FORM-->
                    <div v-if="docType=='other'">
                      
                      <v-form
                        ref="formOther"
                        v-model="validOther"
                        lazy-validation
                      >
                        

                        <v-row>
                          <v-col cols="9">
                            <v-text-field
                              v-model="docName.other"
                              :rules="[v => !!v || 'Title is required',]"
                              label="Document Title *"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-btn
                              class="mt-5"
                              text
                              color="primary"
                              small
                              block
                              outlined
                              @click="viewDocument"
                            >
                              view file
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-text-field
                          v-model="docDescription.other"
                          label="Description"
                          counter="255"
                        ></v-text-field>

                        <v-file-input
                          v-model="fileUpload.other"
                          :rules="[v => !!v || 'Required',]"
                          label="Select File"
                        ></v-file-input>
                      </v-form>
                      <v-switch
                        class=""
                        v-model="docActive.other"
                        color="success"
                        label="Active"
                        value="1"
                        inset
                        hide-details
                      ></v-switch>
                    </div>

                  </v-sheet>
                </div>
                
                <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Document Permissions</div>

                    <div class="caption">
                      <v-icon
                        x-small
                        color="warning"
                      >mdi-alert-circle</v-icon>
                      Which user level(s) can access this document?
                    </div>
                    <v-checkbox
                        v-for="item in customerPermissionData"
                        :key="item.permission_id"
                        v-model="documentPermissions"
                        :label="item.permission"
                        :value="item.permission_id"
                        @change="validateCustomerPermissions"
                        hide-details
                      ></v-checkbox>
                      <div v-if="!validPermissions" class="caption red--text mt-5">Please assign permissions</div>
                    
                  </v-sheet>
                </div>
                
                <!-- begin V2.0 -->
                <div class="mb-7">
                    <v-sheet
                      class="pa-6"
                      outlined
                    >
                    <div class="overline">Assign to Portal Categories</div>

                    <div class="mt-5">
                      <v-treeview
                        v-model="selectedContentCats"
                        selectable
                        item-disabled="locked"
                        :items="catHierarchy"
                      ></v-treeview>
                    </div>
                    <div v-if="!validCategories" class="caption red--text mt-5">Please assign category</div>
                  </v-sheet>
                  <!-- {{ selectedContentCats }} -->
                </div>
                <!-- end V2.0 -->


                <!-- <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Assign to Portal Categories</div>
                    <v-combobox
                      v-model="documentCategories"
                      :items="categoryData"
                      :item-value="categoryData.category_id"
                      :item-text="categoryData.path"
                      clearable
                      multiple
                      small-chips
                      @change="validateCategories"
                    ></v-combobox>

                    <div v-if="!validCategories" class="caption red--text mt-5">Please assign category</div>
                  </v-sheet>
                 
                </div> -->
                <!--
                <div class="mb-7">
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Related Content</div>
                    <v-combobox
                      v-model="relatedContent"
                      :items="documentData"
                      :item-value="documentData.document_id"
                      :item-text="documentData.document_name"
                      clearable
                      multiple
                      small-chips
                    ></v-combobox>
                  </v-sheet>
                </div> 
                -->
                <div>
                  <v-sheet
                    class="pa-6"
                    outlined
                  >
                    <div class="overline">Tags / Search</div>
                    <v-text-field
                      id="newTag"
                      v-model="newTag"
                      append-icon="mdi-plus"
                      label="Enter Tag / Search Phrase"
                      placeholder="Enter Tag / Search Phrase"
                      @keydown.enter="addTag"
                      @click:append="addTag"
                    ></v-text-field>
                    <v-combobox
                      v-model="tags"
                      :items="tagData"
                      :item-value="tagData"
                      :item-text="tagData"
                      clearable
                      multiple
                      small-chips
                    ></v-combobox>
                  </v-sheet>
                </div> 
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn
              text
              color="info"
              large
              @click="$router.push('/portal-content')"
            >
              Back to Portal Content
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              large
              elevation="0"
              class="px-10"
              @click="validate"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="videoDialog"
      width="800"
    >
      <v-card class="pa-5">
        <iframe width="100%" height="450" :src="docVideoLink" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-card>
    </v-dialog>     
  </div>
</template>
<script>
export default {
  name: 'EditPortalContent',
  data: () => ({
    cats:[],
    selectedContentCats:[],
    selectedContentCatsPageLoad:[],
    document_id: null,
    videoDialog:false,  
    validPdf: true,
    validLink: true,
    validVideo: true,
    validOther: true,
    validPermissions: true,
    validCategories: true,
    docName: {pdf: '', link: '', video: '', other: ''},
    docDescription: {pdf: '', link: '', video: '', other: ''},
    fileUpload: {pdf: null, other: null},
    docLink: '',
    docVideoLink: '',
    docActive: {pdf: '1', link: '1', video: '1', other: '1'},
    docDetails: '',
    docType: '',
    active: null,
    documentPermissions: [],
    documentPermissionsCheckAll: null,
    documentCategories: [],
    show1: false,
    loading: false,
    customerPermissionData: [],
    portalCategoriesData: [],
    categoryData: [],
    documentData: [],
    relatedContent: null,
    tags: null,
    tagData: [],
    newTag: '',
    mimetype: null,
    videoUrl: null,
    documentFileName: null,
  }),
  beforeMount() {
    this.$root.$emit("_loggedIn","Edit Portal Content")
    this.getCategorySchema()
    this.getCustomerPermissions()
    this.getCategories()
    this.getPortalDocuments()
  },
  mounted() {
    this.getDoc()
    setTimeout(()=>{
      this.setCatTree()
    },1000)
    
  },
  methods:{
    setCatTree(){
      const arr = this.documentCategories
      let catsArr = []
      for (let i = 0; i < arr.length; i++) {
        catsArr.push(arr[i].value)
      }
      
      this.selectedContentCats = catsArr
      
      
    },
    getCategorySchema() {
      this.catHierarchy = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      const target = 'documents'
      var endpoint = `/portal-category-schemas/${target}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request =  new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
    
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(jsonResponse[0].category_schema_json){
            this.formatTree(JSON.parse(jsonResponse[0].category_schema_json))
        }
        // const success = (JSON.parse(jsonResponse[0].category_schema_json)) ? true : false
        
        // const jsonData = ( success ) ? JSON.parse(jsonResponse[0].category_schema_json) : null

        // const respObj = {
        //   success: success,
        //   data: jsonData
        // }
      
       

      })  
    },
    formatTree(json){
      
      let tempTreeObj = {}
      let tempTreeArr = []
      let tempSubsArr  = []
      let loopSubCatArr = []
      let loopSubCatObj = {}

      for (let i = 0; i < json.length; i++) {

        loopSubCatArr = []
        tempSubsArr  = []

        loopSubCatArr = json[i].subcats
        if(loopSubCatArr.length > 0){
          
          for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
            loopSubCatObj = {
              id: loopSubCatArr[iSubs].category_id,
              name: loopSubCatArr[iSubs].category_name_display,
              locked: false,
              children: []
            }

            tempSubsArr.push(loopSubCatObj)

          }

          

        }

        tempTreeObj = {
          id: json[i].category_id,
          name: json[i].category_name_display,
          locked: false,
          children: tempSubsArr
        }

        tempTreeArr.push(tempTreeObj)
      }

      this.catHierarchy = tempTreeArr
      

    },
    getDoc(){
      const id = this.$route.params.id

      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = `/portal-documents/${id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
      //  console.log(JSON.stringify(jsonResponse))

        this.document_id = jsonResponse[0].document_id 
        this.docType = jsonResponse[0].document_type
        this.mimetype = jsonResponse[0].document_mimetype 
        var active = (jsonResponse[0].active == 1) ? '1' : '0'
        this.documentFileName = jsonResponse[0].document_file_name 
        
        var assignedPermissions = []
        let permissionsArr = jsonResponse[0].permissions_arr.split(',')
        if(permissionsArr.length > 0){
          for (let i = 0; i < permissionsArr.length; i++) {
            assignedPermissions.push(Number(permissionsArr[i]))
          }
        }
        this.documentPermissions = assignedPermissions

 
        var assignedCats = []
        var catsArr = []

        if(jsonResponse[0].categories_arr){
          let assignedCatsArr = jsonResponse[0].categories_arr.split(',').map(Number)

          const catsObj = this.categoryData
          let catsTempObj = {}
          for (let i = 0; i < catsObj.length; i++) {
            let cid = catsObj[i].value
            if( assignedCatsArr.includes(cid) ){
              catsTempObj = {
                text: catsObj[i].text,
                value: catsObj[i].value
              }
              assignedCats.push(catsTempObj)
              catsArr.push(catsObj[i].value)
            }
          }
        }

        this.documentCategories = assignedCats
        this.selectedContentCatsPageLoad = catsArr

        let currentTagsStr = (!jsonResponse[0].document_tags)? null : jsonResponse[0].document_tags
        let tagsTempArr = []
          
        if(currentTagsStr){
          let currentTags = currentTagsStr.split(" | ")
          if(currentTags.length > 0){
            for (let i = 0; i < currentTags.length; i++) {
              tagsTempArr.push(currentTags[i])
            }
          }
        }
        /*
        let currentTags = (!jsonResponse[0].document_tags)? [] : jsonResponse[0].document_tags
        let tagsTempArr = []
        if(currentTags.length > 0){
          for (let i = 0; i < currentTags.length; i++) {
            tagsTempArr.push(currentTags[i].tag)
          }
        }
        */
        this.tagData = tagsTempArr
        this.tags = tagsTempArr
        
        if( this.docType == 'pdf'){
            
          this.docName.pdf = jsonResponse[0].document_name
          this.docDescription.pdf = jsonResponse[0].document_description
          this.docActive.pdf = active
            
        }else if( this.docType == 'link'){
            
          this.docName.link = jsonResponse[0].document_name
          this.docDescription.link = jsonResponse[0].document_description
          this.docLink = jsonResponse[0].document_url
          this.docActive.link = active
            
        }else if( this.docType == 'video'){
            
          this.docName.video = jsonResponse[0].document_name
          this.docDescription.video = jsonResponse[0].document_description
          this.docVideoLink = jsonResponse[0].document_url
          this.docActive.video = active
            
        }else if( this.docType == 'other'){
            
          this.docName.other = jsonResponse[0].document_name
          this.docDescription.other = jsonResponse[0].document_description
          this.docActive.other = active
            
        }
      })
    },
    getCustomerPermissions () {

    //  this.customerPermissionData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/customer-permissions'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint

      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })

      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        this.customerPermissionData = jsonResponse
      })
    },
    getCategories(){
      
      this.categoryData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/portal-categories'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        let arr = []
        let obj = {}
        
        for (const key in jsonResponse) {

          obj = {
            text: jsonResponse[key].path,
            value: jsonResponse[key].category_id,
            parents: jsonResponse[key].parent_sid,
          }
          arr.push(obj)
        }
        
        this.categoryData = arr
        
        /*
text: string | number | object,
  value: string | number | object,
        */

      })
      
    },
    getPortalDocuments () {

      //this.categoryData = []
      const userObj = JSON.parse(localStorage.getItem('rccpa'))

      var endpoint = '/portal-documents'
      const url = process.env.VUE_APP_API_BASE_URL + endpoint

      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })

      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
      
        var arr = []
        var obj = {}

        for (const key in jsonResponse) {
          obj = {
            text: jsonResponse[key].document_name,
            value: jsonResponse[key].document_id,
          }
          arr.push(obj)
        }

        this.documentData = arr

      })      
    },
    addTag() {
      let newTag = this.newTag
      if (newTag.trim().length === 0) {
        return
      }

      var arr = this.tagData;
      arr.push(newTag)
      this.tagData = arr
      this.tags = arr

      this.newTag = ''
    },
    validateCustomerPermissions () {
      if( this.documentPermissions.length == 0 ){
        this.validPermissions = false
      }else{
        this.validPermissions = true
      }
    },
    validateCategories () {

      
      // if( this.documentCategories.length == 0 ){
      if( this.selectedContentCats.length == 0 ){  
        this.validCategories = false
      }else{
        this.validCategories = true
      }
    },
    validate(){

      let permsAndCatsValid = true
      //check permissions
      if( this.documentPermissions.length == 0 ){
        this.validPermissions = false
        setTimeout(()=>{
          this.$root.$emit("notify","error","Please assign document permissions")
          this.loading = false
        },500)
        permsAndCatsValid = false
      }

      //check cats
      // if( this.documentCategories.length == 0 ){
      if( this.selectedContentCats.length == 0 ){
        
        this.validCategories = false
        setTimeout(()=>{
          this.$root.$emit("notify","error","Please assign a category")
          this.loading = false
        },500)
        permsAndCatsValid = false
      }

      if(!permsAndCatsValid) return;

      if( this.docType == 'pdf') { 
        this.createPdf()
      } else if ( this.docType == 'link' ) {
        this.createLink()
      } else if ( this.docType == 'video' ) {
        this.createVideo()
      } else if ( this.docType == 'other' ) {
        this.createOther()
      } 
    },    
    createPdf () {
      if( this.$refs.formPdf.validate() ) {
      
        this.loading = true

        var id = this.document_id

        var active = (this.docActive.pdf == '1') ? 1 : 0

        var docDescription = (!this.docDescription.pdf)? null : this.docDescription.pdf 

        let tagsArr = this.tags
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
              /*
              let tempObj = {
                tag: tagsArr[i]
              }
              tags.push(tempObj)
              */
             let str = tagsArr[i]
             tags.push(str)
          }
          tags = tags.toString().replaceAll(',', ' | ')
        }

        /*
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
              
              let tempObj = {
                tag: tagsArr[i]
              }
              tags.push(tempObj)
          }
        }
        */
       // fileUpload.pdf

        let postData = {
          action: 'updateFileInfo',
          name: this.docName.pdf,
          description: docDescription,
          type: this.docType,
          link: '',
          active: active,
          tags: tags
        }  

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-documents/${id}`

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          

          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error updating this record")
              this.loading = false
            },500)
            return
          }

          
          this.updateDocPermissions(id) 
          this.updateDocCategories(id)
          
          //upload file
          if(this.fileUpload.pdf){
            this.uploadFile(id, this.docType)
          }
          

          setTimeout(()=>{
            this.$root.$emit("notify","success","Document has been changed")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
            //this.documentPermissionsCheckAll = null
            //this.$refs.formPdf.reset()
            //this.docActive.pdf = '1' 
            //this.documentPermissions = []
            //this.documentCategories = []
            //this.tagData = []
            //this.tags = []
          },500)
          
        })
      }else{
        setTimeout(()=>{
          this.$root.$emit("notify","error","There are errors on your form. Please correct.")
          this.loading = false
        },500)
      }
    },
    createLink () {
      if( this.$refs.formLink.validate() ) {
      
        this.loading = true
        var id = this.document_id
        var active = (this.docActive.link == '1') ? 1 : 0

        var docDescription = (!this.docDescription.link)? null : this.docDescription.link 

        let tagsArr = this.tags
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
            let str = tagsArr[i]
            tags.push(str)
          }
          tags = tags.toString().replaceAll(',', ' | ')
        }


        let postData = {
          action: 'updateFileInfo',
          name: this.docName.link,
          description: docDescription,
          type: this.docType,
          link: this.docLink,
          active: active,
          tags: tags
        }  

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-documents/${id}`

        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          
          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error updating this record")
              this.loading = false
            },500)
            return
          }

          this.updateDocPermissions(id)  
          this.updateDocCategories(id) 
          
          setTimeout(()=>{
            this.$root.$emit("notify","success","Link has been updated")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
          },500)
          
        })
      }
    },
    createVideo (){
      
      if( this.$refs.formVideo.validate() ) {
      
        this.loading = true
        var id = this.document_id
        var active = (this.docActive.video == '1') ? 1 : 0

        var docDescription = (!this.docDescription.video)? null : this.docDescription.video 
        
        let tagsArr = this.tags
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
             let str = tagsArr[i]
             tags.push(str)
          }
          tags = tags.toString().replaceAll(',', ' | ')
        }

        let postData = {
          action: 'updateFileInfo',
          name: this.docName.video,
          description: docDescription,
          type: this.docType,
          link: this.docVideoLink,
          active: active,
          tags: tags
        }  
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-documents/${id}`

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error updating this record")
              this.loading = false
            },500)
            return
          }
          
          this.updateDocPermissions(id)  
          this.updateDocCategories(id) 
          
          setTimeout(()=>{
            this.$root.$emit("notify","success","Video has been updated")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
            
          },500)
          
        })
      }
    },
    createOther () {
      if( this.$refs.formOther.validate() ) {
      
        this.loading = true
        var id = this.document_id
        var active = (this.docActive.other == '1') ? 1 : 0
        
        var docDescription = (!this.docDescription.other)? null : this.docDescription.other 

        let tagsArr = this.tags
        let tags = null
        
        if(this.tagData.length > 0){
          tags = []
          for (let i = 0; i < tagsArr.length; i++) {
            let str = tagsArr[i]
            tags.push(str)
          }
          tags = tags.toString().replaceAll(',', ' | ')
        }

        let postData = {
          action: 'updateFileInfo',
          name: this.docName.other,
          description: docDescription,
          type: this.docType,
          link: '',
          active: active,
          tags: tags
        }  

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-documents/${id}`

        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if( jsonResponse.affectedRows != 1 ){
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error updating this record")
              this.loading = false
            },500)
            return
          }

          
          this.updateDocPermissions(id)  
          this.updateDocCategories(id) 
          
          //upload file
          if(this.fileUpload.other){
            this.uploadFile(id, this.docType)
          }

          setTimeout(()=>{
            this.$root.$emit("notify","success","File has been added")
            this.loading = false  
            this.$root.$emit("_resetPortalDocuments")
          },500)
          
        })
      }
    },  
    uploadFile(insertId, docType){
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      var uploadInput

      if(docType == 'pdf'){
        uploadInput = this.fileUpload.pdf
      }else if(docType == 'other'){
        uploadInput = this.fileUpload.other
      }

      let formData = new FormData();
      formData.append( 'portal-file', uploadInput, "upload" );

      //var endpoint2 = '/document-upload'
      var endpoint2 = '/doc-uploads'

      const url2 = process.env.VUE_APP_API_BASE_URL + endpoint2
      const request2 = new Request(url2, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
        },
        body: formData,
        mode: 'cors',
      })
      fetch(request2)
      .then(response2 => {
        return response2.json();
      })
      .then((jsonResponse2) => {
        
        if(!jsonResponse2.success){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error uploading this document")
            this.loading = false
          },500)
          return
        }

        let fileUploadResponse = {
          action: "updateOnUpdate",
          fileName: jsonResponse2.fileData.filename,
          filePath: jsonResponse2.fileData.path,
          fileMimeType: jsonResponse2.fileData.mimetype,
          oldFileName: this.documentFileName
        }

        this.updateFileInfo(fileUploadResponse, insertId)

      })
    },
    updateFileInfo (fileUploadResponse, insertId) {
      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      var endpoint3 = `/portal-documents/${insertId}`
      const url3 = process.env.VUE_APP_API_BASE_URL + endpoint3

      const request3 = new Request(url3, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fileUploadResponse),
        mode: 'cors',
      })

      fetch(request3)
      .then(response3 => {
        return response3.json();
      })
      .then((jsonResponse3) => {
        if( jsonResponse3.affectedRows != 1 ){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error updating this record")
            this.loading = false
          },500)
          return
        }
      })
    },
    updateDocPermissions(insertId) {

      var addNew = (this.documentPermissions.length > 0) ? true : false

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      let bodyPermissions = {
        id: insertId,
        pIds: this.documentPermissions,
        isUpdate: true,
        addNew: addNew
      }

      var endpoint4 = `/document-permissions`
      const url4 = process.env.VUE_APP_API_BASE_URL + endpoint4

      const request4 = new Request(url4, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyPermissions),
        mode: 'cors',
      })
      fetch(request4)
      .then(response4 => {
        return response4.json();
      })
      .then((jsonResponse4) => {
        if( jsonResponse4.affectedRows == 0 ){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error adding document permissions")
            this.loading = false
          },500)
          return
        }
      })

    },
    updateDocCategories(insertId) {
      
      // var addNew = (this.documentCategories.length > 0) ? true : false
      var addNew = (this.selectedContentCats.length > 0) ? true : false
      

      // let selectedCats = this.documentCategories
      let selectedCats = this.selectedContentCats
      
      
      // let arr = []
      // for (let i = 0; i < selectedCats.length; i++) {
      //   let cId = selectedCats[i].value;
      //   arr.push(cId)
      // }

      let arr = selectedCats

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      
      let bodyDocumentCategories = {
        cIds: arr,
        id: insertId,
        isUpdate: true,
        addNew: addNew
      }

      var endpoint5 = `/document-category-assignments`
      const url5 = process.env.VUE_APP_API_BASE_URL + endpoint5

      const request5 = new Request(url5, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyDocumentCategories),
        mode: 'cors',
      })
      fetch(request5)
      .then(request5 => {
        return request5.json();
      })
      .then((jsonResponse5) => {
        
        if( jsonResponse5.affectedRows == 0 ){
          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error adding document categories")
            this.loading = false
          },500)
          return
        }
      })
    },
    reset () {
      
      switch (this.docType) {
        case 'pdf':
          this.$refs.formPdf.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break
        case 'link':
          this.$refs.formLink.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break
        case 'video':
          this.$refs.formVideo.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break  
        case 'other':
          this.$refs.formOther.reset()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break  
      }
      
    },
    resetValidation () {
      
      switch (this.docType) {
        case 'pdf':
          this.$refs.formPdf.resetValidation()
          this.validCategories = true
          this.validPermissions = true
          this.documentPermissions = []
          this.documentCategories = []
          break
        case 'link':
          this.$refs.formLink.resetValidation()
          break
        case 'other':
          this.$refs.formOther.resetValidation()
          break  
      }
    },
    documentCategoriesChange () {
      
      // let selectedCats = this.documentCategories

      let selectedCats = this.selectedContentCats

      let arr = []
      for (let i = 0; i < selectedCats.length; i++) {
        let cId = selectedCats[i].value;
        arr.push(cId)
      }
      


    },
    viewDocument (){

      const userObj = JSON.parse(localStorage.getItem('rccpa'))
      const jwt = userObj.jwt
      var docId = this.document_id
      var url, endpoint

      if( this.docType == 'pdf' || this.docType == 'other' ) {

        endpoint = `/view-file/${docId}/${jwt}`
        url = process.env.VUE_APP_API_BASE_URL + endpoint
        window.open(url)

      }else if ( this.docType == 'link' ) {

        url = this.docLink
        window.open(url)
        
      } else if ( this.docType == 'video' ) {

        this.videoDialog = true

      }

    },
  }
}
</script>