<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">

        <v-toolbar
          
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

          <v-container>
            <v-row justify="center" class="mt-5">
              <v-col cols="12" md="5" sm="6">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-card outlined>
                    <v-card-title>
                      <v-icon large left>
                        {{dialogIcon}}
                      </v-icon>
                      <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div class="modal-form-container">
                      
                      <v-text-field
                        v-model="name"
                        :rules="[v => !!v || 'Name is required',]"
                        label="Category Name *"
                        required
                      ></v-text-field>

                      <v-select
                        v-model="categoryParent"
                        :items="categoryItems"
                        label="Parent Category"
                        @change="parentCategoryChange"
                      ></v-select>

                      <v-row v-if="showCatIcon">
                        <v-col>
                          <v-text-field
                            v-model="icon"
                            label="Category Icon *"
                            required
                            hint="mdi-icon-name"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-alert
                            dense
                            type="info"
                            outlined
                          >
                          <strong><a href="https://pictogrammers.github.io/@mdi/font/6.9.96/" target="_blank">Select your icon here</a></strong>, and paste icon name in text field.
                          
                        </v-alert>
                        </v-col>
                      </v-row>
                      

                      <v-textarea
                        v-model="description"
                        label="Brief Description"
                        maxlength="130"
                        rows="2"
                        counter
                      ></v-textarea>

                      <v-switch
                        v-model="active"
                        color="success" 
                        inset
                        value="true"
                        label="Active"
                        flat
                      ></v-switch>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-btn
                        text
                        color="info"
                        large
                        @click="reset"
                      >
                        Clear Form
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="secondary"
                        large
                        elevation="0"
                        @click="validate"
                      >
                        Add Category
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form> 
              </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: 'AddCategoryModal',
    data: () => ({
      dialog: false,
      dialogTitle: "Add Portal Category",
      dialogIcon: "mdi-format-list-text",
      valid: true,
      name: '',
      icon: '',
      description: '',
      active: true,
      show1: false,
      loading: false,
      categoryItems: '',
      categoryParent: null,
      showCatIcon: false,
    }),
    beforeMount() {
      this.$root.$on('openAddModal',(modal) => {
        if(modal == "Category"){
          this.dialog = true
        }
      })

      this.getCategories()
    },
    methods: {
      validate () {
        
        if(this.$refs.form.validate()){
          
          this.loading = true
          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var status = (this.active) ? "1" : "0"

         // var icon = (!this.icon.trim().length === 0) ? 'null' : this.icon

         var description = (!this.description) ? ' ' : this.description

          let postData = {
            name: this.name,
            icon: 'no-icon',
            description: description,
            active: status,
            parent: this.categoryParent
          }

          var endpoint = '/portal-categories'

          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          const request = new Request(url, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          });

          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              setTimeout(()=>{
                
                this.$root.$emit("notify","success",`${this.name} has been added`)
                this.$root.$emit("_resetCategories")
                
                this.loading = false
                this.reset()
                this.getCategories()

              },500)

            }else{

              setTimeout(()=>{
                
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
                
              },500)

            }
          })      
        }
        
      },
      reset () {
        this.$refs.form.reset()
        this.showCatIcon = false;
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      getCategories(){
      
        this.categoryData = []
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-categories'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          let i = 0;
          let arr = []
          let loopObj = {
            text: "No Parent",
            value: null
          }
          arr.push(loopObj)

          while (i < jsonResponse.length) {
              
            loopObj = {
              text: jsonResponse[i].path,
              value: jsonResponse[i].category_id
            }
            
            arr.push(loopObj)
            i++;
          }

          this.categoryItems = arr
        
        })
        
      },
      parentCategoryChange(){

        //this.showCatIcon = (this.categoryParent === null)? true : false
        this.showCatIcon = false 
      }
    },
  }
</script>
