var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"modal-bg"},[_c('v-toolbar',{attrs:{"elevation":"0","outlined":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-container',[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"6"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.dialogIcon)+" ")]),_c('span',{staticClass:"text-h6 font-weight-light overline"},[_vm._v(_vm._s(_vm.dialogTitle))])],1),_c('v-divider'),_c('div',{staticClass:"modal-form-container"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"New password *","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[
                        v => !!v || 'New password is required',
                        v => /.{8,}$/.test(v) || 'Password must be at lease 8 characters',
                        v => /(?=.*[a-z])(?=.*[A-Z])/.test(v) || 'Password must contain at least one uppercase and one lowercase letter',
                      ],"required":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"label":"Confirm password *","type":_vm.show2 ? 'text' : 'password',"rules":[
                        v => !!v || 'Password must match',
                        v => v === this.password || 'Password must match',
                      ],"required":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.passwordMatch),callback:function ($$v) {_vm.passwordMatch=$$v},expression:"passwordMatch"}})],1),_c('v-col',[_c('v-alert',{attrs:{"dense":"","type":"info","outlined":""}},[_vm._v(" Password must: "),_c('ul',[_c('li',[_vm._v("Have a min of eight characters")]),_c('li',[_vm._v("Contain at least one uppercase letter")]),_c('li',[_vm._v("Have at least one number")])])])],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"primary","large":"","elevation":"0"},on:{"click":_vm.validate}},[_vm._v(" Update Password ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }