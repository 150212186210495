<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">
        <v-toolbar
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <v-row justify="center" class="mt-5">
            <v-col cols="12" md="5" sm="6">
              
              <v-card outlined>
                <v-card-title>
                  <v-icon large left>
                    {{dialogIcon}}
                  </v-icon>
                  <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                </v-card-title>
                <v-divider></v-divider>
                <div class="modal-form-container">

                  <v-row>
                    <v-col>
                      <v-text-field
                            id="newFaqTag"
                            v-model="newFaqTag"
                            append-icon="mdi-plus"
                            label="Create New Tag"
                            placeholder="Add New Tag to Database"
                            @keydown.enter="addTag"
                            @click:append="addTag"
                          ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-divider class="mt-2 mb-5"></v-divider>

                  <v-row>
                    <v-col>

                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Filter Tags"
                        single-line
                        hide-details
                        outlined
                        dense
                        class="mt-2"
                      ></v-text-field>

                      <v-data-table
                        v-model="selected"
                        dense
                        class="mt-5"
                        :headers="headers"
                        :items="faqs"
                        :search="search"
                        hide-default-footer
                        :items-per-page=-1
                        height="450"
                        item-key="tag_ID"
                      >
                        <template v-slot:item.edit="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.tag"
                            large
                            persistent
                            @save="updateCatName(props.item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            <template v-slot:input>
                              <div class="mt-4 text-h6">
                                Update FAQ Tag
                              </div>
                              <v-text-field
                                v-model="props.item.tag"
                                label="Edit"
                                single-line
                                autofocus
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          
                          <v-icon
                            small
                            @click="showDeletDialog(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template> 
                      </v-data-table>
            
                    </v-col>
                  </v-row>
                </div>
              </v-card>
              
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogDelete"
        width="500"
      >
      <v-card>
        <v-card-title class="">
          </v-card-title>
          <v-card-text>
            <v-alert
              text
              prominent
              type="warning"
              icon="mdi-alert"
            >
              Are you sure you want to delete <strong>{{dialogDeleteName}}</strong> from the FAQ Tag database?
            </v-alert>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              elevation="0"
              @click="deleteItem()"
            >
              yes, delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>  
</template>

<script>
  export default {
    name: 'EditCategoryModal',
    data: () => ({
      dialog: false,
      dialogTitle: "Update FAQ Tags",
      dialogIcon: "mdi-format-list-text",
      list: [],
      selected: [],
      singleSelect: false,
      search: '',
      headers: [
        {
          text: 'Tag',
          align: 'start',
          value: 'tag',
        },
        { 
          text: 'Edit', 
          value: 'edit', 
          sortable: false,
          align: 'end'
        },
        { 
          text: 'Delete', 
          value: 'actions', 
          sortable: false,
          align: 'end'
        },
      ],
      faqs: [],
      dialogDelete: false,
      dialogDeleteId: null,
      dialogDeleteName: null,
      newFaqCategory: null,
      newFaqTag: null,
    }),
    beforeMount() {
    },
    mounted() {
      this.$root.$on('openEditFaqTagModal',() => {
        this.getTags()
        this.dialog = true
         
      })
    },
    methods: {
      validate () {
        if(this.$refs.form.validate()){
          
          this.loading = true

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/portal-categories/${this.id}`
          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          
          var status = (this.active) ? "1" : "0"

          let postData = {
            action: "category",
            name: this.name,
            displayOrder: this.displayOrder,
            description: this.description,
            active: status,
            categoryParent: this.categoryParent
          }

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
      
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              
              setTimeout(()=>{
                this.$root.$emit("_resetCategories")
                this.$root.$emit("notify","success","Category has been updated")
                this.loading = false

                setTimeout(()=>{
                  this.dialog = false
                },3500)

              },500) 

            }else{
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
              },500) 
            }
          })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      getTags(){
      
        this.faqs = []
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-faq-tags'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
        
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          
          this.faqs = jsonResponse
          
        })
    
      },
      updateCatName(item){
        const id = item.tag_ID
        const newCatName = item.tag

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-faq-tags/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "category",
          name: newCatName,
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            
            setTimeout(()=>{
              this.$root.$emit("notify","success","FAQ Tag has been updated")
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              
            },500) 
          }
        })
      },
      showDeletDialog(item){
        this.dialogDelete = true
        this.dialogDeleteId = item.tag_ID,
        this.dialogDeleteName = item.tag
      },
      deleteItem(){
        
        const id = this.dialogDeleteId
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-faq-tags/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            this.$root.$emit("notify","success","FAQ Tag has been deleted")
            
            this.resetDialogDelete()

  
            setTimeout(()=>{
              this.getTags()
            }, 750)

          }else{
            this.$root.$emit("notify","error","There was an error processing this request")
            this.resetDialogDelete()
            this.getTags()
          }
          
        })
      },
      resetDialogDelete(){
        this.dialogDelete = false
        this.dialogDeleteId = null,
        this.dialogDeleteName = null
      },
      async addTag(){

        let newTag = this.newFaqTag
        if (newTag.trim().length === 0) {
          return
        }

        const userObj = JSON.parse(localStorage.getItem('rccpa'))
            
        let postData = {
          tag: this.newFaqTag
        }

        var endpoint = '/portal-faq-tags'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        const request = new Request(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        });

        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){

            this.$root.$emit("notify","success",`${this.newFaqTag} has been added`)
            this.getTags()
            this.newFaqTag = ''
        
          }else{

            setTimeout(()=>{
              
              this.$root.$emit("notify","error","There was an error processing this request")
                    
            },500)

          }
        })
    
      },
      closeDialog(){
        this.dialog = false
        this.$root.$emit("resetFaqTags")
      }
    },
  }
</script>
