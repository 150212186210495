<template>
    <div class="content-container">
      <v-row justify="center">
        <v-col cols="12" lg="7" md="6">
          <v-card outlined>
            <v-card-title>
              Content Categories
            </v-card-title>
            <v-card-text>
              <v-card flat outlined>
                <v-card-text>

                  <v-row>
                    <v-col>
                      <v-form
                        ref="form"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="newContentCategory"
                          append-outer-icon="mdi-plus"
                          label="Add Category"
                          :rules="[v => !!v || 'Category Name is required',]"
                          type="text"
                          @click:append-outer="addCat"
                        ></v-text-field>
                      </v-form>
                    </v-col>
                  </v-row>

                  <v-divider class="mt-2 mb-5"></v-divider>

                  <v-row>
                    <v-col>

                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Filter Categories"
                        single-line
                        hide-details
                        outlined
                        dense
                        class="mt-2"
                      ></v-text-field>

                      <v-data-table
                        v-model="selected"
                        dense
                        class="mt-5"
                        :headers="headers"
                        :items="cats"
                        :search="search"
                        hide-default-footer
                        :items-per-page=-1
                        item-key="category_id"
                        show-select
                        :single-select="singleSelect"
                        height="600"
                      >
                        <template v-slot:item.active="props">
                          <v-switch
                            false-value="0"
                            true-value="1"
                            :input-value="props.item.active.toString()"
                            color="success"
                            @change="updateActive($event, props.item.category_id)"
                          ></v-switch>
                        </template>
                        <template v-slot:item.edit="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.category_name"
                            large
                            persistent
                            @save="updateCatName(props.item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            <template v-slot:input>
                              <div class="mt-4 text-h6" style="width:400px;">
                                Update Category
                              </div>
                              <v-text-field
                                v-model="props.item.category_name"
                                label="Category Name"
                                autofocus
                              ></v-text-field>
                              <v-text-field
                                v-model="props.item.category_name_display"
                                label="Category Display Name"
                              ></v-text-field>
                              <v-textarea
                                v-model="props.item.category_description"
                                label="Description"
                                counter
                                maxlength="255"
                                full-width
                              ></v-textarea>
                              <v-text-field
                                v-model="props.item.category_slug"
                                label="Category Slug"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          
                          <v-icon
                            small
                            @click="showDeletDialog(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template> 
                      </v-data-table>
            
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-4">
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="loading"
                    color="primary"
                    elevation="0"
                    text
                    @click="addSelectedCats"
                  >Add Selected to Hierarchy</v-btn>
                </v-card-actions>
              </v-card>   
            </v-card-text>
           
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" md="6">
          
          <v-card outlined>
            <v-card-title class="mb-3">
              Content Category Hierarchy
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    elevation="0"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="resetHierarchy"
                  >
                    <v-icon dark>
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Reset Category Hierarchy </span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <div v-if="list.length === 0" style="min-height: 600px;">
                No Data
              </div>
              <nested-draggable :subcats="list" />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn 
                :loading="loadingHierarchy"
                color="secondary"
                elevation="0"
                @click="updateCategoryHierarchy"
              >Update Category Hierarchy</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialogDelete"
        width="500"
      >
      <v-card>
        <v-card-title class="">
          </v-card-title>
          <v-card-text>
            <v-alert
              text
              prominent
              type="warning"
              icon="mdi-alert"
            >
              Are you sure you want to delete <strong>{{dialogDeleteName}}</strong> from Content Categories?
            </v-alert>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              elevation="0"
              @click="deleteItem()"
            >
              yes, delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import nestedDraggable from '../components/ContentCategoriesNested.vue'

  export default {
    name: 'ContentCategories',
    data: () => ({
      loading: false,
      loadingHierarchy: false,
      newContentCategory: null,
      currentCatStructure: [],
      list: [],
      selected: [],
      singleSelect: false,
      search: '',
      headers: [
        {
          text: 'Category',
          align: 'start',
          value: 'category_name',
        },
        {
          text: 'Display Name',
          align: 'start',
          value: 'category_name_display',
        },
        {
          text: 'Description',
          align: 'start',
          value: 'category_description',
        },
        {
          text: 'Slug',
          align: 'start',
          value: 'category_slug',
        },
        { 
          text: 'Active', 
          value: 'active', 
          sortable: false, 
          filterable: false, 
        },
        { 
          text: 'Edit', 
          value: 'edit', 
          sortable: false,
          align: 'end'
        },
        { 
          text: 'Delete', 
          value: 'actions', 
          sortable: false,
          align: 'end'
        },
      ],
      cats: [],
      dialogDelete: false,
      dialogDeleteId: null,
      dialogDeleteName: null
    }),
    beforeMount() {
      this.$root.$emit("_loggedIn","Portal Content: Content Categories")
      this.getCategories()
      this.getCategorySchema()
    },
    methods: {
      addCat() {
        
        if(this.$refs.form.validate()){
          
          this.loading = true

          const userObj = JSON.parse(localStorage.getItem('rccpa'))
          
          let postData = {
            contentCategory: this.newContentCategory
          }

          var endpoint = '/portal-content-categories'

          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          const request = new Request(url, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          });

          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              setTimeout(()=>{
                
                this.$root.$emit("notify","success",`${this.newContentCategory} has been added`)
                
                this.reset()
                this.getCategories()
                this.loading = false
              },500)

            }else{

              setTimeout(()=>{
                
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
                
              },500)

            }
          })

        }
      },
      reset () {
        this.$refs.form.reset()
        // this.showCatIcon = false;
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      getCategories(){
      
        this.cats = []
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = '/portal-content-categories'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
        
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          
          this.cats = jsonResponse
          // console.log(this.cats)
        })
      
      },
      updateCatName(item){
        const id = item.category_id
        const newCatName = item.category_name
        const newCatNameDisplay = item.category_name_display
        const newCatSlug = item.category_slug
        const newCatDescription = item.category_description

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-content-categories/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "category",
          name: newCatName,
          nameDisplay: newCatNameDisplay,
          slug: newCatSlug,
          description: newCatDescription
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            //update nav JSON
            this.synchHierarchy(id,newCatName,newCatNameDisplay,newCatDescription,newCatSlug)
            
            setTimeout(()=>{
              this.$root.$emit("notify","success","Category has been updated")
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              this.loading = false
            },500) 
          }
        })


      },
      updateActive(event, id){
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))
  
        var endpoint = `/portal-content-categories/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "active",
          active: event,
        }
  
        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
  
          if(jsonResponse.affectedRows){
                        
            setTimeout(()=>{
              this.$root.$emit("notify","success","Category status has been updated")
            },500) 
  
          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              this.loading = false
            },500) 
          }
        })
  
      },
      synchHierarchy(id,newCatName,newCatNameDisplay, newCatDescription, newCatSlug) {
        
        let navObj = this.currentCatStructure
        let newNavArr = []
        let loopId, loopCat, loopCatDisplay, loopCatSlug, loopCatDesc, loopSubCatArr, loopSubCatObj, subsId, subsCat, subsCatSlug, subsCatDisplay, subsCatDesc, tempSubsArr, tempObj

        for (let i = 0; i < navObj.length; i++) {

          //TOP LEVEL
          loopId = navObj[i].category_id
          loopCat = navObj[i].category_name
          loopCatDisplay = navObj[i].category_name_display
          loopCatSlug = navObj[i].category_slug
          loopCatDesc = navObj[i].category_description

          if(id === loopId) {
            loopCat = newCatName
            loopCatDisplay = newCatNameDisplay
            loopCatDesc = newCatDescription
            loopCatSlug = newCatSlug
          }

          loopSubCatArr = navObj[i].subcats
          tempSubsArr  = []

          if(loopSubCatArr.length > 0){
            
            for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
              
              subsId = loopSubCatArr[iSubs].category_id
              subsCat = loopSubCatArr[iSubs].category_name
              subsCatDisplay = loopSubCatArr[iSubs].category_name_display
              subsCatSlug = loopSubCatArr[iSubs].category_slug
              subsCatDesc = loopSubCatArr[iSubs].category_description

              if(id === subsId) {
                // subsCat = subsCatDisplay
                subsCat = newCatName
                subsCatDisplay = newCatNameDisplay
                subsCatSlug = newCatSlug
                subsCatDesc = newCatDescription
              }

              let loopSubCat2Arr = loopSubCatArr[iSubs].subcats
              let tempSubs2Arr  = []

              if(loopSubCat2Arr.length > 0){

                for (let iSubs2 = 0; iSubs2 < loopSubCat2Arr.length; iSubs2++) {
                  
                  let subs2Id = loopSubCat2Arr[iSubs2].category_id
                  let subs2Cat = loopSubCat2Arr[iSubs2].category_name
                  let subs2CatDisplay = loopSubCat2Arr[iSubs2].category_name_display
                  let subs2CatSlug = loopSubCat2Arr[iSubs2].category_slug
                  let subs2CatDesc = loopSubCat2Arr[iSubs2].category_description

                  // if(id === subs2Id) {
                  //   subs2Cat = subs2CatDisplay
                  // }
                  if(id === subs2Id) {
                    // subsCat = subsCatDisplay
                    subs2Cat = newCatName
                    subs2CatDisplay = newCatNameDisplay
                    subs2CatSlug = newCatSlug
                    subs2CatDesc = newCatDescription
                  }

                  let loopSubCat3Arr = loopSubCat2Arr[iSubs2].subcats
                  let tempSubs3Arr  = []

                  if(loopSubCat3Arr.length > 0){

                    for (let iSubs3 = 0; iSubs3 < loopSubCat3Arr.length; iSubs3++) {

                      let subs3Id = loopSubCat3Arr[iSubs3].category_id
                      let subs3Cat = loopSubCat3Arr[iSubs3].category_name
                      let subs3CatDisplay = loopSubCat3Arr[iSubs3].category_name_display
                      let subs3CatSlug = loopSubCat3Arr[iSubs3].category_slug
                      let subs3CatDesc = loopSubCat3Arr[iSubs3].category_description

                      // if(id === subs3Id) {
                      //   subs3Cat = subs3CatDisplay
                      // }
                      if(id === subs3Id) {
                        // subsCat = subsCatDisplay
                        subs3Cat = newCatName
                        subs3CatDisplay = newCatNameDisplay
                        subs3CatSlug = newCatSlug
                        subs3CatDesc = newCatDescription
                      }

                      let loopSubCat4Arr = loopSubCat3Arr[iSubs3].subcats
                      let tempSubs4Arr  = []

                      if(loopSubCat4Arr.length > 0){
                        for (let iSubs4 = 0; iSubs4 < loopSubCat4Arr.length; iSubs4++) {

                          let subs4Id = loopSubCat4Arr[iSubs4].category_id
                          let subs4Cat = loopSubCat4Arr[iSubs4].category_name
                          let subs4CatDisplay = loopSubCat4Arr[iSubs4].category_name_display
                          let subs4CatSlug = loopSubCat4Arr[iSubs4].category_slug
                          let subs4CatDesc = loopSubCat4Arr[iSubs4].category_description

                          // if(id === subs4Id) {
                          //   subs4Cat = subs4CatDisplay
                          // }

                          if(id === subs4Id) {
                            // subsCat = subsCatDisplay
                            subs4Cat = newCatName
                            subs4CatDisplay = newCatNameDisplay
                            subs4CatSlug = newCatSlug
                            subs4CatDesc = newCatDescription
                          }

                          let loopSubCat4Obj = {
                            category_id: subs4Id,
                            category_name: subs4Cat,
                            category_name_display: subs4CatDisplay,
                            category_slug: subs4CatSlug,
                            category_description: subs4CatDesc,
                            subcats: []
                          }

                          tempSubs4Arr.push(loopSubCat4Obj)

                        }
                      }

                      

                      let loopSubCat3Obj = {
                        category_id: subs3Id,
                        category_name: subs3Cat,
                        category_name_display: subs3CatDisplay,
                        category_slug: subs3CatSlug,
                        category_description: subs3CatDesc,
                        subcats: tempSubs4Arr
                      }

                      tempSubs3Arr.push(loopSubCat3Obj)
                    }

                  }


                  let loopSubCat2Obj = {
                    category_id: subs2Id,
                    category_name: subs2Cat,
                    category_name_display: subs2CatDisplay,
                    category_slug: subs2CatSlug,
                    category_description: subs2CatDesc,
                    subcats: tempSubs3Arr
                  }

                  tempSubs2Arr.push(loopSubCat2Obj)

                }

              }



              loopSubCatObj = {
                category_id: subsId,
                category_name: subsCat,
                category_name_display: subsCatDisplay,
                category_slug: subsCatSlug,
                category_description: subsCatDesc,
                subcats: tempSubs2Arr
              }

              tempSubsArr.push(loopSubCatObj)

            }
          }

          tempObj = {
            category_id: loopId,
            category_name: loopCat,
            category_name_display: loopCatDisplay,
            category_slug: loopCatSlug,
            category_description: loopCatDesc,
            subcats: tempSubsArr  
          }

          newNavArr.push(tempObj)

        }

        this.list = newNavArr

        setTimeout(()=>{
          this.updateCategoryHierarchy()
        }, 300)

        setTimeout(()=>{
          this.getCategories()
          this.getCategorySchema() 
        }, 750)

      },
      addSelectedCats() {

        this.loading = true
        let tempArr = this.list
        let addedCats = this.selected

        let i = 0;

        let tempObj = {}

        while (i < addedCats.length) {
          tempObj = {
            category_id: addedCats[i].category_id,
            category_name: addedCats[i].category_name,
            category_name_display: addedCats[i].category_name_display,
            category_slug: addedCats[i].category_slug,
            category_description: addedCats[i].category_description,
            subcats: []
          }
          tempArr.push(tempObj)
          i++;
        }

        this.list = tempArr
        this.selected = []

        setTimeout(()=>{
          this.loading = false
        },500)
         //tempArr.push(addedCats)
      },
      getCategorySchema() {
        this.cats = []
        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        const target = 'documents'
        var endpoint = `/portal-category-schemas/${target}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
                
          if(jsonResponse[0].category_schema_json){
            this.list = JSON.parse(jsonResponse[0].category_schema_json)
            this.currentCatStructure = this.list
          }

        })  
      },
      updateCategoryHierarchy() {
        
        this.loadingHierarchy = true

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        const target = 'documents'
        var endpoint = `/portal-category-schemas/${target}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          category_schema_json: this.list
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            this.updateParents()

            setTimeout(()=>{
              
              this.$root.$emit("notify","success","Content Category Schema has been updated")
              this.loadingHierarchy = false
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              this.loadingHierarchy = false
            },500) 
          }
        })
      },
      updateParents(){
        const navObj = this.list
        let updateArr = []

        for (let i = 0; i < navObj.length; i++) {
          
          let level1ParentId = navObj[i].category_id
          let level2Subcats = navObj[i].subcats

          if(level2Subcats.length > 0){
            
            for (let iL2 = 0; iL2 < level2Subcats.length; iL2++) {
              
              let level2ParentId = level2Subcats[iL2].category_id
              let level3Subcats = level2Subcats[iL2].subcats
              
              let level2Obj = {
                parent_id: level1ParentId,
                category_id: level2Subcats[iL2].category_id
              }
              updateArr.push(level2Obj)

              if(level3Subcats.length > 0){
                
                for (let iL3 = 0; iL3 < level3Subcats.length; iL3++) {

                  let level3ParentId = level3Subcats[iL3].category_id
                  let level4Subcats = level3Subcats[iL3].subcats
                  
                  let level3Obj = {
                    parent_id: level2ParentId,
                    category_id: level3Subcats[iL3].category_id
                  }
                  updateArr.push(level3Obj)

                  if(level4Subcats.length > 0){

                    for (let iL4 = 0; iL4 < level4Subcats.length; iL4++) {

                      
                      let level4Obj = {
                        parent_id: level3ParentId,
                        category_id: level4Subcats[iL4].category_id
                      }
                      updateArr.push(level4Obj)

                    }

                  }

                }

              }

            }
          
          }
        }

        this.loadingHierarchy = true

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-content-categories/0`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: 'parent_all',
          newOrderArr: updateArr
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          console.log(jsonResponse)
        })
      },
      showDeletDialog(item){
        this.dialogDelete = true
        this.dialogDeleteId = item.category_id,
        this.dialogDeleteName = item.category_name_display
      },
      resetDialogDelete(){
        this.dialogDelete = false
        this.dialogDeleteId = null,
        this.dialogDeleteName = null
      },
      deleteItem(){
        
        const id = this.dialogDeleteId
        
        let navObj = this.currentCatStructure
        let newNavArr = []

        // let loopId, loopCat, loopCatDisplay, loopSubCatArr, loopSubCatObj, subsId, tempSubsArr, tempObj
        let loopId, loopCat, loopCatDisplay, loopCatSlug, loopCatDesc, loopSubCatArr, loopSubCatObj, subsId, subsCat, subsCatSlug, subsCatDisplay, subsCatDesc, tempSubsArr, tempObj

        for (let i = 0; i < navObj.length; i++) {
          
          //TOP LEVEL
          loopId = navObj[i].category_id
          loopCat = navObj[i].category_name
          loopCatDisplay = navObj[i].category_name_display
          loopCatSlug = navObj[i].category_slug
          loopCatDesc = navObj[i].category_description

          if(id === loopId) {
            continue
          }

          loopSubCatArr = navObj[i].subcats
          tempSubsArr  = []

          if(loopSubCatArr.length > 0){
            
            for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
              
              subsId = loopSubCatArr[iSubs].category_id
              subsCat = loopSubCatArr[iSubs].category_name
              subsCatDisplay = loopSubCatArr[iSubs].category_name_display
              subsCatSlug = loopSubCatArr[iSubs].category_slug
              subsCatDesc = loopSubCatArr[iSubs].category_description

              if(id === subsId) {
                continue
              }

              let loopSubCat2Arr = loopSubCatArr[iSubs].subcats
              let tempSubs2Arr  = []

              if(loopSubCat2Arr.length > 0){

                for (let iSubs2 = 0; iSubs2 < loopSubCat2Arr.length; iSubs2++) {
                  
                  let subs2Id = loopSubCat2Arr[iSubs2].category_id
                  let subs2Cat = loopSubCat2Arr[iSubs2].category_name
                  let subs2CatDisplay = loopSubCat2Arr[iSubs2].category_name_display
                  let subs2CatSlug = loopSubCat2Arr[iSubs2].category_slug
                  let subs2CatDesc = loopSubCat2Arr[iSubs2].category_description

                  if(id === subs2Id) {
                    continue
                  }

                  
                  // if(id === subs2Id) {
                  //   subs2Cat = subs2CatDisplay
                  // }

                  let loopSubCat3Arr = loopSubCat2Arr[iSubs2].subcats
                  let tempSubs3Arr  = []

                  if(loopSubCat3Arr.length > 0){

                    for (let iSubs3 = 0; iSubs3 < loopSubCat3Arr.length; iSubs3++) {

                      let subs3Id = loopSubCat3Arr[iSubs3].category_id
                      let subs3Cat = loopSubCat3Arr[iSubs3].category_name
                      let subs3CatDisplay = loopSubCat3Arr[iSubs3].category_name_display
                      let subs3CatSlug = loopSubCat3Arr[iSubs3].category_slug
                      let subs3CatDesc = loopSubCat3Arr[iSubs3].category_description

                      if(id === subs3Id) {
                        continue
                      }
                      // if(id === subs3Id) {
                      //   subs3Cat = subs3CatDisplay
                      // }

                      let loopSubCat4Arr = loopSubCat3Arr[iSubs3].subcats
                      let tempSubs4Arr  = []

                      if(loopSubCat4Arr.length > 0){
                        for (let iSubs4 = 0; iSubs4 < loopSubCat4Arr.length; iSubs4++) {

                          let subs4Id = loopSubCat4Arr[iSubs4].category_id
                          let subs4Cat = loopSubCat4Arr[iSubs4].category_name
                          let subs4CatDisplay = loopSubCat4Arr[iSubs4].category_name_display
                          let subs4CatSlug = loopSubCat4Arr[iSubs4].category_slug
                          let subs4CatDesc = loopSubCat4Arr[iSubs4].category_description

                          if(id === subs4Id) {
                            continue
                          }
                          // if(id === subs4Id) {
                          //   subs4Cat = subs4CatDisplay
                          // }

                          let loopSubCat4Obj = {
                            category_id: subs4Id,
                            category_name: subs4Cat,
                            category_name_display: subs4CatDisplay,
                            category_slug: subs4CatSlug,
                            category_description: subs4CatDesc,
                            subcats: []
                          }

                          tempSubs4Arr.push(loopSubCat4Obj)

                        }
                      }

                      

                      let loopSubCat3Obj = {
                        category_id: subs3Id,
                        category_name: subs3Cat,
                        category_name_display: subs3CatDisplay,
                        category_slug: subs3CatSlug,
                        category_description: subs3CatDesc,
                        subcats: tempSubs4Arr
                      }

                      tempSubs3Arr.push(loopSubCat3Obj)
                    }

                  }


                  let loopSubCat2Obj = {
                    category_id: subs2Id,
                    category_name: subs2Cat,
                    category_name_display: subs2CatDisplay,
                    category_slug: subs2CatSlug,
                    category_description: subs2CatDesc,
                    subcats: tempSubs3Arr
                  }

                  tempSubs2Arr.push(loopSubCat2Obj)

                }

              }



              loopSubCatObj = {
                category_id: subsId,
                category_name: subsCat,
                category_name_display: subsCatDisplay,
                category_slug: subsCatSlug,
                category_description: subsCatDesc,
                subcats: tempSubs2Arr
              }

              tempSubsArr.push(loopSubCatObj)

            }
          }

          tempObj = {
            category_id: loopId,
            category_name: loopCat,
            category_name_display: loopCatDisplay,
            category_slug: loopCatSlug,
            category_description: loopCatDesc,
            subcats: tempSubsArr  
          }

          newNavArr.push(tempObj)
          

          // if(loopSubCatArr.length > 0){
            
          //   for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
              
          //     subsId = loopSubCatArr[iSubs].category_id
              
          //     if(id === subsId) {
          //       continue
          //     }else{

          //       loopSubCatObj = {
          //         category_id: subsId,
          //         category_name: loopSubCatArr[iSubs].category_name,
          //         category_name_display: loopSubCatArr[iSubs].category_name_display,
          //         subcats: []
          //       }

          //       tempSubsArr.push(loopSubCatObj)
          //     }

          //   }
          // }

          // tempObj = {
          //   category_id: loopId,
          //   category_name: loopCat,
          //   category_name_display: loopCatDisplay,
          //   subcats: tempSubsArr  
          // }

          // newNavArr.push(tempObj)
        }

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-content-categories/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            this.$root.$emit("notify","success","Content Category has been deleted")
            
            this.resetDialogDelete()

            this.list = newNavArr

            setTimeout(()=>{
              this.updateCategoryHierarchy()
            }, 300)

            setTimeout(()=>{
              this.getCategories()
              this.getCategorySchema() 
            }, 750)

          }else{
            this.$root.$emit("notify","error","There was an error processing this request")
            this.resetDialogDelete()
            this.getCategories()
          }
          
        })
      },
      resetHierarchy(){
        this.getCategories()
        this.getCategorySchema()
      }
    },
    
    components: {
      nestedDraggable
    }
  }
  </script>
  <style lang="scss" scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
 
  </style>