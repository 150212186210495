<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="modal-bg">
        <v-toolbar
          elevation="0"
          outlined
        >
          <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        
        <v-container>
          <v-row justify="center" class="mt-5">
            <v-col cols="12" md="5" sm="6">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-card outlined>
                  <v-card-title>
                    <v-icon large left>
                      {{dialogIcon}}
                    </v-icon>
                    <span class="text-h6 font-weight-light overline">{{dialogTitle}}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <div class="modal-form-container">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          v-model="firstName"
                          :rules="[v => !!v || 'First name is required',]"
                          label="First Name *"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          v-model="lastName"
                          :rules="[v => !!v || 'Last name is required',]"
                          label="Last Name *"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          v-model="email"
                          :rules="[
                            v => !!v || 'Email is required',
                            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                          ]"
                          label="Email *"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          v-model="phone"
                          label="Phone"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          v-model="company"
                          label="Company"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          v-model="permissions"
                          :items="accountTypes"
                          label="Permissions *"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-switch
                      v-model="active"
                      color="success" 
                      inset
                      label="Active"
                      flat
                    ></v-switch>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        large
                        elevation="0"
                        @click="validate"
                      >
                        Update Customer
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form> 
              </v-col>
            </v-row>
        </v-container>
      
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: 'EditCustomerModal',
    data: () => ({
      dialog: false,
      dialogTitle: "Update Customer",
      dialogIcon: "mdi-account-group",
      valid: true,
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      permissions: '',
      active: null,
      show1: false,
      loading: false,
      accountTypes: ["Guest","Customer","Distributor","Admin"
      ],
    }),
    beforeMount() {
    },
    mounted() {
      this.$root.$on('openEditCustomerModal',(id) => {

        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/customers/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
        
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          this.dialog = true
          this.id = jsonResponse[0].customer_id
          this.firstName = jsonResponse[0].customer_first
          this.lastName = jsonResponse[0].customer_last
          this.email = jsonResponse[0].customer_email
          this.phone = jsonResponse[0].customer_phone 
          this.company = jsonResponse[0].customer_company
          this.permissions = jsonResponse[0].customer_permissions
          this.active = jsonResponse[0].active
        })
      })
    },
    methods: {
      validate () {
        if(this.$refs.form.validate()){
          
          this.loading = true

          const userObj = JSON.parse(localStorage.getItem('rccpa'))

          var endpoint = `/customers/${this.id}`
          const url = process.env.VUE_APP_API_BASE_URL + endpoint
          
          var status = (this.active) ? "1" : "0"

          let postData = {
            action: "customer",
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            company: this.company,
            permissions: this.permissions,
            active: status
          }

          const request = new Request(url, {
            method: 'PUT',
            headers: {
              'Authorization': 'Bearer ' + userObj.jwt,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData),
            mode: 'cors',
          })
      
          fetch(request)
          .then(response => {
            return response.json();
          })
          .then((jsonResponse) => {

            if(jsonResponse.affectedRows){
              
              setTimeout(()=>{
                this.$root.$emit("_resetCustomers")
                this.$root.$emit("notify","success","Customer has been updated")
                this.loading = false

                setTimeout(()=>{
                  this.dialog = false
                },3500)

              },500) 

            }else{
              setTimeout(()=>{
                this.$root.$emit("notify","error","There was an error processing this request")
                this.loading = false
              },500) 
            }
          })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
