<template>
  <div>
    <draggable class="nested-sortable" tag="ul" :list="subcats" :group="{ name: 'g1' }" 
      :move="checkMove" 
      >
      <li v-for="el, index in subcats" :key="el.id">
        <v-row>
          <v-col cols class="col-auto mt-4 ml-3 move-me">
            <v-icon>mdi-arrow-up-down</v-icon>  
          </v-col>
          <v-col class="mt-4">
            {{ el.document_name }}
          </v-col>
          <v-col cols class="col-auto">
            <v-switch
              label="Active"
              false-value="0"
              true-value="1"
              :input-value="el.active.toString()"
              color="success"
              @change="updateActive($event, el.document_id)"
            ></v-switch>
          </v-col>
          <v-col cols class="col-auto pt-6">
            <v-btn
              icon
              color="primary"
            >
              <v-icon @click="updateItem(el.document_id)">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col cols class="col-auto pt-6 pr-6">
            <v-btn
              icon
            >
              <v-icon @click="showDialogDelete(el.id,index)">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </li>
    </draggable>

    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
    <v-card>
      <v-card-title class="">
        </v-card-title>
        <v-card-text>
          <v-alert
            text
            prominent
            type="warning"
            icon="mdi-alert"
          >
            Are you sure you want to remove content from this category?
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            elevation="0"
            @click="deleteItem()"
          >
            yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>    
</template>
  <script>
  import draggable from 'vuedraggable'
  
  export default {
    props: {
      subcats: {
        required: true,
        type: Array
      }
    },
    data: () => ({
      dialogDelete: false,
      displayId: null, 
      subcatsIndex: null,
    }),
    
    methods:{
      doDelete: function(index){
        this.subcats.splice(index, 1);
      },
      checkMove: function (evt) {
       
        //const collection = document.getElementsByClassName("nested-sortable");
        // var toLvl = $(to.el).parents('.nested-sortable').length;

       // console.log(evt.to.childNodes)
       
       const parentLi = evt.to.parentNode
       const parentUl = parentLi.parentNode
    // const parentLi2 = parentUl.parentNode

       if( parentUl.nodeName === 'UL'){
        return false
       }

       setTimeout(()=>{
        this.updateOrder()
       },1000)

      },
      updateOrder(){

        const orderedArr = this.subcats
        //let tempObj = {}
        let tempArr = []

        for (let i = 0; i < orderedArr.length; i++) {
          // tempObj = {
          //   id: orderedArr[i].ID,
          //   order: i 
          // }
          
          tempArr.push(orderedArr[i].id)
        }

        const userObj = JSON.parse(localStorage.getItem('rccpa'))
        
        var endpoint = `/portal-content-category-assignments/0`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "displayOrder",
          newOrder: tempArr
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.success){
            
            setTimeout(()=>{
              
              this.$root.$emit("notify","success","Display order has been updated")
              
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              
            },500) 
          }

        })
        

      },
      updateActive(event, id){
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-content/${id}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
            
        let postData = {
          action: "active",
          active: event,
        }

        const request = new Request(url, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
                        
            setTimeout(()=>{
              this.$root.$emit("notify","success","Status has been updated")
            },500) 

          }else{
            setTimeout(()=>{
              this.$root.$emit("notify","error","There was an error processing this request")
              this.loading = false
            },500) 
          }
        })

      },
      showDialogDelete(id, index){
        this.dialogDelete = true
        this.displayId = id
        this.subcatsIndex = index
      },
      deleteItem(){
        
        const userObj = JSON.parse(localStorage.getItem('rccpa'))

        var endpoint = `/portal-content-category-assignments/${this.displayId}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
        const request = new Request(url, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
      
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.affectedRows){
            
            this.$root.$emit("notify","success","Content has been removed from category")
            
            this.subcats.splice(this.subcatsIndex, 1);
            this.resetDialogDelete()

            // this.list = newNavArr

            // setTimeout(()=>{
            //   this.updateCategoryHierarchy()
            // }, 300)

            // setTimeout(()=>{
            //   this.getCategories()
            //   this.getCategorySchema() 
            // }, 750)

          }else{
            this.$root.$emit("notify","error","There was an error processing this request")
            // this.resetDialogDelete()
            // this.getCategories()
          }
          
        })
      },
      resetDialogDelete(){
        this.dialogDelete = false
        this.displayId = null
        this.subcatsIndex = null
      },
      updateItem(id) {
        this.$router.push(`/edit-portal-content/${id}`)
      },
    },
    components: {
      draggable
    },
    name: "nested-draggable"
  };
  </script>
  <style scoped>
  ul.nested-sortable {
    min-height: 10px;
    margin: 0 0 0 -25px;
    list-style: none;
    padding-bottom: 5px;

    li{
      
      margin: 0 0 25px 0;
      outline: 1px dashed;
      

      ul li{
        outline: 1px dashed;
        margin: 0 0 10px 0;
      }
    }

    .move-me{
      cursor: move;
    }
  }
  </style>