<template>
  <div class="content-container">
    logout
  </div>
</template>
<script>
export default {
  name: 'Logout',
  data: () => ({
  }),
  beforeMount() {
    this.$root.$emit("_loggedOut")
    this.$router.push({ path: '/' })
  },
  methods: {
  },
  components: {
  }
}
</script>
